import apiRequest from '../ApiService';

const apiEndPoint = 'store_parameters/category';

export const getCategory = async (param = '') => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    console.log(error);
  }
};

export const addCategory = async (param) => {
  try {
    return apiRequest.post(`/${apiEndPoint}`, param);
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryByStoreId = async (store_id) => {
  const { data } = await getCategory('?page=all');
  if (data?.success) {
    return (
      data?.data.filter((c) => c && Number(c?.store_id) === Number(store_id)) ||
      []
    );
  } else {
    return [];
  }
};

export const updateCategory = async (uuid, param) => {
  try {
    return await apiRequest.put(`/${apiEndPoint}/` + uuid, param);
  } catch (error) {
    console.log(error);
  }
};

export const iconList = [
  'folder',
  '3d-rotation',
  'accessibility',
  'accessibility-new',
  'accessible',
  'accessible-forward',
  'account-balance',
  'account-balance-wallet',
  'account-box',
  'account-circle',
  'add-shopping-cart',
  'alarm',
  'alarm-add',
  'alarm-off',
  'alarm-on',
  'all-inbox',
  'all-out',
  'android',
  'announcement',
  'arrow-right-alt',
  'aspect-ratio',
  'assessment',
  'assignment',
  'assignment-ind',
  'assignment-late',
  'assignment-return',
  'assignment-returned',
  'assignment-turned-in',
  'autorenew',
  'backup',
  'book',
  'bookmark',
  'bookmark-border',
  'bookmarks',
  'bug-report',
  'build',
  'cached',
  'calendar-today',
  'calendar-view-day',
  'camera-enhance',
  'card-giftcard',
  'card-membership',
  'card-travel',
  'change-history',
  'check-circle',
  'check-circle-outline',
  'chrome-reader-mode',
  'class',
  'code',
  'commute',
  'compare-arrows',
  'contact-support',
  'copyright',
  'credit-card',
  'dashboard',
  'date-range',
  'delete',
  'delete-forever',
  'delete-outline',
  'description',
  'dns',
  'done',
  'done-all',
  'done-outline',
  'donut-large',
  'donut-small',
  'drag-indicator',
  'eject',
  'euro-symbol',
  'event',
  'event-seat',
  'exit-to-app',
  'explore',
  'explore-off',
  'extension',
  'face',
  'favorite',
  'favorite-border',
  'feedback',
  'find-in-page',
  'find-replace',
  'fingerprint',
  'flight-land',
  'flight-takeoff',
  'flip-to-back',
  'flip-to-front',
  'g-translate',
  'gavel',
  'get-app',
  'gif',
  'grade',
  'group-work',
  'help',
  'help-outline',
  'highlight-off',
  'history',
  'home',
  'horizontal-split',
  'hourglass-empty',
  'hourglass-full',
  'http',
  'https',
  'important-devices',
  'info',
  'input',
  'invert-colors',
  'label',
  'label-important',
  'label-off',
  'language',
  'launch',
  'line-style',
  'line-weight',
  'list',
  'lock',
  'lock-open',
  'loyalty',
  'markunread-mailbox',
  'maximize',
  'minimize',
  'motorcycle',
  'note-add',
  'offline-bolt',
  'offline-pin',
  'opacity',
  'open-in-browser',
  'open-in-new',
  'open-with',
  'pageview',
  'pan-tool',
  'payment',
  'perm-camera-mic',
  'perm-contact-calendar',
  'perm-data-setting',
  'perm-device-information',
  'perm-identity',
  'perm-media',
  'perm-phone-msg',
  'perm-scan-wifi',
  'pets',
  'picture-in-picture',
  'picture-in-picture-alt',
  'play-for-work',
  'polymer',
  'power-settings-new',
  'pregnant-woman',
  'print',
  'query-builder',
  'question-answer',
  'receipt',
  'record-voice-over',
  'redeem',
  'remove-shopping-cart',
  'reorder',
  'report-problem',
  'restore',
  'restore-from-trash',
  'restore-page',
  'room',
  'rounded-corner',
  'rowing',
  'schedule',
  'search',
  'settings',
  'settings-applications',
  'settings-backup-restore',
  'settings-bluetooth',
  'settings-brightness',
  'settings-cell',
  'settings-ethernet',
  'settings-input-antenna',
  'settings-input-component',
  'settings-input-composite',
  'settings-input-hdmi',
  'settings-input-svideo',
  'settings-overscan',
  'settings-phone',
  'settings-power',
  'settings-remote',
  'settings-voice',
  'shop',
  'shop-two',
  'shopping-basket',
  'shopping-cart',
  'speaker-notes',
  'speaker-notes-off',
  'spellcheck',
  'star-rate',
  'stars',
  'store',
  'subject',
  'supervised-user-circle',
  'supervisor-account',
  'swap-horiz',
  'swap-horizontal-circle',
  'swap-vert',
  'swap-vertical-circle',
  'tab',
  'tab-unselected',
  'text-rotate-up',
  'text-rotate-vertical',
  'text-rotation-down',
  'text-rotation-none',
  'theaters',
  'thumb-down',
  'thumb-up',
  'thumbs-up-down',
  'timeline',
  'toc',
  'today',
  'toll',
  'touch-app',
  'track-changes',
  'translate',
  'trending-down',
  'trending-flat',
  'trending-up',
  'turned-in',
  'turned-in-not',
  'update',
  'verified-user',
  'vertical-split',
  'view-agenda',
  'view-array',
  'view-carousel',
  'view-column',
  'view-day',
  'view-headline',
  'view-list',
  'view-module',
  'view-quilt',
  'view-stream',
  'view-week',
  'visibility',
  'visibility-off',
  'voice-over-off',
  'watch-later',
  'work',
  'work-off',
  'work-outline',
  'youtube-searched-for',
  'zoom-in',
  'zoom-out',
  'add-alert',
  'error',
  'error-outline',
  'notification-important',
  'warning',
  '4k',
  'add-to-queue',
  'airplay',
  'album',
  'art-track',
  'av-timer',
  'branding-watermark',
  'call-to-action',
  'closed-caption',
  'control-camera',
  'equalizer',
  'explicit',
  'fast-forward',
  'fast-rewind',
  'featured-play-list',
  'featured-video',
  'fiber-dvr',
  'fiber-manual-record',
  'fiber-new',
  'fiber-pin',
  'fiber-smart-record',
  'forward-10',
  'forward-30',
  'forward-5',
  'games',
  'hd',
  'hearing',
  'high-quality',
  'library-add',
  'library-books',
  'library-music',
  'loop',
  'mic',
  'mic-none',
  'mic-off',
  'missed-video-call',
  'movie',
  'music-video',
  'new-releases',
  'not-interested',
  'note',
  'pause',
  'pause-circle-filled',
  'pause-circle-outline',
  'play-arrow',
  'play-circle-filled',
  'play-circle-outline',
  'playlist-add',
  'playlist-add-check',
  'playlist-play',
  'queue',
  'queue-music',
  'queue-play-next',
  'radio',
  'recent-actors',
  'remove-from-queue',
  'repeat',
  'repeat-one',
  'replay',
  'replay-10',
  'replay-30',
  'replay-5',
  'shuffle',
  'skip-next',
  'skip-previous',
  'slow-motion-video',
  'snooze',
  'sort-by-alpha',
  'stop',
  'subscriptions',
  'subtitles',
  'surround-sound',
  'video-call',
  'video-label',
  'video-library',
  'videocam',
  'videocam-off',
  'volume-down',
  'volume-mute',
  'volume-off',
  'volume-up',
  'web',
  'web-asset',
  'alternate-email',
  'business',
  'call',
  'call-end',
  'call-made',
  'call-merge',
  'call-missed',
  'call-missed-outgoing',
  'call-received',
  'call-split',
  'cancel-presentation',
  'cell-wifi',
  'chat',
  'chat-bubble',
  'chat-bubble-outline',
  'clear-all',
  'comment',
  'contact-mail',
  'contact-phone',
  'contacts',
  'desktop-access-disabled',
  'dialer-sip',
  'dialpad',
  'domain-disabled',
  'duo',
  'email',
  'forum',
  'import-contacts',
  'import-export',
  'invert-colors-off',
  'list-alt',
  'live-help',
  'location-off',
  'location-on',
  'mail-outline',
  'message',
  'mobile-screen-share',
  'no-sim',
  'pause-presentation',
  'person-add-disabled',
  'phone',
  'phonelink-erase',
  'phonelink-lock',
  'phonelink-ring',
  'phonelink-setup',
  'portable-wifi-off',
  'present-to-all',
  'print-disabled',
  'ring-volume',
  'rss-feed',
  'screen-share',
  'sentiment-satisfied-alt',
  'speaker-phone',
  'stay-current-landscape',
  'stay-current-portrait',
  'stay-primary-landscape',
  'stay-primary-portrait',
  'stop-screen-share',
  'swap-calls',
  'textsms',
  'unsubscribe',
  'voicemail',
  'vpn-key',
  'add',
  'add-box',
  'add-circle',
  'add-circle-outline',
  'archive',
  'backspace',
  'ballot',
  'block',
  'clear',
  'create',
  'delete-sweep',
  'drafts',
  'file-copy',
  'filter-list',
  'flag',
  'font-download',
  'forward',
  'gesture',
  'how-to-reg',
  'how-to-vote',
  'inbox',
  'link',
  'link-off',
  'low-priority',
  'mail',
  'markunread',
  'move-to-inbox',
  'next-week',
  'outlined-flag',
  'redo',
  'remove',
  'remove-circle',
  'remove-circle-outline',
  'reply',
  'reply-all',
  'report',
  'report-off',
  'save',
  'save-alt',
  'select-all',
  'send',
  'sort',
  'text-format',
  'unarchive',
  'undo',
  'waves',
  'weekend',
  'where-to-vote',
  'access-alarm',
  'access-alarms',
  'access-time',
  'add-alarm',
  'add-to-home-screen',
  'airplanemode-active',
  'airplanemode-inactive',
  'battery-alert',
  'battery-charging-full',
  'battery-full',
  'battery-std',
  'battery-unknown',
  'bluetooth',
  'bluetooth-connected',
  'bluetooth-disabled',
  'bluetooth-searching',
  'brightness-auto',
  'brightness-high',
  'brightness-low',
  'brightness-medium',
  'data-usage',
  'developer-mode',
  'devices',
  'dvr',
  'gps-fixed',
  'gps-not-fixed',
  'gps-off',
  'graphic-eq',
  'location-disabled',
  'location-searching',
  'mobile-friendly',
  'mobile-off',
  'network-cell',
  'network-wifi',
  'nfc',
  'screen-lock-landscape',
  'screen-lock-portrait',
  'screen-lock-rotation',
  'screen-rotation',
  'sd-storage',
  'settings-system-daydream',
  'signal-cellular-4-bar',
  'signal-cellular-alt',
  'signal-cellular-connected-no-internet-4-bar',
  'signal-cellular-no-sim',
  'signal-cellular-null',
  'signal-cellular-off',
  'signal-wifi-0-bar',
  'signal-wifi-4-bar',
  'signal-wifi-4-bar-lock',
  'signal-wifi-off',
  'storage',
  'usb',
  'wallpaper',
  'widgets',
  'wifi-lock',
  'wifi-tethering',
  'add-comment',
  'attach-file',
  'attach-money',
  'bar-chart',
  'border-all',
  'border-bottom',
  'border-clear',
  'border-color',
  'border-horizontal',
  'border-inner',
  'border-left',
  'border-outer',
  'border-right',
  'border-style',
  'border-top',
  'border-vertical',
  'bubble-chart',
  'drag-handle',
  'format-align-center',
  'format-align-justify',
  'format-align-left',
  'format-align-right',
  'format-bold',
  'format-clear',
  'format-color-fill',
  'format-color-reset',
  'format-color-text',
  'format-indent-decrease',
  'format-indent-increase',
  'format-italic',
  'format-line-spacing',
  'format-list-bulleted',
  'format-list-numbered',
  'format-list-numbered-rtl',
  'format-paint',
  'format-quote',
  'format-shapes',
  'format-size',
  'format-strikethrough',
  'format-textdirection-l-to-r',
  'format-textdirection-r-to-l',
  'format-underlined',
  'functions',
  'highlight',
  'insert-chart',
  'insert-chart-outlined',
  'insert-comment',
  'insert-drive-file',
  'insert-emoticon',
  'insert-invitation',
  'insert-link',
  'insert-photo',
  'linear-scale',
  'merge-type',
  'mode-comment',
  'monetization-on',
  'money-off',
  'multiline-chart',
  'notes',
  'pie-chart',
  'publish',
  'scatter-plot',
  'score',
  'short-text',
  'show-chart',
  'space-bar',
  'strikethrough-s',
  'table-chart',
  'text-fields',
  'title',
  'vertical-align-bottom',
  'vertical-align-center',
  'vertical-align-top',
  'wrap-text',
  'attachment',
  'cloud',
  'cloud-circle',
  'cloud-done',
  'cloud-download',
  'cloud-off',
  'cloud-queue',
  'cloud-upload',
  'create-new-folder',
  'folder',
  'folder-open',
  'folder-shared',
  'cast',
  'cast-connected',
  'cast-for-education',
  'computer',
  'desktop-mac',
  'desktop-windows',
  'developer-board',
  'device-hub',
  'device-unknown',
  'devices-other',
  'dock',
  'gamepad',
  'headset',
  'headset-mic',
  'keyboard',
  'keyboard-arrow-down',
  'keyboard-arrow-left',
  'keyboard-arrow-right',
  'keyboard-arrow-up',
  'keyboard-backspace',
  'keyboard-capslock',
  'keyboard-hide',
  'keyboard-return',
  'keyboard-tab',
  'keyboard-voice',
  'laptop',
  'laptop-chromebook',
  'laptop-mac',
  'laptop-windows',
  'memory',
  'mouse',
  'phone-android',
  'phone-iphone',
  'phonelink',
  'phonelink-off',
  'power-input',
  'router',
  'scanner',
  'security',
  'sim-card',
  'smartphone',
  'speaker',
  'speaker-group',
  'tablet',
  'tablet-android',
  'tablet-mac',
  'toys',
  'tv',
  'videogame-asset',
  'watch',
  'add-a-photo',
  'add-photo-alternate',
  'add-to-photos',
  'adjust',
  'assistant',
  'assistant-photo',
  'audiotrack',
  'blur-circular',
  'blur-linear',
  'blur-off',
  'blur-on',
  'brightness-1',
  'brightness-2',
  'brightness-3',
  'brightness-4',
  'brightness-5',
  'brightness-6',
  'brightness-7',
  'broken-image',
  'brush',
  'burst-mode',
  'camera',
  'camera-alt',
  'camera-front',
  'camera-rear',
  'camera-roll',
  'center-focus-strong',
  'center-focus-weak',
  'collections',
  'collections-bookmark',
  'color-lens',
  'colorize',
  'compare',
  'control-point',
  'control-point-duplicate',
  'crop',
  'crop-16-9',
  'crop-3-2',
  'crop-5-4',
  'crop-7-5',
  'crop-din',
  'crop-free',
  'crop-landscape',
  'crop-original',
  'crop-portrait',
  'crop-rotate',
  'crop-square',
  'dehaze',
  'details',
  'edit',
  'exposure',
  'exposure-neg-1',
  'exposure-neg-2',
  'exposure-plus-1',
  'exposure-plus-2',
  'exposure-zero',
  'filter',
  'filter-1',
  'filter-2',
  'filter-3',
  'filter-4',
  'filter-5',
  'filter-6',
  'filter-7',
  'filter-8',
  'filter-9',
  'filter-9-plus',
  'filter-b-and-w',
  'filter-center-focus',
  'filter-drama',
  'filter-frames',
  'filter-hdr',
  'filter-none',
  'filter-tilt-shift',
  'filter-vintage',
  'flare',
  'flash-auto',
  'flash-off',
  'flash-on',
  'flip',
  'gradient',
  'grain',
  'grid-off',
  'grid-on',
  'hdr-off',
  'hdr-on',
  'hdr-strong',
  'hdr-weak',
  'healing',
  'image',
  'image-aspect-ratio',
  'image-search',
  'iso',
  'landscape',
  'leak-add',
  'leak-remove',
  'lens',
  'linked-camera',
  'looks',
  'looks-3',
  'looks-4',
  'looks-5',
  'looks-6',
  'looks-one',
  'looks-two',
  'loupe',
  'monochrome-photos',
  'movie-creation',
  'movie-filter',
  'music-note',
  'music-off',
  'nature',
  'nature-people',
  'navigate-before',
  'navigate-next',
  'palette',
  'panorama',
  'panorama-fish-eye',
  'panorama-horizontal',
  'panorama-vertical',
  'panorama-wide-angle',
  'photo',
  'photo-album',
  'photo-camera',
  'photo-filter',
  'photo-library',
  'photo-size-select-actual',
  'photo-size-select-large',
  'photo-size-select-small',
  'picture-as-pdf',
  'portrait',
  'remove-red-eye',
  'rotate-90-degrees-ccw',
  'rotate-left',
  'rotate-right',
  'shutter-speed',
  'slideshow',
  'straighten',
  'style',
  'switch-camera',
  'switch-video',
  'tag-faces',
  'texture',
  'timelapse',
  'timer',
  'timer-10',
  'timer-3',
  'timer-off',
  'tonality',
  'transform',
  'tune',
  'view-comfy',
  'view-compact',
  'vignette',
  'wb-auto',
  'wb-cloudy',
  'wb-incandescent',
  'wb-iridescent',
  'wb-sunny',
  '360',
  'add-location',
  'atm',
  'beenhere',
  'category',
  'compass-calibration',
  'departure-board',
  'directions',
  'directions-bike',
  'directions-boat',
  'directions-bus',
  'directions-car',
  'directions-railway',
  'directions-run',
  'directions-subway',
  'directions-transit',
  'directions-walk',
  'edit-attributes',
  'edit-location',
  'ev-station',
  'fastfood',
  'flight',
  'hotel',
  'layers',
  'layers-clear',
  'local-activity',
  'local-airport',
  'local-atm',
  'local-bar',
  'local-cafe',
  'local-car-wash',
  'local-convenience-store',
  'local-dining',
  'local-drink',
  'local-florist',
  'local-gas-station',
  'local-grocery-store',
  'local-hospital',
  'local-hotel',
  'local-laundry-service',
  'local-library',
  'local-mall',
  'local-movies',
  'local-offer',
  'local-parking',
  'local-pharmacy',
  'local-phone',
  'local-pizza',
  'local-play',
  'local-post-office',
  'local-printshop',
  'local-see',
  'local-shipping',
  'local-taxi',
  'map',
  'money',
  'my-location',
  'navigation',
  'near-me',
  'not-listed-location',
  'person-pin',
  'person-pin-circle',
  'pin-drop',
  'place',
  'rate-review',
  'restaurant',
  'restaurant-menu',
  'satellite',
  'store-mall-directory',
  'streetview',
  'subway',
  'terrain',
  'traffic',
  'train',
  'tram',
  'transfer-within-a-station',
  'transit-enterexit',
  'trip-origin',
  'zoom-out-map',
  'apps',
  'arrow-back',
  'arrow-back-ios',
  'arrow-downward',
  'arrow-drop-down',
  'arrow-drop-down-circle',
  'arrow-drop-up',
  'arrow-forward',
  'arrow-forward-ios',
  'arrow-left',
  'arrow-right',
  'arrow-upward',
  'cancel',
  'check',
  'chevron-left',
  'chevron-right',
  'close',
  'expand-less',
  'expand-more',
  'first-page',
  'fullscreen',
  'fullscreen-exit',
  'last-page',
  'menu',
  'more-horiz',
  'more-vert',
  'refresh',
  'subdirectory-arrow-left',
  'subdirectory-arrow-right',
  'unfold-less',
  'unfold-more',
  'adb',
  'airline-seat-flat',
  'airline-seat-flat-angled',
  'airline-seat-individual-suite',
  'airline-seat-legroom-extra',
  'airline-seat-legroom-normal',
  'airline-seat-legroom-reduced',
  'airline-seat-recline-extra',
  'airline-seat-recline-normal',
  'bluetooth-audio',
  'confirmation-number',
  'disc-full',
  'drive-eta',
  'enhanced-encryption',
  'event-available',
  'event-busy',
  'event-note',
  'folder-special',
  'live-tv',
  'mms',
  'more',
  'network-check',
  'network-locked',
  'no-encryption',
  'ondemand-video',
  'personal-video',
  'phone-bluetooth-speaker',
  'phone-callback',
  'phone-forwarded',
  'phone-in-talk',
  'phone-locked',
  'phone-missed',
  'phone-paused',
  'power',
  'power-off',
  'priority-high',
  'sd-card',
  'sms',
  'sms-failed',
  'sync',
  'sync-disabled',
  'sync-problem',
  'system-update',
  'tap-and-play',
  'time-to-leave',
  'tv-off',
  'vibration',
  'voice-chat',
  'vpn-lock',
  'wc',
  'wifi',
  'wifi-off',
  'ac-unit',
  'airport-shuttle',
  'all-inclusive',
  'beach-access',
  'business-center',
  'casino',
  'child-care',
  'child-friendly',
  'fitness-center',
  'free-breakfast',
  'golf-course',
  'hot-tub',
  'kitchen',
  'meeting-room',
  'no-meeting-room',
  'pool',
  'room-service',
  'rv-hookup',
  'smoke-free',
  'smoking-rooms',
  'spa',
  'cake',
  'domain',
  'group',
  'group-add',
  'location-city',
  'mood',
  'mood-bad',
  'notifications',
  'notifications-active',
  'notifications-none',
  'notifications-off',
  'notifications-paused',
  'pages',
  'party-mode',
  'people',
  'people-outline',
  'person',
  'person-add',
  'person-outline',
  'plus-one',
  'poll',
  'public',
  'school',
  'sentiment-dissatisfied',
  'sentiment-satisfied',
  'sentiment-very-dissatisfied',
  'sentiment-very-satisfied',
  'share',
  'thumb-down-alt',
  'thumb-up-alt',
  'whatshot',
  'check-box',
  'check-box-outline-blank',
  'indeterminate-check-box',
  'radio-button-checked',
  'radio-button-unchecked',
  'star',
  'star-border',
  'star-half',
  'toggle-off',
  'toggle-on',
];
