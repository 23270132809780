import {
  Badge,
  Grid,
  Button,
  ImageListItemBar,
  Box,
  Typography,
} from "@mui/material";

// images
import default_image from "./../../../../../assets/images/default_product_image.png";
// import { formatPrice } from "../../../../utils/Strings";

export default function Products(props) {
  const { cart, products, handlePressItem } = props;
  return (
    <Grid
      container
      sx={{
        width: "100%",
        maxHeight: "70vh",
        overflow: "hidden",
        overflowY: "scroll",
        pt: 2,
      }}
    >
      {products.map((item, index) => (
        <Grid key={index} item xs={4} lg={3}>
          <Button
            sx={{
              display: "list-item",
              background: "transparent",
              maxHeight: 250,
              textAlign: "center",
              maxWidth: 130,
            }}
            onClick={() => handlePressItem(item)}
          >
            <Badge
              badgeContent={
                cart?.find((row) => row.id === item.id)?.quantity || 0
              }
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: 14,
                  height: 30,
                  minWidth: 30,
                  borderRadius: 30,
                  fontWeight: "bold",
                },
              }}
            >
              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  color: "white",
                }}
              >
                {item?.status === 1 && item?.available_stock <= 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: " translate(-50%, -50%)",
                    }}
                  >
                    Out of Stock
                  </div>
                )}
                {item?.status === 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: " translate(-50%, -50%)",
                    }}
                  >
                    Inactive
                  </div>
                )}
                <img
                  src={item?.image || default_image}
                  alt={item.title}
                  loading="lazy"
                  height="80px"
                  width="80px"
                  style={{
                    borderRadius: 10,
                    opacity:
                      Number(item?.available_stock <= 0) ||
                      isNaN(item?.available_stock) ||
                      item?.status === 0
                        ? "50%"
                        : "100%",
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = default_image;
                  }}
                />
              </div>
            </Badge>
            <ImageListItemBar
              title={
                <Typography
                  textAlign="left"
                  whiteSpace="pre-wrap"
                  style={{
                    fontSize: "12px",
                    maxHeight: 60,
                    overflow: "scroll",
                  }}
                >
                  {item.product_name}
                </Typography>
              }
              subtitle={
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    marginTop: 5,
                    color: "#FFFFFF",
                  }}
                >
                  <div>{item?.product_code}</div>
                  {/* <div style={{ maxWidth: 50 }}>{formatPrice(item?.price)}</div>{" "}
                  <div>
                    {Number(item?.available_stock) > 0
                      ? item.available_stock
                      : 0}
                  </div> */}
                </div>
              }
              position="below"
            />
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
