import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { View } from "react-native";
import * as DocumentPicker from "expo-document-picker";

import {
  Snackbar,
  TextField,
  Box,
  Stack,
  Alert,
  Grid,
  CardMedia,
  Typography,
  CircularProgress,
  Backdrop,
  Button,
} from "@mui/material";

// css
import GlobalStyles from "../../../../styles/GlobalStyles";

//images
import empty_image from "./../../../../../assets/images/empty_image.svg";

// services
import {
  AddBrand,
  updateBrand,
} from "../../../../services/StoreParameter/BrandService";
import { uploadPhoto } from "../../../../services/UploadService";

const FormBrand = ({ onDataListChange, action }) => {
  const [brand_name, setBrandName] = useState("");
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "success" });
  const [isAddAction, setIsAddAction] = useState(true);

  useEffect(() => {
    onDataListChange(true);

    // update from list
    if (action && action?.uuid) {
      setIsAddAction(false);

      // from update component
      setBrandName(action?.brand_name);
      setBrandImage(action?.brand_image);
      setFormBrandImage(action?.brand_image);
    } else {
      setIsAddAction(true);
    }
  }, [action]);

  // uploading of photo
  const [brand_image, setBrandImage] = useState(null);
  const [formBrandImage, setFormBrandImage] = useState(null);

  // snackbar for unsupported file type
  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const pickImage = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/jpg, image/jpeg, image/png, image/svg, image/gif",
      copyToCacheDirectory: false,
    });
    if (result.canceled) {
      return;
    }
    if (
      result.mimeType == "image/jpeg" ||
      result.mimeType == "image/png" ||
      result.mimeType == "image/svg" ||
      result.mimeType == "image/gif"
    ) {
      setBrandImage(result.uri);
      let formData = new FormData();
      formData.append("file", result.file);
      const link = await uploadPhoto(formData);
      if (link) {
        setFormBrandImage(link.data.link);
      }
    } else {
      setOpenSnack(true);
      setSnackVariant("error");
      setErrMsg("Please select valid image.");
    }
  };

  const addRecord = async () => {
    const store_id = (await AsyncStorage.getItem("store_id")) || null;
    const created_by = (await AsyncStorage.getItem("user_id")) || null;
    const brand_image = formBrandImage;

    try {
      onDataListChange(false);
      const { data } = await AddBrand({
        store_id,
        brand_name,
        created_by,
        brand_image,
      });

      if (data && data?.success) {
        setAlert({ message: data?.message, type: "success" });
        setOpen(true);
        cancelForm();
        // setOpenLoader(true);
      } else {
        setAlert({
          message: data?.message?.brand_name[0] || null,
          type: "warning",
        });
        setOpen(true);

        console.log(
          "[fail add brand] : ",
          data?.message?.brand_name[0] || null
        );
      }
      setOpenLoader(false);
    } catch (error) {
      console.log("[err add brand] : ", error);

      setAlert({ message: "Invalid input.", type: "error" });
      setOpen(true);
    }
  };

  const updateRecord = async () => {
    const store_id = (await AsyncStorage.getItem("store_id")) || null;
    try {
      onDataListChange(false);

      const param = {
        brand_name,
        brand_image: formBrandImage,
      };

      const { data } = await updateBrand(action?.uuid, param);

      if (data && data?.success) {
        setAlert({ message: data?.message, type: "success" });
        setOpen(true);
        cancelForm();
        setFormBrandImage(null);
        //  handleCloseLoader();
      } else {
        console.log("[fail add brand] : ", data.message || null);
      }
      setOpenLoader(false);
    } catch (error) {
      console.log("[err add brand] : ", error);

      setAlert({ message: "Invalid input.", type: "error" });
      setOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // form validate
    const errors = {};
    if (!brand_name) {
      errors.brand_name = "Name is required";
    }

    // submission
    if (isAddAction) {
      setOpenLoader(true);
      await addRecord();
    } else {
      setOpenLoader(true);
      await updateRecord();
    }

    // whenver errors occurs, set them in state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
  };

  const cancelForm = async () => {
    onDataListChange(false);
    setBrandName("");
    setBrandImage("");
    setErrors({});
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // loader
  const [openLoader, setOpenLoader] = React.useState(false);

  const handleCloseLoader = () => {
    setOpenLoader(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ ml: 1, mt: 4, mb: 2 }} variant="h6" component="div">
            Brand Form
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <View>
              <Grid container style={GlobalStyles.productWrapper}>
                <Grid item xs={4}>
                  <CardMedia
                    name="brand_image"
                    style={GlobalStyles.productImage}
                    component="img"
                    src={brand_image ? brand_image : empty_image}
                    alt=""
                    onClick={pickImage}
                    onChange={(e) => setBrandImage(e.target.value)}
                    helperText={errors.brand_image}
                  />
                </Grid>
              </Grid>
            </View>
            <div>
              <TextField
                fullWidth
                id="input-brand-name"
                label="Brand name"
                margin="normal"
                variant="filled"
                type="brand_name"
                value={brand_name}
                onChange={(e) => setBrandName(e.target.value)}
                error={errors.brand_name ? true : false}
                helperText={
                  errors.brand_name ? "Brand name is required" : "(Brand name)"
                }
              />
            </div>

            <Stack
              spacing={2}
              direction="row"
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={3}
            >
              <Button variant="outlined" onClick={cancelForm}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                {isAddAction ? "Add" : "Update"}
              </Button>
            </Stack>

            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                severity={alert?.type}
                sx={{ width: "100%" }}
              >
                {alert?.message || "Brand successfully added"}
              </Alert>
            </Snackbar>

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleCloseSnack}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseSnack}
                severity={snackVariant}
                sx={{ width: "100%" }}
              >
                {errMsg}
              </Alert>
            </Snackbar>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FormBrand;
