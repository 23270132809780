import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Grid, Alert, Button, Typography } from "@mui/material";
import {
  getPOSProducts,
  storeReturnTransaction,
} from "../../../services/PosService";
import ChargeModal from "./components/ChargeModal";
import ReceiptModal from "./components/ReceiptModal";
import { QuantityDialog } from "./components/QuantityDialog";

// global css
import UtangModal from "./components/UtangModal";
import { DiscountDialog } from "./components/DiscountDialog";
import PosProducts from "./sections/PosProducts";
import ReturnDetails from "./sections/ReturnDetails";
import PosSuccess from "./sections/PosSuccess";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SearchProduct from "./components/SearchProduct";
import CategoryChips from "./sections/CategoryChips";
import PosProductSkeleton from "./sections/PosProductSkeleton";

export default function Return() {
  let cancelTokenSource;
  const tableRef = useRef(0);
  const [storeId, setStoreId] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [barcodeMode, setBarcodeMode] = useState(false);
  const [filter, setFilter] = useState(0);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("items")) || []
  );
  const transaction_no = localStorage.getItem("transaction_no");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [openChargeModal, setChargeModalOpen] = useState(false);
  const [openReceiptModal, setReceiptModalOpen] = useState(false);
  const [openUtangModal, setUtangModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [addDiscount, setAddDiscount] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [isSuccessTransaction, setSuccessTransaction] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    if (cart.length === 0) {
      location.href = "/transactions";
    }
  }, []);

  const loadMore = async () => {
    const newRows = Number(rowsPerPage) + 100;
    setRowsPerPage(newRows);
    getProductList(searchText, storeId, newRows, filter);
  };

  const handleOpenChargeModal = () => {
    setIsLoading(!isLoading);
    setChargeModalOpen(!openChargeModal);
  };
  const handleOpenUtangModal = () => {
    setIsLoading(!isLoading);
    setUtangModalOpen(!openUtangModal);
  };

  const subTotal = Number(
    cart.reduce((accumulator, object) => {
      return accumulator + object.price * object.quantity;
    }, 0)
  );

  const grandTotal = Number(subTotal - discount).toFixed(2);

  const getProductList = async (word, storeId, rowsPerPage, filter) => {
    if (word.length > 0 && word.length < 4) {
      return;
    }

    // Cancel the previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new search");
    }

    // Create a new CancelToken source
    cancelTokenSource = axios.CancelToken.source();

    return await getPOSProducts(
      word,
      storeId,
      rowsPerPage,
      filter,
      cancelTokenSource
    )
      .then((response) => {
        if (response.data.length === products.length) {
          setHasNextPage(false);
        } else {
          setProducts(response.data);
        }
        return response.data;
      })
      .finally(() => {
        setFetching(false);
      });
  };
  const handlePressItem = (item) => {
    handleAddProduct(1, item);
  };

  // get products from api and set to products state
  useEffect(() => {
    const fetchProduct = async () => {
      const id = await AsyncStorage.getItem("store_id");
      getProductList("", id, rowsPerPage, filter);
      setStoreId(id);
    };
    fetchProduct();
  }, []);

  const handleAddProduct = (quantity, item) => {
    const selectedItem = item ? item : selected;
    const selectedFromCart = cart.find((row) => row.id === selectedItem.id);
    const currentCart = cart.filter((row) => row.id !== selectedItem.id);
    const tempCart = cart.find((row) => row.id === selectedItem.id);
    const accumulatedQuantity = selectedFromCart?.quantity
      ? (selectedFromCart.quantity += quantity)
      : quantity;
    const selectedItemIndex = products.findIndex(
      (row) => row.id === selectedItem.id
    );
    const tempProducts = products;
    if (!tempProducts[selectedItemIndex].orig_stock) {
      tempProducts[selectedItemIndex].orig_stock = selectedItem.available_stock;
    }
    tempProducts[selectedItemIndex].available_stock =
      selectedItem.available_stock - quantity;
    setProducts(tempProducts);
    setCart([
      ...currentCart,
      {
        ...selectedItem,
        quantity: accumulatedQuantity,
        is_return: tempCart?.is_return || 0,
      },
    ]);
    if (item) {
      setSearchText("");
    }

    if (tableRef.current) {
      setTimeout(() => {
        tableRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 100);
    }
  };

  const handleCharge = async (amount, customerId, payment_type) => {
    setPaymentAmount(Number(amount));
    setChargeModalOpen(false);
    const products = cart.map((row) => {
      if (row?.product) {
        return {
          product_id: row.product.id,
          quantity: row.quantity,
          price: row.price,
          is_damaged: row?.product?.is_return || 0,
          total_amount: Math.abs(row.price * row.quantity),
        };
      } else {
        return {
          product_id: row.id,
          quantity: row.quantity,
          price: row.price,
          is_damaged: row?.is_return || 0,
          total_amount: Math.abs(row.price * row.quantity),
        };
      }
    });

    const params = {
      products: products,
      customer_id: customerId || null,
      quantity: products.length,
      subtotal_amount: subTotal,
      discount_amount: discount,
      vatable_sale: 0,
      paid_amount: amount,
      is_utang: payment_type === 1 ? 0 : 1,
      payment_type: payment_type,
    };
    try {
      const data = await storeReturnTransaction(transaction_no, params);
      setReceipt({ ...data?.data });
      setSuccessTransaction(!isSuccessTransaction);
      localStorage.removeItem("items");
      localStorage.removeItem("transaction_no");
      localStorage.removeItem("customer");
    } catch (error) {
      console.error("Transaction Failed!");
    }
  };

  const handleUtang = async (customerId) => {
    setUtangModalOpen(false);
    const products = cart.map((row) => {
      if (row?.product) {
        return {
          product_id: row.product.id,
          quantity: row.quantity,
          price: row.price,
          is_damaged: row.is_return,
          total_amount: row.total_amount,
        };
      } else {
        return {
          product_id: row.id,
          quantity: row.quantity,
          price: row.price,
          is_damaged: 0,
          total_amount: row.price * row.quantity,
        };
      }
    });

    const params = {
      products: products,
      customer_id: customerId || null,
      quantity: products.length,
      subtotal_amount: subTotal,
      discount_amount: discount,
      vatable_sale: 0,
      paid_amount: 0,
      is_utang: 1,
      payment_type: 0,
    };

    try {
      const data = await storeReturnTransaction(transaction_no, params);
      setReceipt({ ...data?.data });
      setSuccessTransaction(!isSuccessTransaction);
    } catch (error) {
      Alert.error("Transaction Failed!");
    }
  };

  const handleNewTransaction = () => {
    setCart([]);
    setSuccessTransaction(false);
    setIsLoading(false);
    setDiscount(0);
    setPaymentAmount(0);
  };

  const handleReceipt = () => {
    setReceiptModalOpen(!openReceiptModal);
  };

  const handleCopyLink = async () => {
    const copyLink = receipt?.data?.transaction_receipt_link;
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(copyLink);
    } else {
      document.execCommand("copy", true, copyLink);
    }
    alert("Copied Link!");
  };

  return fetching ? (
    <PosProductSkeleton />
  ) : (
    <Grid container>
      <Grid item sm={12} md={cart.length ? 6 : 12}>
        <CategoryChips
          searchText={searchText}
          storeId={storeId}
          rowsPerPage={rowsPerPage}
          filter={filter}
          setFilter={setFilter}
          getProductList={getProductList}
        />
        <SearchProduct
          storeId={storeId}
          rowsPerPage={rowsPerPage}
          getProductList={getProductList}
          searchText={searchText}
          setSearchText={setSearchText}
          disabled={isSuccessTransaction}
          filter={filter}
          setBarcodeMode={setBarcodeMode}
          barcodeMode={barcodeMode}
          handleAddProduct={handleAddProduct}
          setSelected={setSelected}
          products={products}
        />
        <PosProducts
          cart={cart}
          products={products}
          handlePressItem={handlePressItem}
          isSuccessTransaction={isSuccessTransaction}
        />
        <hr />
        {products && products.length > 0 ? (
          hasNextPage ? (
            <Button onClick={loadMore} fullWidth>
              Load More
            </Button>
          ) : (
            <></>
          )
        ) : (
          <Typography mt={2} align="center">
            No Record Found
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          background: "#050F1A",
          borderRadius: 1,
        }}
      >
        {!isSuccessTransaction && cart.length ? (
          <ReturnDetails
            isLoading={isLoading}
            discount={discount}
            isSuccessTransaction={isSuccessTransaction}
            cart={cart}
            setCart={setCart}
            setProducts={setProducts}
            products={products}
            handleOpenChargeModal={handleOpenChargeModal}
            handleOpenUtangModal={handleOpenUtangModal}
            handleDiscount={() => setAddDiscount(!addDiscount)}
            tableRef={tableRef}
          />
        ) : (
          <></>
        )}
        {isSuccessTransaction && cart.length ? (
          <PosSuccess
            handleNewTransaction={handleNewTransaction}
            discount={discount}
            paymentAmount={paymentAmount}
            isSuccessTransaction={isSuccessTransaction}
            cart={cart}
            handleReceipt={handleReceipt}
            handleCopyLink={handleCopyLink}
          />
        ) : (
          <></>
        )}
      </Grid>
      <QuantityDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleAddProduct}
        item={selected}
      />
      <DiscountDialog
        currentTotal={subTotal}
        discount={discount}
        open={addDiscount}
        onClose={() => setAddDiscount(false)}
        onConfirm={(discount) => setDiscount(discount)}
      />
      <ChargeModal
        open={openChargeModal}
        handleClose={() => {
          setChargeModalOpen(false);
          setIsLoading(false);
        }}
        grandTotal={grandTotal}
        handleSubmit={handleCharge}
        setOpen={setChargeModalOpen}
      />
      <ReceiptModal
        open={openReceiptModal}
        handleClose={() => setReceiptModalOpen(false)}
        grandTotal={grandTotal}
        handleSubmit={handleCharge}
        receipt={receipt}
        handleReceipt={handleReceipt}
        change={
          receipt?.data?.payment_status ? paymentAmount - grandTotal : null
        }
      />
      <UtangModal
        open={openUtangModal}
        setOpen={setUtangModalOpen}
        handleClose={() => {
          setUtangModalOpen(false);
          setIsLoading(false);
        }}
        grandTotal={grandTotal}
        handleSubmit={handleUtang}
      />
    </Grid>
  );
}
