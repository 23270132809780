import AsyncStorage from '@react-native-async-storage/async-storage';
import apiRequest from './ApiService';

export const login = async (param) => {
  try {
    return await apiRequest.post('/login', param);
  } catch (error) {
    console.log(error);
  }
};

export const logout = async () => {
  try {
    AsyncStorage.clear();
    return {
      status: 'success',
      message: 'You are logged out',
    };
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async (param) => {
  try {
    return await apiRequest.post('/reset-password', param);
  } catch (error) {
    console.log(error);
  }
};

export const confirmResetPassword = async (param, uuid, time) => {
  try {
    return await apiRequest.post(
      '/confirm-reset-password/' + uuid + '/' + time,
      param
    );
  } catch (error) {
    console.log(error);
  }
};

export const checkResetPassword = async (uuid, time) => {
  try {
    return await apiRequest.post('/check-reset-password/' + uuid + '/' + time);
  } catch (error) {
    console.log(error);
  }
};

export const getToken = async () => {
  try {
    return (await AsyncStorage.getItem('access_token')) ? true : false;
  } catch (error) {
    return false;
  }
};
