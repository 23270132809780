import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { Alert } from "../../../../components/common";
import { LoadingButton } from "@mui/lab";

export function QuantityDialog(props) {
  const { onClose, open, onConfirm, item } = props;
  const [productQty, setProductQty] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const availableStock = item?.available_stock || 0;

  const handleCancel = () => {
    onClose();
    setProductQty(1);
  };

  const handleOk = () => {
    if (Number(productQty) > 0 && Number(productQty) <= availableStock) {
      onConfirm && onConfirm(productQty);
      onClose();
      setProductQty(1);
      setError(false);
    } else {
      setError(true);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "25%", maxHeight: 435 },
        textAlign: "center",
      }}
      maxWidth="sm"
      open={open}
      keepMounted
    >
      <DialogTitle>Quantity</DialogTitle>
      <DialogContent
        dividers
        display="flex"
        sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
      >
        <Button
          sx={{
            background: "radial-gradient(black, transparent);",
            fontSize: "25px",
          }}
          onClick={() => {
            Number(productQty) > 1 && setProductQty(productQty - 1);
          }}
          disabled={productQty <= 1}
        >
          -
        </Button>
        <TextField
          fullWidth
          inputProps={{
            min: 0,
            max: availableStock,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          sx={{ minWidth: 50, textAlignLast: "center" }}
          value={productQty}
          onChange={(e) => {
            const reg = /^[0-9\b]+$/;
            if (e.target.value === "" || reg.test(e.target.value)) {
              setProductQty(Number(e.target.value));
            }
          }}
          onKeyDown={(event) => {
            const reg = /^[0-9\b]+$/;
            if (!reg.test(event.key) && event.key != "Backspace") {
              event.preventDefault();
            }
          }}
          error={error}
        />
        <Button
          sx={{
            background: "radial-gradient(black, transparent);",
            fontSize: "25px",
          }}
          onClick={() => {
            Number(productQty) < availableStock &&
              setProductQty(Number(productQty) + 1);
          }}
        >
          +
        </Button>
      </DialogContent>
      <Typography sx={{ color: "white", p: 2 }} fontWeight="md">
        Available Stocks: {availableStock}
      </Typography>
      {error && (
        <Alert
          onClose={() => {
            setError(false);
          }}
          severity="error"
          sx={{ width: "100%", alignItems: "center" }}
        >
          <Typography sx={{ color: "white" }} fontWeight="md">
            Invalid Quantity !
          </Typography>
        </Alert>
      )}
      <DialogActions
        sx={{
          justifyContent: "space-between",
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        <Button fullWidth onClick={handleCancel}>
          CANCEL
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          color="success"
          onClick={() => {
            setIsLoading(true);
            handleOk();
          }}
          loading={isLoading}
        >
          CONFIRM
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
