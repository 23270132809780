import * as React from "react";
import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import GlobalStyles from "../../../../styles/GlobalStyles";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { checkDsrTransactions } from "../../../../services/report/SalesReportService";
import { TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#050F1A",
  border: "1px solid #fff",
  boxShadow: 24,
  p: 4,
};

export default function DSRDateModal(props) {
  const {
    open,
    handleClose,
    handleGenerate,
    setDateDSR,
    isLoading,
    showButton,
    setShowButton,
    setGenerateOrShow,
    generateOrShow,
    setCashOnHand,
  } = props;

  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cash_on_hand: "",
    },
  });

  const [date, setDate] = useState({
    start: dayjs(),
  });
  const [disabled, setDisabled] = useState(false);

  const handleChange = (value) => {
    setCashOnHand(value);
  };

  useEffect(() => {
    setDate({
      ...date,
      start: dayjs(),
    });
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      const response = await checkDsrTransactions(
        `?report_date=${date.start.format("YYYY-MM-DD")}`
      );
      setValue("cash_on_hand", response?.data?.data?.end_cash);
      handleChange(response?.data?.data?.end_cash);
      if (
        response?.data?.data?.is_today == 1 &&
        response?.data?.data?.has_record == 0
      ) {
        setDisabled(false);
        setGenerateOrShow(true);
      } else if (
        response?.data?.data?.is_today == 1 &&
        response?.data?.data?.has_record == 1
      ) {
        setDisabled(false);
        setGenerateOrShow(true);
      } else if (
        response?.data?.data?.is_today == 0 &&
        response?.data?.data?.has_record == 0
      ) {
        setDisabled(false);
        setGenerateOrShow(true);
      } else if (
        response?.data?.data?.is_today == 0 &&
        response?.data?.data?.has_record == 1
      ) {
        setDisabled(true);
        setGenerateOrShow(false);
      } else {
        setDisabled(false);
        setGenerateOrShow(false);
      }
      setShowButton(true);
    }
    if (date?.start) {
      fetchData();
    }
  }, [date]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              mb={2}
              textAlign={"center"}
            >
              Generate Daily Sales Report
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                mb={2}
                label="Select Date"
                value={date?.start}
                onChange={(newValue) =>
                  setDate({
                    ...date,
                    start: newValue,
                  })
                }
                disableFuture={true}
              />
            </LocalizationProvider>

            {showButton && (
              <>
                <Controller
                  name="cash_on_hand"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      onInput={(e) => handleChange(e.target.value)}
                      disabled={disabled}
                      margin="normal"
                      fullWidth
                      label="Cash on hand"
                      variant="filled"
                      inputProps={{ autoComplete: "none" }}
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      error={!!errors.cost}
                      helperText={errors.cost && "Cash on hand is required."}
                      {...field}
                    />
                  )}
                />
                <LoadingButton
                  sx={{ mt: 2 }}
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={() => setDateDSR(date)}
                  loading={isLoading}
                  disabled={
                    date?.start?.format("YYYY-MM-DD") === "Invalid Date" ||
                    date?.start === null
                  }
                >
                  {generateOrShow ? "Generate" : "Show"}
                </LoadingButton>

                {/* <LoadingButton
                  sx={{ mt: 2 }}
                  fullWidth
                  variant='contained'
                  style={GlobalStyles.primaryBtn}
                  onClick={() => setDateDSR(date)}
                  loading={isLoading}
                >
                  Show
                </LoadingButton> */}
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
