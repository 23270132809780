import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { LogoTransparent } from "../../../components/common";
import { useParams } from "react-router-dom";
import { getTransactionReceipt } from "../../../services/TransactionService";

const products = [
  {
    name: "Product 1",
    desc: "A nice thing",
    price: "$9.99",
  },
  {
    name: "Product 2",
    desc: "Another thing",
    price: "$3.45",
  },
  {
    name: "Product 3",
    desc: "Something else",
    price: "$6.51",
  },
  {
    name: "Product 4",
    desc: "Best thing of all",
    price: "$14.11",
  },
  { name: "Shipping", desc: "", price: "Free" },
];

const addresses = ["1 MUI Drive", "Reactville", "Anytown", "99999", "USA"];
const payments = [
  { name: "Card type", detail: "Visa" },
  { name: "Card holder", detail: "Mr John Smith" },
  { name: "Card number", detail: "xxxx-xxxx-xxxx-1234" },
  { name: "Expiry date", detail: "04/2024" },
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
];

export default function TransactionReceipt() {
  const { id } = useParams();
  const { store_name } = useParams();
  const [transaction, setTransaction] = useState([]);
  const [products, setProducts] = useState([]);

  const getTransaction = (id, store_name) => {
    getTransactionReceipt(id, store_name).then((rs) => {
      setTransaction(rs.data.data);
      setProducts(rs.data.data.products);
    });
  };

  useEffect(() => {
    getTransaction(id, store_name);
  }, []);

  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{
          my: { xs: 3, md: 6 },
          p: { xs: 2, md: 3 },
          backgroundColor: "#fff",
          color: "#000",
        }}
      >
        {/* <Typography variant='h6' gutterBottom color='#000'>
          Order summary {id}
        </Typography> */}
        <Box sx={{ my: 3, display: "inline-block" }}>
          {transaction?.store?.logo ? (
            <LogoTransparent
              otherSrc={transaction?.store?.logo}
              height={"50px !important"}
              width={"120px !important"}
            />
          ) : (
            <LogoTransparent
              height={"50px !important"}
              width={"120px !important"}
            />
          )}
        </Box>
        {/* <Typography
          sx={{ color: '#000 !important' }}
          fontWeight={700}
          gutterBottom
          mb={3}
        >
          Store Name: {transaction.store?.name}
        </Typography> */}

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={7}>
            <Typography
              sx={{ color: "#000 !important" }}
              fontWeight={600}
              gutterBottom
            >
              Transaction Details
            </Typography>
            <Typography sx={{ color: "#000 !important" }} gutterBottom>
              Number: {transaction.transaction_number}
            </Typography>
            <Typography sx={{ color: "#000 !important" }} gutterBottom>
              {/* {addresses.join(', ')} */}
              Date: {new Date(transaction.created_at).toLocaleString("en-US")}
            </Typography>
            {/* <Typography sx={{ color: '#000 !important' }} gutterBottom>
              Type: {transaction.transaction_type_name}
            </Typography> */}
          </Grid>
          <Grid item container direction="column" xs={12} sm={5}>
            <Typography
              sx={{ color: "#000 !important" }}
              fontWeight={700}
              gutterBottom
            >
              Customer Details
            </Typography>

            <Typography sx={{ color: "#000 !important" }} gutterBottom>
              {transaction.customer?.first_name &&
              transaction.customer?.last_name
                ? transaction.customer?.first_name +
                  " " +
                  transaction.customer?.last_name
                : "----"}
            </Typography>
            <Typography sx={{ color: "#000 !important" }} gutterBottom>
              {transaction.customer?.address_text
                ? transaction.customer?.address_text
                : "----"}
            </Typography>
            {/* {payments.map((payment) => (
                <React.Fragment key={payment.name}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: '#000 !important' }} gutterBottom>
                      {payment.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: '#000 !important' }} gutterBottom>
                      {payment.detail}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))} */}
          </Grid>
        </Grid>

        {/* <List disablePadding>
          {products.map((product) => (
            <ListItem key={product.name} sx={{ py: 1, px: 0, color: '#000' }}>
              <ListItemText
                sx={{ color: '#000 !important' }}
                primary={
                  <Typography component='span' variant='body2'>
                    {product.name}
                  </Typography>
                }
                secondary={product.desc}
              />
              <Typography sx={{ color: '#000 !important' }} variant='body2'>
                {product.price}
              </Typography>
            </ListItem>
          ))}

          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary='Total' />
            <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
              $34.06
            </Typography>
          </ListItem>
        </List> */}
        <TableContainer>
          <Table
            sx={{ minWidth: 650, border: "1px solid" }}
            aria-label="caption table"
          >
            {/* <caption>A basic table example with a caption</caption> */}
            <TableHead>
              <TableRow
                sx={{ backgroundColor: "#309737 !important", border: "none" }}
              >
                <TableCell>Product Code</TableCell>
                <TableCell>Items</TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row) => (
                <TableRow key={row.product?.product_name}>
                  <TableCell
                    sx={{ backgroundColor: "#fff !important", color: "#000" }}
                    component="th"
                    scope="row"
                  >
                    {row.product?.product_code}
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "#fff !important", color: "#000" }}
                  >
                    {row.product?.product_name}
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "#fff !important", color: "#000" }}
                    align="right"
                  >
                    {row.quantity}
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "#fff !important", color: "#000" }}
                    align="right"
                  >
                    {Number(row.price_per_piece).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "#fff !important", color: "#000" }}
                    align="right"
                  >
                    {Number(row.total_amount).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  sx={{ backgroundColor: "#fff !important", color: "#000" }}
                />
                <TableCell
                  sx={{ backgroundColor: "#fff !important", color: "#000" }}
                ></TableCell>
                <TableCell
                  sx={{ backgroundColor: "#fff !important", color: "#000" }}
                  align="right"
                >
                  Total Qty: {transaction.items_quantity}
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#fff !important", color: "#000" }}
                />
                <TableCell
                  sx={{ backgroundColor: "#fff !important", color: "#000" }}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container spacing={2} mb={4} p={2}>
          <Grid item xs={12} sm={6}>
            {/* <Typography
              sx={{ color: '#000 !important' }}
              fontWeight={600}
              gutterBottom
            >
              Payment Info:
            </Typography> */}
            {/* <Typography sx={{ color: '#000 !important' }} gutterBottom>
              Type :{' '}
              {transaction.payments &&
                transaction.payments[0]?.payment_type_name}
            </Typography> */}
            {/* <Typography sx={{ color: '#000 !important' }} gutterBottom>
              Status : {transaction.payment_status_name}
            </Typography> */}
            {/* <Typography sx={{ color: '#000 !important' }} gutterBottom>
              Terms : {transaction.payment_term?.name}
            </Typography> */}
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={12}
            sm={6}
            textAlign={"end"}
          >
            <Grid container>
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#000 !important" }}
                    fontWeight={600}
                    gutterBottom
                  >
                    Sub Total:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#000 !important" }}
                    fontWeight={600}
                    gutterBottom
                  >
                    {Number(transaction.subtotal_amount).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#000 !important" }} gutterBottom>
                    Add Discount:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#000 !important" }}
                    fontWeight={600}
                    gutterBottom
                  >
                    {Number(transaction.discount_amount).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Grid>
                {/* <Grid item xs={6}>
                  <Typography sx={{ color: '#000 !important' }} gutterBottom>
                    Tax:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: '#000 !important' }}
                    fontWeight={600}
                    gutterBottom
                  >
                    {transaction.tax_amount}
                  </Typography>
                </Grid> */}
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#000 !important" }}
                    fontWeight={600}
                    gutterBottom
                  >
                    Total:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "#000 !important" }}
                    fontWeight={600}
                    gutterBottom
                  >
                    {Number(transaction.total_amount).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Grid>
              </React.Fragment>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
