import apiRequest from "./ApiService";

const apiEndPoint = "dashboard";

export const getDailySales = async (store_id, param = '') => {
  try {
    return await apiRequest.get(`/${apiEndPoint}?filter[store_id]=${store_id}` + param);
  } catch (error) {
    return error;
  }
};


