import React, { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useForm, Controller, useFormState, set } from "react-hook-form";
import * as DocumentPicker from "expo-document-picker";

// global css
import GlobalStyles from "../../../styles/GlobalStyles";

// services
import {
  addProduct,
  getSuggestedProducts,
} from "../../../services/ProductService";
import { uploadPhoto } from "../../../services/UploadService";

// libs
import {
  Box,
  Grid,
  TextField,
  Stack,
  CardMedia,
  Snackbar,
  Autocomplete,
  CircularProgress,
  Switch,
  FormControlLabel,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

// common
import { Alert } from "../../../components/common";

// images
import empty_image from "./../../../../assets/images/empty_image.svg";

import {
  getCategory,
  getBrand,
  getSupplier,
  getUom,
} from "../../../services/DateSetService";

const AddProducts = ({
  setOpenDrawer,
  getProductList,
  setOpenSnack,
  setSnackVariant,
  setErrMsg,
  action,
}) => {
  const [switchState, setSwitchState] = useState(false);
  const [formValues, setFormValues] = useState({
    product_code: "",
    product_sku: "",
    product_name: "",
    supplier_id: "",
    category_id: "",
    brand_id: "",
    uom: "",
    uom_units: "",
    low_stock_level: "",
    price: "",
    product_description: "",
    cost: "",
    quantity: "",
  });

  const [suggestedProd, setSuggestedProd] = useState({
    product_code: "",
    product_sku: "",
    product_name: "",
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError,
  } = useForm({
    defaultValues: formValues,
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const [openDialog, setOpenDialog] = useState(false);

  const handleCancel = () => {
    if (isDirty) {
      setOpenDialog(true);
    } else {
      setOpenDrawer(false);
    }
  };

  const handleCloseDialog = (e) => {
    if (e.target.innerText == "Yes") {
      setOpenDrawer(false);
      reset();
      setSuggestedProd({
        product_code: "",
        product_sku: "",
        product_name: "",
      });
    }
    setOpenDialog(false);
  };

  useEffect(() => {
    reset();
    setSuggestedProd({
      product_code: "",
      product_sku: "",
      product_name: "",
    });
  }, [action]);

  const handleSwitch = (event) => {
    setSwitchState(event.target.checked);
  };

  // submit form user login
  async function onSubmit(data) {
    setOpenLoader(true);
    let formData = {};
    formData = data;
    formData.category_id = data.category_id.id;
    formData.brand_id = data.brand_id.id;
    formData.supplier_id = data?.supplier_id?.id;
    formData.uom_units = data.uom_units.id;
    formData.store_id = await AsyncStorage.getItem("store_id");
    formData.created_by = await AsyncStorage.getItem("user_id");
    formData.status = productStatus ? 1 : 0;
    formData.image = formProductImage;

    // suggested product
    formData.product_name =
      suggestedProd?.product_name || formData.product_name;
    formData.product_code =
      suggestedProd?.product_code || formData.product_code;
    formData.product_sku = suggestedProd?.product_sku || formData.product_sku;

    if (formData) {
      try {
        const data = await addProduct(formData);
        if (data.data.success == 1) {
          setSnackVariant("success");
          setErrMsg("Product added successfully.");
          getProductList();
          setOpenDrawer(false);
          setOpenSnack(true);
        } else {
          const msg = data.data.errors;
          const updatedErrors = { ...errors };
          for (const key in msg) {
            if (msg.hasOwnProperty(key)) {
              const value = msg[key].join(" ");
              let field = key;
              updatedErrors[key] = { type: "manual", message: value };
              setError(field, {
                type: "server",
                message: value,
              });
            }
          }
        }
        setOpenLoader(false);
      } catch (error) {
        console.log(error);
      } finally {
        // setOpenSnack(true);
      }
    }
  }

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [uomList, setUom] = useState([]);

  useEffect(() => {
    getCategory().then((rs) => {
      const data = rs.data.data.map((data) => data);
      setCategories(data);
    });
  }, []);

  useEffect(() => {
    getBrand().then((rs) => {
      const data = rs.data.data.map((data) => data);
      setBrands(data);
    });
  }, []);

  useEffect(() => {
    getSupplier().then((rs) => {
      const data = rs.data.data.map((data) => data);
      setSuppliers(data);
    });
  }, []);

  useEffect(() => {
    getUom().then((rs) => {
      const data = rs.data.data.map((data) => data);
      setUom(data);
    });
  }, []);

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-() ]+$/;
  const ALPHA_NUMERIC_DASH_REGEX_NO_PAR = /^[a-zA-Z0-9- ]+$/;

  const status = [{ status: "1" }, { status: "2" }, { status: "3" }];
  const products = [
    { product_name: "1" },
    { product_name: "2" },
    { product_name: "3" },
  ];

  const [productStatus, setProductStatus] = React.useState(1);
  const handleChangeProductStatus = (event) => {
    setProductStatus(event.target.checked);
  };

  const [productImage, setProductImage] = useState(null);
  const [formProductImage, setFormProductImage] = useState(null);

  const [openSnackAlert, setOpenSnackAlert] = useState(false);
  const [snackVariantAlert, setSnackVariantAlert] = useState("success");
  const [errMsgAlert, setErrMsgAlert] = useState("");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackAlert(false);
  };

  const pickImage = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/jpg, image/jpeg, image/png, image/svg, image/gif",
      multiple: false,
      copyToCacheDirectory: false,
    });
    if (result.canceled) {
      return;
    }
    if (
      result.mimeType == "image/jpeg" ||
      result.mimeType == "image/png" ||
      result.mimeType == "image/svg" ||
      result.mimeType == "image/gif"
    ) {
      setProductImage(result.uri);
      let formData = new FormData();
      formData.append("file", result.file);
      const link = await uploadPhoto(formData);
      if (link) {
        setFormProductImage(link.data.link);
      }
    } else {
      setOpenSnackAlert(true);
      setSnackVariantAlert("error");
      setErrMsgAlert("Please select valid image.");
    }
  };

  // autocomplete as search by suggested products : start
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const isLoadingSuggestedProd = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!isLoadingSuggestedProd) {
      return undefined;
    }

    (async () => {
      const { data } = await getSuggestedProducts("?page=all");
      if (active) {
        setOptions(data?.length ? [...data] : []);
      }
    })();

    return () => {
      active = false;
    };
  }, [isLoadingSuggestedProd]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const selectedSuggestedProd = (data) => {
    if (!data) {
      return [];
    }

    setSuggestedProd({
      product_name: data?.product_name || "",
      product_code: data?.product_code || "",
      product_sku: data?.product_sku || "",
    });
  };
  // autocomplete as search by suggested products : end

  // loader
  const [openLoader, setOpenLoader] = useState(false);

  return (
    <Box
      sx={{ flexGrow: 1, width: "500px" }}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* suggested products start */}
      <Grid container spacing={2} sx={{ padding: 5 }}>
        <Grid item xs={12} xl={12} marginBottom={2} style={{ display: "none" }}>
          <Autocomplete
            id="async-suggested-products"
            sx={{ width: "100%" }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) =>
              option.product_name === value.product_name
            }
            getOptionLabel={(option) => option.product_name}
            options={options}
            loading={isLoadingSuggestedProd}
            onChange={(event, value) => selectedSuggestedProd(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={() => setValue("category_id", { shouldDirty: true })}
                label="Search by suggested product"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoadingSuggestedProd ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        {/* suggested products end */}

        <Grid item xs={12} sx={{ padding: 4 }}>
          <Grid container spacing={3} justifyContent="center" mb={2}>
            <Grid item xs={4}>
              <CardMedia
                name="customer_image"
                style={GlobalStyles.productImage}
                component="img"
                src={productImage ? productImage : empty_image}
                alt=""
                onClick={pickImage}
              />
            </Grid>
          </Grid>

          <Controller
            name="category_id"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Autocomplete
                  options={categories}
                  getOptionLabel={(option) => option.category_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product Category"
                      margin="normal"
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.category_id}
                      helperText={
                        errors.category_id
                          ? "Product Category is required."
                          : "(ex. Beauty Soap, Cosmetics)"
                      }
                    />
                  )}
                  onChange={(_, data) => field.onChange(data)}
                />
              );
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="product_name"
                control={control}
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: "Must be 4 digits.",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Product name"
                    variant="filled"
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ autoComplete: "none" }}
                    error={!!errors.product_name}
                    helperText={
                      errors.product_name?.message
                        ? errors.product_name?.message
                        : errors.product_name
                        ? "Product Name is required."
                        : "(ex. Hiyas Soap, HauzPro)"
                    }
                    value={suggestedProd?.product_name}
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setSuggestedProd({
                        ...suggestedProd,
                        product_name: value,
                      });
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="brand_id"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      options={brands}
                      getOptionLabel={(option) => option.brand_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand"
                          margin="normal"
                          required
                          fullWidth
                          variant="filled"
                          error={!!errors.brand_id}
                          helperText={errors.brand_id && "Brand is required."}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="product_code"
                control={control}
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: "Must be 4 digits.",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Product Code"
                    variant="filled"
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_DASH_REGEX_NO_PAR.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ autoComplete: "none" }}
                    value={suggestedProd?.product_code}
                    error={!!errors.product_code}
                    helperText={
                      errors.product_code?.message
                        ? errors.product_code?.message
                        : errors.product_code
                        ? "Product Code is required."
                        : ""
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setSuggestedProd({
                        ...suggestedProd,
                        product_code: value,
                      });
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="product_sku"
                control={control}
                rules={{
                  minLength: {
                    value: 4,
                    message: "Must be 4 digits.",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="SKU"
                    variant="filled"
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_DASH_REGEX_NO_PAR.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ autoComplete: "none" }}
                    error={!!errors.product_sku}
                    helperText={
                      errors.product_sku
                        ? errors.product_sku?.message
                        : "(optional)"
                    }
                    value={suggestedProd?.product_sku}
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setSuggestedProd({
                        ...suggestedProd,
                        product_sku: value,
                      });
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Controller
                name="uom"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="No. of Units"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    helperText="(optional)"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="uom_units"
                control={control}
                rules={{ required: false }}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      options={uomList}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Unit of Measurement"
                          placeholder="Kilogram (Kg)"
                          margin="normal"
                          fullWidth
                          variant="filled"
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="quantity"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Stock on hand"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    error={!!errors.quantity}
                    helperText={
                      errors.quantity ? "Stock on hand is required." : "(Qty)"
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="low_stock_level"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Low stock level"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    error={!!errors.low_stock_level}
                    helperText={
                      errors.low_stock_level
                        ? "Low stock level is required."
                        : "(Critical level)"
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="price"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Retail Price"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    error={!!errors.price}
                    helperText={
                      errors.price ? "Retail Price is required." : "(SRP)"
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="cost"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Cost"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    error={!!errors.cost}
                    helperText={
                      errors.cost ? "Cost is required." : "(Price at purchase)"
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Controller
            name="product_description"
            control={control}
            render={({ field }) => (
              <TextField
                label="Description"
                multiline
                fullWidth
                rows={4}
                variant="filled"
                {...field}
              />
            )}
          />

          <Controller
            name="supplier_id"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Autocomplete
                  options={suppliers}
                  getOptionLabel={(option) => option.supplier_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Supplier"
                      margin="normal"
                      fullWidth
                      variant="filled"
                      error={!!errors.supplier_id}
                      helperText={errors.supplier_id && "Supplier is required."}
                    />
                  )}
                  onChange={(_, data) => field.onChange(data)}
                />
              );
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Switch
                    label="Status"
                    defaultChecked
                    onChange={handleChangeProductStatus}
                    color="success"
                  />
                }
                label={productStatus ? "Active" : "Inactive"}
                labelPlacement="start"
              />
            </Grid>
          </Grid>

          {switchState && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="no_of_items_in_package"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="No of items in package"
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        helperText="(Items per box)"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="size"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Size"
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        helperText=""
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="stock_on_hand_2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Stock on hand"
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        helperText="(Qty)"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="low_stock_level_2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Low stock level"
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        helperText="Critical level"
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          <Stack
            spacing={2}
            direction="row"
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={5}
          >
            <Button onClick={() => handleCancel()} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="success">
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackAlert}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariantAlert}
          sx={{ width: "100%" }}
        >
          {errMsgAlert}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={openDialog}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"You have unsaved changes on this page."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to leave this page and discard your changes or stay on
            this page? If you leave this page, your changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            No
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleCloseDialog}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddProducts;
