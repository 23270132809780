import { useEffect, useState } from "react";

// libs
import {
  Box,
  Grid,
  Typography,
  Snackbar,
  Backdrop,
  CircularProgress,
  Button,
  Alert,
  Stack,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

// services
import {
  getCollectionReports,
  exportReport,
} from "../../../../services/report/CollectionReportService";
import DataRecords from "../components/DataRecords";

// sub component
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";

const CollectionReport = () => {
  const [loading, setLoading] = useState(false);
  const [dataReport, setDataReport] = useState([]);
  const [transaction, setTransaction] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [date, setDate] = useState({
    start: dayjs().date(1),
    end: dayjs(new Date()),
  });
  const [rsDataPagination, setRsDataPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const schema = {
    payment_date: "",
    transaction_id: "",
    transaction_type: "",
    amount: "",
    starting_balance: "",
    ending_balance: "",
    remarks: "",
  };

  const fetchDataReport = async (page, pageSize) => {
    setPage(page);
    const { data } = await getCollectionReports(
      `?&sort=-id&page=${page}&size=${pageSize}&date_start=${dayjs(
        date.start
      ).format("YYYY-MM-DD")}&date_end=${dayjs(date.end).format("YYYY-MM-DD")}`
    );
    if (data && data?.success) {
      let rs = [];

      setTransaction(data?.data);

      data?.data?.transaction?.map((d) => {
        rs.push({
          ...schema,
          id: d?.id,
          payment_date: d?.transaction?.updated_at || "",
          transaction_id: d?.transaction?.transaction_number || "N/A",
          transaction_type: d?.transaction?.transaction_type_name || "N/A",
          amount: `₱ ${Number(d?.transaction?.total_amount || 0).toLocaleString(
            "en",
            {
              minimumFractionDigits: 2,
            }
          )}`,
          starting_balance: `₱ ${Number(
            d?.starting_balance || 0
          ).toLocaleString("en", {
            minimumFractionDigits: 2,
          })}`,
          ending_balance: `₱ ${Number(d?.ending_balance || 0).toLocaleString(
            "en",
            {
              minimumFractionDigits: 2,
            }
          )}`,
          remarks: d?.remarks || "",
        });
      });
      setDataReport(rs);
      setRsDataPagination(data?.data?.pagination);
    }
  };

  const handleClickExport = async () => {
    setLoading(true);
    try {
      const start = date.start.format("YYYY-MM-DD");
      const end = date.end.format("YYYY-MM-DD");

      const { data } = await exportReport(start, end);

      if (data?.success) {
        setOpenSnack(true);
        setSnackVariant("success");
        setErrMsg(
          data?.message ?? "Successfully sent file to your email address"
        );
      } else {
        setOpenSnack(true);
        setSnackVariant("error");
        setErrMsg("Something went wrong, please try again");
      }
      setLoading(false);
    } catch {
      setLoading(false);
      setOpenSnack(true);
      setSnackVariant("error");
      setErrMsg("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    fetchDataReport(page, pageSize);
  }, [date, pageSize]);

  const tableColumns = [
    {
      field: "payment_date",
      headerName: "Payment Date",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      field: "transaction_id",
      headerName: "Transaction ID",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (param) => {
        const handleLabel = (label) => {
          switch (label) {
            case "POS":
              return "Order";

            default:
              return label;
          }
        };

        return handleLabel(param.value);
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "starting_balance",
      headerName: "Starting Balance",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "ending_balance",
      headerName: "Ending Balance",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date Start"
                value={date?.start}
                disableFuture={true}
                onChange={(newValue) =>
                  setDate({
                    ...date,
                    start: newValue,
                  })
                }
              />
              <DatePicker
                label="Date End"
                value={date?.end}
                disableFuture={true}
                onChange={(newValue) =>
                  setDate({
                    ...date,
                    end: newValue,
                  })
                }
              />
            </LocalizationProvider>
          </Stack>
          <Box alignContent="center" textAlign="center" p={2}>
            <LoadingButton
              disabled={loading || dataReport.length <= 0}
              variant="contained"
              onClick={() => handleClickExport()}
              loading={loading}
            >
              <DownloadIcon />
              Export
            </LoadingButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <DataRecords
            tableColumns={tableColumns}
            tableRecords={dataReport}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => fetchDataReport(newPage + 1, pageSize)}
            page={page}
            rowCount={rsDataPagination?.total || 0}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom fontWeight={500} display="block">
            Total Amount: ₱
            {Number(transaction?.summary?.total_amount || 0).toLocaleString(
              "en",
              {
                minimumFractionDigits: 2,
              }
            )}
          </Typography>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CollectionReport;
