import { View, Text } from "react-native";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

// libs
import {
  Box,
  TextField,
  Stack,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";

// global css
import GlobalStyles from "../../../styles/GlobalStyles";

// services
import { updateUserById } from "../../../services/ProfileService";

const UpdateProfile = ({
  productData,
  setOpenDrawer,
  setOpenSnack,
  setSnackVariant,
  setErrMsg,
  userData,
  uuid,
  getUserDetails,
  setOpenLoader,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      first_name: userData?.first_name || "",
      middle_name: userData?.middle_name || "",
      last_name: userData?.last_name || "",
      email_address: userData?.email_address || "",
      mobile_number: userData?.mobile_number || "",
    },
  });

  useEffect(() => {
    reset({ ...userData });
  }, [userData]);

  // submit form add stock
  async function onSubmit(data) {
    setOpenLoader(true);
    let formData = {};
    formData = data;
    // formData.product_id = productData.id;
    // formData.created_by = 1;

    if (formData) {
      try {
        const data = await updateUserById(uuid, formData);
        if (data.data.success == 1) {
          setSnackVariant("success");
          setErrMsg(data.data.message);
          getUserDetails();
          setOpenDrawer(false);
          setOpenSnack(true);
        } else {
          const msg = data.data.errors;
          const updatedErrors = { ...errors };
          for (const key in msg) {
            if (msg.hasOwnProperty(key)) {
              const value = msg[key].join(" ");
              let field = key;
              updatedErrors[key] = { type: "manual", message: value };
              setError(field, {
                type: "server",
                message: value,
              });
            }
          }
        }
        setOpenLoader(false);
      } catch (error) {
        console.log(error);
      } finally {
        // setOpenSnack(true);
      }
    }
  }

  return (
    <Box
      sx={{ flexGrow: 1, width: "408px" }}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box sx={{ padding: 5 }}>
        <Controller
          name="first_name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              required
              label="First Name"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              type="text"
              error={!!errors.first_name}
              helperText={errors.first_name && "First Name is required."}
              {...field}
            />
          )}
        />

        <Controller
          name="middle_name"
          control={control}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              label="Middle Name"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              type="text"
              error={!!errors.middle_name}
              helperText={errors.middle_name && "Middle Name is required."}
              {...field}
            />
          )}
        />

        <Controller
          name="last_name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              required
              label="Last Name"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              type="text"
              error={!!errors.last_name}
              helperText={errors.last_name && "Last Name is required."}
              {...field}
            />
          )}
        />

        <Controller
          name="suffix_name"
          control={control}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              label="Suffx Name"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              type="text"
              error={!!errors.suffix_name}
              helperText="(optional)"
              {...field}
            />
          )}
        />

        <Controller
          name="email_address"
          control={control}
          rules={{
            required: "Email Address is required",
            pattern: {
              value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Enter a valid email",
            },
          }}
          render={({ field }) => (
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email address"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              error={!!errors.email_address}
              helperText={
                errors.email_address?.message
                  ? errors.email_address?.message
                  : errors.email_address
                  ? "Email address is required."
                  : ""
              }
              {...field}
            />
          )}
        />

        <Controller
          name="mobile_number"
          control={control}
          rules={{
            required: "Mobile Number is required",
            pattern: {
              value: /^(0|[1-9]\d*)(\.\d+)?$/,
            },
            minLength: {
              value: 10,
              message: "Must be 10 digits",
            },
            maxLength: {
              value: 10,
              message: "Should not exceed to 10 digits",
            },
          }}
          render={({ field }) => (
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+63</InputAdornment>
                ),
              }}
              margin="normal"
              required
              fullWidth
              id="mobile_number"
              label="Mobile No."
              placeholder="(eg. 9XXXXXXXXX)"
              variant="filled"
              type="number"
              inputProps={{
                autoComplete: "none",
                maxLength: 10,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onKeyPress={(event) => {
                if (event?.key === "-" || event?.key === "+") {
                  event.preventDefault();
                }
              }}
              error={!!errors.mobile_number}
              helperText={
                errors.mobile_number?.message
                  ? errors.mobile_number?.message
                  : errors.mobile_number
                  ? "Mobile number is required."
                  : ""
              }
              {...field}
            />
          )}
        />
        <Stack
          spacing={1}
          direction="row"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          mt={5}
        >
          <Button onClick={() => setOpenDrawer(false)} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="success">
            Save
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default UpdateProfile;
