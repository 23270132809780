import { View, Text } from "react-native";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { uploadPhoto } from "../../../services/UploadService";
import * as DocumentPicker from "expo-document-picker";

// libs
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Button,
  InputAdornment,
  CardMedia,
  Typography,
} from "@mui/material";
import empty_image from "../../../../assets/images/empty_image.svg";
// global css
import GlobalStyles from "../../../styles/GlobalStyles";

// services
import { updateStore } from "../../../services/ProfileService";
import { LoadingButton } from "@mui/lab";

const UpdateStore = ({
  setOpenDrawer,
  setOpenSnack,
  setSnackVariant,
  setErrMsg,
  userData,
  uuid,
  setOpenLoader,
}) => {
  const [formLogo, setFormLogo] = useState(userData?.store?.logo ?? null);
  const [logoLink, setLogoLink] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: userData?.store?.name || "",
      country_code: userData?.store?.country_code || "",
      address_text: userData?.store?.address_text || "",
      company_email: userData?.store?.company_email || "",
      company_phone: userData?.store?.company_phone || "",
      logo: userData?.store?.logo || "",
    },
  });

  const [country, setCountry] = useState("PH");

  useEffect(() => {
    reset({
      name: userData?.store?.name || "",
      country_code: userData?.store?.country_code || "",
      address_text: userData?.store?.address_text || "",
      company_email: userData?.store?.company_email || "",
      company_phone: userData?.store?.company_phone || "",
      logo: userData?.store?.logo || "",
    });
  }, []);

  // submit form add stock
  async function onSubmit(data) {
    setOpenLoader(true);
    let formData = {};
    formData = { ...data, logo: logoLink ? logoLink : "" };
    // formData.product_id = productData.id;
    // formData.created_by = 1;

    if (formData) {
      try {
        const data = await updateStore(userData?.store?.uuid, formData);
        if (data.data.success == 1) {
          localStorage.setItem("store_name", formData.name);
          setSnackVariant("success");
          setErrMsg(data.data.message);
          userData.store = { ...userData.store, ...data.data.data };
          setOpenDrawer(false);
          setOpenSnack(true);
          setTimeout(location.reload(), 1000);
        } else {
          const msg = data.data.message;
          const updatedErrors = { ...errors };
          for (const key in msg) {
            if (msg.hasOwnProperty(key)) {
              const value = msg[key].join(" ");
              let field = key;
              updatedErrors[key] = { type: "manual", message: value };
              setError(field, {
                type: "server",
                message: value,
              });
            }
          }
        }
        setOpenLoader(false);
      } catch (error) {
        console.log(error);
      } finally {
        // setOpenSnack(true);
      }
    }
  }

  const pickImage = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/jpg, image/jpeg, image/png, image/svg, image/gif",
      multiple: false,
      copyToCacheDirectory: false,
    });
    setLoading(true);
    if (result.canceled) {
      setLoading(false);
      return;
    }
    if (
      result.mimeType == "image/jpeg" ||
      result.mimeType == "image/png" ||
      result.mimeType == "image/svg" ||
      result.mimeType == "image/gif"
    ) {
      setFormLogo(result.uri);
      let formData = new FormData();
      formData.append("file", result.file);
      const link = await uploadPhoto(formData);
      if (link) {
        setLogoLink(link.data.link);

        setLoading(false);
      }
      setLoading(false);
    } else {
      setOpenSnackAlert(true);
      setSnackVariantAlert("error");
      setErrMsgAlert("Please select valid image.");
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{ flexGrow: 1, width: "408px" }}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: 5 }}
      >
        <CardMedia
          name="logo"
          style={GlobalStyles.productImage}
          component="img"
          src={formLogo ? formLogo : empty_image}
          alt=""
          onClick={pickImage}
        />
        <Typography color="gray" fontSize={12} mt={1}>
          Recommended Size 120px X 120px
        </Typography>
        <br />

        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              label="Store Name"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              type="text"
              error={!!errors.name}
              helperText={errors.name && "Store Name is required."}
              {...field}
            />
          )}
        />

        <Controller
          name="country_code"
          control={control}
          render={({ field }) => (
            <FormControl
              variant="filled"
              style={GlobalStyles.fullField}
              helperText="Country"
              name="country_code"
              id="country_code"
              inputProps={{
                autoComplete: "none",
              }}
            >
              <InputLabel>Country</InputLabel>
              <Select defaultValue={country}>
                <MenuItem value="PH" setSelected>
                  PH
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <Controller
          name="address_text"
          control={control}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              label="Address"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              type="text"
              error={!!errors.address_text}
              helperText={errors.address_text && "Address is required."}
              {...field}
            />
          )}
        />

        <Controller
          name="company_email"
          control={control}
          rules={{
            required: "Email Address is required",
            pattern: {
              value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Enter a valid email",
            },
          }}
          render={({ field }) => (
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email address"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              error={!!errors.company_email}
              helperText={
                errors.company_email?.message
                  ? errors.company_email?.message
                  : errors.company_email
                  ? "Email address is required."
                  : ""
              }
              {...field}
            />
          )}
        />

        <Controller
          name="company_phone"
          control={control}
          rules={{
            pattern: {
              value: /^(0|[1-9]\d*)(\.\d+)?$/,
            },
            minLength: {
              value: 10,
              message: "Must be 10 digits",
            },
            maxLength: {
              value: 11,
              message: "Should not exceed to 10 digits",
            },
          }}
          render={({ field }) => (
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+63</InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              margin="normal"
              fullWidth
              value={userData.mobile_number || ""}
              label="Company Phone"
              placeholder="(eg. 9XXXXXXXXX)"
              variant="filled"
              type="number"
              inputProps={{ autoComplete: "none" }}
              onKeyPress={(event) => {
                if (event?.key === "-" || event?.key === "+") {
                  event.preventDefault();
                }
              }}
              error={!!errors.company_phone}
              helperText={
                errors?.company_phone &&
                (errors?.company_phone?.message || "Company Phone is required.")
              }
              {...field}
            />
          )}
        />

        <Stack
          spacing={1}
          direction="row"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          mt={5}
        >
          <Button onClick={() => setOpenDrawer(false)} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="success"
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default UpdateStore;
