import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import CustomContainer from '../components/layout/CustomContainer';
import { getToken } from '../services/AuthService';

const PrivateRoute = () => {
  const [accessToken, setAccessToken] = useState(true);

  getToken().then(function (rs) {
    setAccessToken(rs);
  });

  return (
    <>
      {accessToken ? (
        <CustomContainer>
          <Outlet />
        </CustomContainer>
      ) : (
        <Navigate to='/login' />
      )}
    </>
  );
};

export default PrivateRoute;
