import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { View } from "react-native";
import * as DocumentPicker from "expo-document-picker";
import AsyncStorage from "@react-native-async-storage/async-storage";
// css
import GlobalStyles from "../../../styles/GlobalStyles";

// services
import { addCustomer } from "../../../services/CustomerService";
import { uploadPhoto } from "../../../services/UploadService";
import { getLocations } from "../../../services/DateSetService";

// images
import empty_image from "./../../../../assets/images/empty_image.svg";

// libs
import {
  Box,
  Grid,
  CardMedia,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  Autocomplete,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
  Button,
} from "@mui/material";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const AddCustomer = ({
  setOpenDrawer,
  getCustomerList,
  setOpenSnack,
  setSnackVariant,
  setErrMsg,
}) => {
  const [selected, setSelected] = useState([{ key: 0, label: selected }]);
  const [tag, setTag] = useState([{ key: 0, label: tag }]);
  const [country, setCountry] = useState("PH"); // set PH as default country
  const [formValues, setFormValues] = useState({
    customer_image: "",
    first_name: "",
    last_name: "",
    email_address: "",
    mobile_number: "",
    tags: "",
    business_name: "",
    address_text: "",
    country_code: "PH",
    mobile_code: "+63",
    address_region: "",
    address_province: "",
    address_city: "",
    address_postal: "",
    business_email_address: "",
    business_contact_number: "",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    defaultValues: formValues,
  });

  const handleSwitch = (event) => {
    setSwitchState(event.target.checked);
  };

  const handleDelete = (chipToDelete) => () => {
    setTag((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  // submit form add customer
  async function onSubmit(data) {
    setOpenLoader(true);
    const formData = {
      ...data,
      address_region: addressRegion,
      address_province: addressProvince,
      address_city: addressCity,
      store_id: await AsyncStorage.getItem("store_id"),
      created_by: await AsyncStorage.getItem("user_id"),
      customer_image: formCustomerImage,
      country_code: "PH",
    };

    // let formData = {};
    // formData = data;
    // formData.address_region = addressRegion;
    // formData.address_province = addressProvince;
    // formData.address_city = addressCity ? addressCity : customerData.address_city  ;

    // formData.store_id = await AsyncStorage.getItem('store_id');
    // formData.created_by = await AsyncStorage.getItem('user_id');
    // formData.customer_image = formCustomerImage;

    if (formData) {
      try {
        const data = await addCustomer(formData);
        if (data.data.success == 1) {
          setSnackVariant && setSnackVariant("success");
          setErrMsg && setErrMsg("Customer was successfully added.");
          getCustomerList();
          setOpenDrawer(false);
          setOpenSnack(true);
          reset();
        } else {
          const msg = data.data.errors;
          const updatedErrors = { ...errors };
          for (const key in msg) {
            if (msg.hasOwnProperty(key)) {
              const value = msg[key].join(" ");
              let field = key;
              updatedErrors[key] = { type: "manual", message: value };
              setError(field, {
                type: "server",
                message: value,
              });
            }
          }
        }
        setOpenLoader(false);
        reset();
      } catch (error) {
        setSnackVariant && setSnackVariant("error");
        setErrMsg && setErrMsg("Something went wrong!");
        setOpenDrawer(false);
        setOpenSnack(true);
      } finally {
        reset();
      }
    }
  }

  const [region, setRegion] = useState([]);
  const [city, setCity] = useState([]);
  const [province, setProvince] = useState([]);
  const [addressCity, setAddressCity] = useState("");

  useEffect(() => {
    getLocations().then((rs) => {
      const data = JSON.parse(rs.data);
      setRegion(data);
    });
  }, []);

  const [addressRegion, setAddressRegion] = useState("");
  // get Province by Region
  const getProvinceByRegion = (value) => {
    const data = region.map((data) => {
      let val = data;

      if (val.id == value.id) {
        setProvince(val.provinces);
        setAddressRegion(value.id);
      }
    });
  };

  const [addressProvince, setAddressProvince] = useState("");
  // get city by Province
  const getCityByProvince = (value) => {
    const data = province.map((data) => {
      let val = data;

      if (val.id == value.id) {
        setCity(val.cities);
        setAddressProvince(value.id);
      }
    });
  };

  // uploading of photo
  const [customerImage, setCustomerImage] = useState(null);
  const [formCustomerImage, setFormCustomerImage] = useState(null);
  const [openDrawer, setsOpenDrawer] = useState(false);

  const [openSnackAlert, setOpenSnackAlert] = useState(false);
  const [snackVariantAlert, setSnackVariantAlert] = useState("success");
  const [errMsgAlert, setErrMsgAlert] = useState("");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackAlert(false);
  };

  const pickImage = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/jpg, image/jpeg, image/png, image/svg, image/gif",
      multiple: false,
      copyToCacheDirectory: false,
    });

    if (result.canceled) {
      return;
    }
    if (
      result.mimeType == "image/jpeg" ||
      result.mimeType == "image/png" ||
      result.mimeType == "image/svg" ||
      result.mimeType == "image/gif"
    ) {
      setCustomerImage(result.uri);
      let formData = new FormData();
      formData.append("file", result.file);
      const link = await uploadPhoto(formData);
      if (link) {
        setFormCustomerImage(link.data.link);
      }
    } else {
      setOpenSnackAlert(true);
      setSnackVariantAlert("error");
      setErrMsgAlert("Please select valid image.");
    }
  };

  // loader
  const [openLoader, setOpenLoader] = React.useState(false);
  // const handleShowLoader = () => {
  //   setOpenLoader(true);
  // };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ flexGrow: 1, width: "500px" }}
        noValidate
      >
        <Grid container spacing={2} style={GlobalStyles.drawerWrapper}>
          <Grid item md={12} sx={{ padding: "20px 15px" }}>
            <View>
              <Grid container style={GlobalStyles.productWrapper}>
                <Grid item xs={4}>
                  <CardMedia
                    name="customer_image"
                    style={GlobalStyles.productImage}
                    component="img"
                    src={customerImage ? customerImage : empty_image}
                    alt=""
                    onClick={pickImage}
                  />
                </Grid>
              </Grid>
            </View>
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{
                    required: "First name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      required
                      autoFocus
                      fullWidth
                      label="First Name"
                      variant="filled"
                      id="first_name"
                      inputProps={{
                        autoComplete: "none",
                      }}
                      error={!!errors.first_name}
                      helperText={
                        errors.first_name && errors.first_name.message
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{
                    required: "Last name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="last_name"
                      label="Last Name"
                      variant="filled"
                      inputProps={{
                        autoComplete: "none",
                      }}
                      error={!!errors.last_name}
                      helperText={errors.last_name && errors.last_name.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Controller
                    name="email_address"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Enter a valid email",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Email address"
                        variant="filled"
                        inputProps={{
                          autoComplete: "none",
                        }}
                        error={!!errors.email_address}
                        helperText={
                          errors.email_address
                            ? "Invalid Email"
                            : "Email Address"
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="mobile_number"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      },
                      minLength: {
                        value: 10,
                        message: "Must be 10 digits",
                      },
                      maxLength: {
                        value: 10,
                        message: "Should not exceed to 10 digits",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        InputProps={{
                          inputProps: { min: 0 },
                          startAdornment: (
                            <InputAdornment position="start">
                              +63
                            </InputAdornment>
                          ),
                        }}
                        margin="normal"
                        type="number"
                        inputmode="numeric"
                        fullWidth
                        id="mobile_number"
                        label="Mobile No."
                        placeholder="(eg. 9XXXXXXXXX)"
                        variant="filled"
                        inputProps={{
                          autoComplete: "none",
                        }}
                        error={!!errors.mobile_number}
                        sx={{
                          "& input[type=number]": {
                            "-moz-appearance": "textfield",
                          },
                          "& input[type=number]::-webkit-outer-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                          "& input[type=number]::-webkit-inner-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                        }}
                        helperText="( Mobile Number )"
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  name="business_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="business_name"
                      label="Business Name"
                      variant="filled"
                      inputProps={{
                        autoComplete: "none",
                      }}
                      helperText="Registered company name (optional)"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid container spacing={2}>
                <Grid id item md={6} sx={{ marginTop: "13px" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Controller
                        name="country_code"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            variant="filled"
                            style={GlobalStyles.fullField}
                            helperText="Country"
                            name="country_code"
                            id="country_code"
                            inputProps={{
                              autoComplete: "none",
                            }}
                          >
                            <InputLabel>Country</InputLabel>
                            <Select defaultValue={country}>
                              <MenuItem value="PH" setSelected>
                                PH
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="address_region"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={region}
                        getOptionLabel={(option) => option.region_name}
                        onChange={(event, values) =>
                          getProvinceByRegion(values)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            onChange={onChange}
                            margin="normal"
                            fullWidth
                            label="Region"
                            variant="filled"
                            autoComplete="off"
                            error={!!errors.address_region}
                            helperText="(Region)"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Controller
                    name="address_province"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={province}
                        getOptionLabel={(option) => option.province_name}
                        onChange={(event, values) => getCityByProvince(values)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            onChange={onChange}
                            margin="normal"
                            fullWidth
                            label="Province"
                            variant="filled"
                            autoComplete="off"
                            error={!!errors.address_province}
                            helperText="(Province)"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="address_city"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={city}
                        getOptionLabel={(option) => option.city_name}
                        onChange={(event, values) => setAddressCity(values.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            onChange={onChange}
                            margin="normal"
                            fullWidth
                            label="City"
                            variant="filled"
                            autoComplete="off"
                            error={!!errors.address_city}
                            helperText="(City)"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                <Grid item md={6}>
                  <Controller
                    name="address_text"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="address_text"
                        label="Address"
                        variant="filled"
                        helperText="(Address)"
                        inputProps={{
                          autoComplete: "none",
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={6}>
                  <Controller
                    name="address_postal"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        fullWidth
                        id="address_postal"
                        label="Postal Code"
                        variant="filled"
                        helperText="(Postal Code)"
                        inputProps={{
                          autoComplete: "none",
                        }}
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Controller
                    name="business_email_address"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Enter a valid email ",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Company Email"
                        placeholder="example@email.com"
                        variant="filled"
                        inputProps={{
                          autoComplete: "none",
                        }}
                        error={!!errors.business_email_address}
                        helperText={
                          errors.business_email_address
                            ? "Invalid Email"
                            : "Business Email Address"
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="business_contact_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        type="number"
                        fullWidth
                        id="mobile_number"
                        label="Company contact number"
                        variant="filled"
                        inputProps={{
                          autoComplete: "none",
                        }}
                        error={!!errors.business_contact_number}
                        helperText={"Business Mobile Number"}
                        sx={{
                          "& input[type=number]": {
                            "-moz-appearance": "textfield",
                          },
                          "& input[type=number]::-webkit-outer-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                          "& input[type=number]::-webkit-inner-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                        }}
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6}></Grid>
              <Grid
                item
                md={6}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button onClick={() => setOpenDrawer(false)} variant="outlined">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="success">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={openSnackAlert}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snackVariantAlert}
            sx={{ width: "100%" }}
          >
            {errMsgAlert}
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
};

export default AddCustomer;
