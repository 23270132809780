import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Modal,
  Autocomplete,
} from "@mui/material";

import { getCustomers } from "../../../../services/CustomerService";
import AddCustomer from "../../customers/AddCustomer";
import CustomDrawer from "../../../../components/CustomDrawer";
import { formatPrice } from "../../../../utils/Strings";

const mainModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFFFFF",
  bgcolor: "#050F1A",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function UtangModal(props) {
  const { open, handleClose, grandTotal, handleSubmit, setOpen } = props;
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [addCustomer, setAddCustomer] = useState(false);
  const [action, setAction] = useState("add");

  const getCustomerList = () => {
    getCustomers("?page=all&sort=first_name").then((resp) => {
      setCustomers(resp.data.data);
    });
  };

  useEffect(() => {
    const cust = JSON.parse(localStorage.getItem('customer'));
    if (cust) {
      setCustomer(cust);
    }
  }, []);

  // get customers from api and set to customers state
  useEffect(() => {
    getCustomerList();
  }, []);

  const searchCustomer = async (e) => {
    const param = e.target.value || "";
    const customerList = await getCustomers(
      "?sort=first_name&keyword=" + param
    );

    try {
      const data = customerList && customerList?.data?.data;
      if (data) {
        setCustomers(customerList?.data?.data);
      }
    } catch (error) {
      setCustomers([]);
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => {
          handleClose();
          getCustomerList();
          setCustomer(null);
        }}
      >
        <Box sx={mainModalStyle}>
          <Box>
            <Typography textAlign="center" variant="h6" component="h2">
              Utang Transaction
            </Typography>
            <hr />
            <br />
            <Box display="flex" justifyContent="space-between">
              <Typography>TOTAL AMOUNT DUE</Typography>
              <Typography> {formatPrice(grandTotal)}</Typography>
            </Box>
            <br />
            <Box sx={{ textAlign: "center" }}>
              <Autocomplete
                disablePortal
                defaultValue={customer}
                onChange={(e, value) => {
                  setCustomer(value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.defaultMuiPrevented = true;
                  } else {
                    searchCustomer(event);
                  }
                }}
                getOptionLabel={(option) =>
                  option.first_name + ' ' + option.last_name
                }
                options={customers}
                // options={customers.map((row) => {
                //   return {
                //     label: `${row.first_name} ${row.last_name}`,
                //     id: row.id,
                //   };
                // })}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Select Customer" />
                )}
              />
            </Box>
            <Box textAlign="center" display="flex" flexDirection="column">
              <Button
                variant="standard"
                color="info"
                onClick={() => {
                  setAddCustomer(true);
                  getCustomerList();
                  handleClose();
                }}
              >
                Add New Customer
              </Button>
              <br />

              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  handleSubmit && handleSubmit(customer?.id);
                  setCustomer(null);
                }}
                disabled={!customer}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {addCustomer && (
        <CustomDrawer
          setOpenDrawer={setAddCustomer}
          openDrawer={addCustomer}
          setAction={setAction}
          action={action}
          showAddButton={false}
        >
          {action === "add" && (
            <AddCustomer
              setOpenDrawer={setAddCustomer}
              getCustomerList={getCustomerList}
              setOpenSnack={setOpen}
            />
          )}
        </CustomDrawer>
      )}
    </>
  );
}
