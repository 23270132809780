import React, { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// services
import { getSuppliers } from '../../../services/StoreParameter/SupplierService';

// global css
import GlobalStyles from '../../../styles/GlobalStyles';

// libs
import {
  styles as styled,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';

import { ModeEdit as ModeEditIcon } from '@mui/icons-material';

const ListSupplier = ({ renderFlag, onActionChange }) => {
  const [supplierList, setSupplierList] = useState([]);

  const getSupplierList = () => {
    getSuppliers('?page=all').then((rs) => {
      const data = rs.data.data.map((data) => data);
      setSupplierList(data);

      onActionChange({});
    });
  };

  const editItem = (data) => {
    onActionChange(data);
  };

  useEffect(() => {
    if (!renderFlag) {
      getSupplierList();
    }
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ ml: 4, mt: 4, mb: 2 }} variant='h6' component='div'>
            Suppliers List
          </Typography>

          {supplierList && supplierList.length > 0 ? (
            <List style={GlobalStyles.ListCategory}>
              {supplierList.map((data, index) => (
                <ListItem
                  key={data?.uuid}
                  secondaryAction={
                    <IconButton
                      edge='end'
                      aria-label='edit'
                      onClick={() => editItem(data)}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={data.supplier_name} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography sx={{ textAlignLast: 'center' }}>
              - No record found -
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ListSupplier;
