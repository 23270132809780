import React, { useState, useEffect } from "react";
import { View } from "react-native";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// Images
import peso from "./../../../assets/icons/peso.svg";

// Icons
import FolderIcon from "@mui/icons-material/Folder";

// Styles
import GlobalStyles from "../../styles/GlobalStyles";

// Service
import { getDailySales } from "../../services/DashboardService";

// Charts
import { LineChart, BarChart } from "react-native-chart-kit";

export default function Dashboard() {
  const store_id = localStorage.getItem("store_id");
  const [width, setWidth] = useState(0);
  const [data, setData] = useState([]);
  const [dailySales, setDailySales] = useState([]);
  const [weeklyEarnings, setWeeklyEarnings] = useState([]);

  const onLayout = (event) => {
    const { x, y, height, width } = event.nativeEvent.layout;
    setWidth(width);
  };

  useEffect(() => {
    getDashboardData(store_id);
  }, []);

  const getDashboardData = (store_id) => {
    setOpenLoader(true);
    getDailySales(store_id).then((rs) => {
      setData(rs.data.data);
      setDailySales(rs.data.data.weekly_sales.map((item) => item.sales));
      setWeeklyEarnings(
        rs.data.data.weekly_earnings.map((item) => item.earnings)
      );
      setOpenLoader(false);
    });
  };

  const [openLoader, setOpenLoader] = useState(true);
  if (openLoader == true) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box sx={{ minWidth: 275 }}>
      <Typography variant="h7" component="div" mb={2}>
        Here's what's going on with your store today.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Card variant="outlined" sx={{ padding: "10px" }}>
            <CardContent>
              <View onLayout={onLayout}>
                <Grid container mb={2}>
                  <Grid item>
                    <Typography variant="h5" fontWeight="600" component="div">
                      Today's Sales
                    </Typography>
                    <Typography variant="body2" mb={2}>
                      {new Date().toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs sx={{ textAlign: "end" }}>
                    <Typography variant="h5" fontWeight="400" component="div">
                      ₱ {(Number(data.daily_sales) || 0)?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>

                <BarChart
                  data={{
                    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    datasets: [
                      {
                        data: dailySales,
                      },
                    ],
                  }}
                  width={width}
                  height={250}
                  withHorizontalLabels={true}
                  withVerticalLabels={true}
                  withInnerLines={false}
                  fromZero={true}
                  // yAxisLabel={'Rs'}
                  chartConfig={{
                    backgroundGradientFrom: 0,
                    backgroundGradientFromOpacity: 0,
                    backgroundGradientTo: 0,
                    backgroundGradientToOpacity: 0,
                    fillShadowGradient: "#66B459",
                    fillShadowGradientOpacity: 1,
                    color: () => `#66B459`,
                    barPercentage: 1.9,
                  }}
                  style={{
                    borderBottomColor: "white",
                    borderBottomWidth: 1,
                    paddingRight: 0,
                    marginVertical: 2,
                  }}
                />
              </View>
            </CardContent>
            <CardActions>
              <Link
                size="small"
                sx={{ color: GlobalStyles.primaryText }}
                underline="always"
                href="/sales_report"
              >
                View Report
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Card variant="outlined" sx={{ padding: "10px", height: "430px" }}>
            <CardContent>
              <View>
                <Grid container mb={2}>
                  <Grid item>
                    <Typography variant="h5" fontWeight="600" component="div">
                      Earnings
                    </Typography>
                    <Typography variant="body2" mb={2}>
                      {new Date().toLocaleDateString()} -{" "}
                      {new Date().toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs sx={{ textAlign: "end" }}>
                    <Typography variant="h5" fontWeight="400" component="div">
                      ₱ {(Number(data.daily_earnings) || 0)?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>

                <LineChart
                  data={{
                    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    datasets: [
                      {
                        // data: [
                        //   Math.random() * 100,
                        //   Math.random() * 100,
                        //   Math.random() * 100,
                        //   Math.random() * 100,
                        //   Math.random() * 100,
                        //   Math.random() * 100,
                        // ],
                        data: weeklyEarnings,
                      },
                    ],
                  }}
                  width={width}
                  height={250}
                  withInnerLines={false}
                  fromZero={true}
                  // yAxisLabel={'Rs'}
                  bezier
                  chartConfig={{
                    decimalPlaces: 0,
                    backgroundGradientFrom: 0,
                    backgroundGradientFromOpacity: 0,
                    backgroundGradientTo: 0,
                    backgroundGradientToOpacity: 0,
                    fillShadowGradient: "#66B459",
                    fillShadowGradientOpacity: 1,
                    color: () => `#66B459`,
                  }}
                  style={{
                    borderBottomColor: "white",
                    borderBottomWidth: 1,
                  }}
                />
              </View>
            </CardContent>
            {/* <CardActions>
              <Link
                size='small'
                sx={{ color: GlobalStyles.primaryText }}
                underline='always'
              >
                View Report
              </Link>
            </CardActions> */}
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={2}>
        {/* <Grid item xs={12} sm={12} md={4}>
          <Box sx={{ padding: '25px' }}>
            <Typography variant='h5' fontWeight='600' component='div'>
              Top Products
            </Typography>
            <Typography variant='body2' fontWeight='600' mb={1}>
              Today
            </Typography>

            <List>
              <ListItem sx={{ padding: '0' }}>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Link sx={{ color: GlobalStyles.primaryText }}>
                      {' '}
                      SanSan Make-up{' '}
                    </Link>
                  }
                  secondary={
                    <Typography variant='body2'> 20 ordered </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Link sx={{ color: GlobalStyles.primaryText }}>
                      {' '}
                      ActiveJoint{' '}
                    </Link>
                  }
                  secondary={
                    <Typography variant='body2'> 18 ordered </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Link sx={{ color: GlobalStyles.primaryText }}>
                      {' '}
                      Body Recipe{' '}
                    </Link>
                  }
                  secondary={
                    <Typography variant='body2'> 12 ordered </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={12} md={4}>
          <Card variant="outlined" sx={{ padding: "10px" }}>
            <Box sx={{ padding: "25px" }}>
              <Typography variant="h5" fontWeight="600" component="div">
                Quick Links
              </Typography>
              <List>
                <ListItem sx={{ padding: "0", pb: "10px" }}>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon sx={{ color: "#0a315a" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <Link
                    size="medium"
                    sx={{
                      color: GlobalStyles.primaryText,
                      color: "#fff",
                      textDecoration: "underline",
                      fontSize: "18px",
                    }}
                    href="/customers"
                  >
                    Customers
                  </Link>
                </ListItem>
                <ListItem sx={{ padding: "0", pb: "10px" }}>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon sx={{ color: "#0a315a" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <Link
                    size="medium"
                    sx={{
                      color: GlobalStyles.primaryText,
                      color: "#fff",
                      textDecoration: "underline",
                      fontSize: "18px",
                    }}
                    href="/products"
                  >
                    Products
                  </Link>
                </ListItem>
                <ListItem sx={{ padding: "0", pb: "10px" }}>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon sx={{ color: "#0a315a" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <Link
                    size="medium"
                    sx={{
                      color: GlobalStyles.primaryText,
                      color: "#fff",
                      textDecoration: "underline",
                      fontSize: "18px",
                    }}
                    href="/inventory"
                  >
                    Inventory
                  </Link>
                </ListItem>
                <ListItem sx={{ padding: "0", pb: "10px" }}>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon sx={{ color: "#0a315a" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <Link
                    size="medium"
                    sx={{
                      color: GlobalStyles.primaryText,
                      color: "#fff",
                      textDecoration: "underline",
                      fontSize: "18px",
                    }}
                    href="/supplier"
                  >
                    Suppliers
                  </Link>
                </ListItem>
              </List>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card variant="outlined" sx={{ padding: "10px" }}>
            <CardContent>
              <Typography variant="h5" fontWeight="600" component="div">
                Stocks Total Value
              </Typography>
              <Typography variant="body2" fontWeight="600" mb={2}>
                Inventory
              </Typography>
              <Typography variant="body2" mb={2}>
                {new Date().toLocaleDateString()}
              </Typography>
              <Typography variant="h4" fontWeight="400" component="div">
                {/* <img src={peso} /> */}₱ {data.total_stock_value}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card variant="outlined" sx={{ padding: "10px" }}>
            <CardContent>
              <Typography variant="h5" fontWeight="600" component="div">
                Stocks Cost
              </Typography>
              <Typography variant="body2" fontWeight="600" mb={2}>
                Inventory
              </Typography>
              <Typography variant="body2" mb={2}>
                {new Date().toLocaleDateString()}
              </Typography>
              <Typography variant="h4" fontWeight="400" component="div">
                {/* <img src={peso} /> */}₱ {data.cost_value}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
