import { Skeleton, Box, Grid } from "@mui/material";

export default function StockInSkeleton() {
  return (
    <Grid container>
      <Grid item xs={12} sm={5}>
        <Skeleton variant="rectangular" width="100%" height={50} />
        <hr />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Box p={2}>
          <Grid
            container
            sx={{
              width: "100%",
              maxHeight: "60vh",
              overflow: "hidden",
              overflowY: "scroll",
              pt: 2,
            }}
          >
            {Array(18)
              .fill()
              .map((_, index) => (
                <Grid item xs={4} key={index}>
                  <Skeleton
                    variant="rectangular"
                    height="100px"
                    width="100px"
                    sx={{ borderRadius: 2, mb: 1 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="8px"
                    width="100px"
                    sx={{ mb: 1 }}
                  />
                  <Box display="flex">
                    <Skeleton
                      variant="rectangular"
                      height="8px"
                      width="50px"
                      sx={{ marginRight: "20px" }}
                    />
                    <Skeleton variant="rectangular" height="8px" width="30px" />
                  </Box>
                  <br />
                </Grid>
              ))}
          </Grid>
        </Box>
        <br />
        <hr />
        <Skeleton variant="rectangular" width="100%" height={25} />
        <br />
      </Grid>
      <Grid item xs={12} sm={7}>
        <Skeleton
          style={{ marginLeft: 10 }}
          variant="rectangular"
          width="100%"
          height="100%"
        />
      </Grid>
    </Grid>
  );
}
