import axios from "axios";
import apiRequest from "./ApiService";
import { API_UPLOAD_URL } from "@env";

const posEndpoint = "pos";
const utangEndpoint = "pos/utang";
const invoiceEndpoint = "invoice";

export const getPOSProducts = async (
  search = "",
  storeId = 1,
  rowsPerPage = 15,
  filter = 0,
  cancelTokenSource
) => {
  try {
    let url = `/${posEndpoint}`;
    if (search) {
      url += `/search?keyword=${search}&size=${rowsPerPage}&filter[store_id]=${storeId}`;
    } else {
      url += `?size=${rowsPerPage}&filter[store_id]=${storeId}`;
    }
    if (filter) {
      url += `&filter[category_id]=${filter}`;
    }
    const result = await apiRequest.get(url, {
      cancelToken: cancelTokenSource.token,
    });
    return {
      data: result?.data?.data || [],
      status: result?.status === 200 ? "Success" : "Error",
      message: result?.statusText,
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
    } else {
      console.error("Error occurred:", error.message);
    }
  }
};

export const storePosTransaction = async (param) => {
  try {
    const res = await apiRequest.post(`/${posEndpoint}`, param);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const storeUtangTransaction = async (param) => {
  try {
    const res = await apiRequest.post(`/${utangEndpoint}`, param);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const storeInvoiceTransaction = async (param) => {
  try {
    const res = await apiRequest.post(`/${invoiceEndpoint}`, param);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getPaymentTerms = async () => {
  try {
    const res = await apiRequest.get(
      `global_parameters/payment_term?page=all&filter[status]=1`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getFilters = async () => {
  try {
    const res = await apiRequest.get(`store_parameters/category?page=all`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const storeReturnTransaction = async (id ,param) => {
  try {
    const res = await apiRequest.post(`transactions/return-replace/${id}`, param);
    return res;
  } catch (error) {
    console.error(error);
  }
};
