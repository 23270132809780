import { useEffect, useState } from "react";

// libs
import {
  Box,
  Stack,
  Grid,
  Snackbar,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";

// services
import { getStocksIn, exportStockIn } from "../../../../services/StockService";
import DataRecords from "../components/DataRecords";

// sub component
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";

const StocksIn = () => {
  const [loading, setLoading] = useState(false);
  const [dataReport, setDataReport] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [date, setDate] = useState({
    start: dayjs().date(1),
    end: dayjs(new Date()),
  });
  const [rsDataPagination, setRsDataPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const schema = {
    created_at: "",
    product_code: "",
    product_name: "",
    quantity: "",
    cost: "",
    remarks: "",
  };

  const fetchSalesReport = async (page, pageSize) => {
    setPage(page);
    const { data } = await getStocksIn(
      `?&sort=-id&page=${page}&size=${pageSize}&date_start=${dayjs(
        date.start
      ).format("YYYY-MM-DD")}&date_end=${dayjs(date.end).format("YYYY-MM-DD")}`
    );
    if (data && data?.success) {
      let rs = [];
      data?.data?.map((d) => {
        rs.push({
          ...schema,
          id: d?.id,
          created_at: d?.created_at,
          product_code: d?.product.product_code,
          product_name: d?.product.product_name,
          quantity: d?.quantity,
          cost: d?.cost,
          remarks: d?.remarks,
        });
      });
      setDataReport(rs);
      setRsDataPagination(data?.pagination);
    }
  };

  const handleClickExport = async () => {
    try {
      setLoading(true);
      const start = date.start.format("YYYY-MM-DD");
      const end = date.end.format("YYYY-MM-DD");

      const { data } = await exportStockIn(start, end);

      if (data?.success) {
        setOpenSnack(true);
        setSnackVariant("success");
        setErrMsg(
          data?.message ?? "Successfully sent file to your email address"
        );
      } else {
        setOpenSnack(true);
        setSnackVariant("error");
        setErrMsg("Something went wrong, please try again");
      }
      setLoading(false);
    } catch {
      setLoading(false);
      setOpenSnack(true);
      setSnackVariant("error");
      setErrMsg("Something went wrong, please try again");
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchSalesReport(page, pageSize);
  }, [date, pageSize]);

  const tableColumns = [
    {
      field: "created_at",
      headerName: "Date",
      width: 200,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "product_code",
      headerName: "Product Code",
      width: 200,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "cost",
      headerName: "Cost",
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          direction={{ xs: "column", md: "row" }}
        >
          <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date Start"
                value={date?.start}
                onChange={(newValue) =>
                  setDate({
                    ...date,
                    start: newValue,
                  })
                }
              />
              <DatePicker
                label="Date End"
                value={date?.end}
                onChange={(newValue) =>
                  setDate({
                    ...date,
                    end: newValue,
                  })
                }
              />
            </LocalizationProvider>
          </Stack>
          <Box alignContent="center" textAlign="center" p={2}>
            <LoadingButton
              disabled={loading || dataReport?.length <= 0}
              variant="contained"
              onClick={() => handleClickExport()}
              loading={loading}
            >
              <DownloadIcon />
              Export
            </LoadingButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height="100%"
        >
          <DataRecords
            tableColumns={tableColumns}
            tableRecords={dataReport}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => fetchSalesReport(newPage + 1, pageSize)}
            page={page}
            rowCount={rsDataPagination?.total || 0}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default StocksIn;
