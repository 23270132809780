import React, { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// services
import { getBrands } from '../../../../services/StoreParameter/BrandService';

// global css
import GlobalStyles from '../../../../styles/GlobalStyles';

// libs
import {
  styles as styled,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';

import { ModeEdit as ModeEditIcon } from '@mui/icons-material';

// load icons
import { MaterialIcons } from 'react-native-vector-icons';

const ListBrands = ({ renderFlag, onActionChange }) => {
  const [brandList, setBrandList] = useState([]);

  const getBrandList = () => {
    getBrands('?page=all').then((rs) => {
      const data = rs.data.data.map((data) => data);
      setBrandList(data);

      onActionChange({});
    });
  };

  const editItem = (data) => {
    onActionChange(data);
    console.log(data);
  };

  useEffect(() => {
    if (!renderFlag) {
      getBrandList();
    }
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ ml: 4, mt: 4, mb: 2 }} variant='h6' component='div'>
            Brand List
          </Typography>

          {brandList && brandList.length > 0 ? (
            <List style={GlobalStyles.ListCategory}>
              {brandList.map((data, index) => (
                <ListItem
                  key={data?.uuid}
                  secondaryAction={
                    <IconButton
                      edge='end'
                      aria-label='edit'
                      onClick={() => editItem(data)}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={data.brand_name} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography sx={{ textAlignLast: 'center' }}>
              - No record found -
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ListBrands;
