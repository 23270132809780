import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatPrice } from "../../../../utils/Strings";

export default function ItemList(props) {
  const { cart, setCart, products, setProducts, tableRef } = props;

  const columns = [
    { field: "product_code", headerName: "Product Code" },
    { field: "product_name", headerName: "Product Name" },
    {
      field: "quantity",
      headerName: "QTY",
    },
    {
      field: "price",
      headerName: "Price",
    },
    {
      field: "price",
      headerName: "Price at Purchase",
    },
    {
      field: "id",
      headerName: "Action",
    },
  ];

  return (
    <Grid item xs={12} sx={{ flexGrow: 1 }}>
      <Box
        style={{
          borderRadius: 10,
          padding: "1%",
          height: "100%",
        }}
      >
        <TableContainer style={{ height: "70vh", overflow: "auto" }}>
          <Table stickyHeader ref={tableRef}>
            <TableHead>
              <TableRow>
                {columns?.map((col, index) => (
                  <TableCell sx={{ fontWeight: "bold" }} key={index}>
                    {col.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody p={1}>
              {cart.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ padding: "0px 16px" }}>
                    <Typography
                      noWrap
                      sx={{
                        maxWidth: 100,
                        fontSize: 12,
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.product_code || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "0px 16px" }}>
                    <Tooltip
                      title={row.product_name || "-"}
                      placement="top-start"
                    >
                      <Typography
                        noWrap
                        sx={{
                          minWidth: 200,
                          fontSize: 12,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.product_name || "-"}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ padding: "0px 16px" }}>
                    <TextField
                      type="number"
                      value={row?.quantity}
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        min: 1,
                      }}
                      sx={{
                        width: 100,
                        textAlignLast: "center",
                      }}
                      onChange={(e) => {
                        const reg = /^[0-9\b]+$/;
                        const tempCart = cart;
                        const tempProducts = products;
                        if (e.target.value === "" || reg.test(e.target.value)) {
                          const selectedItemIndex = cart.findIndex((item) => {
                            return item.id === row.id;
                          });
                          if (
                            e.target.value === "" ||
                            Number(e.target.value) > 0
                          ) {
                            tempCart[selectedItemIndex].quantity =
                              Number(e.target.value) || "";
                            setProducts(() => [...tempProducts]);
                            setCart(() => [...tempCart]);
                          }
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "0px 16px" }}>
                    <Typography
                      minWidth={75}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {formatPrice(row?.price)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "0px 8px" }}>
                    <Box
                      sx={{
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        minWidth: 100,
                        fontSize: 12,
                      }}
                    >
                      <TextField
                        type="number"
                        value={row.summary.average_cost}
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          min: 1,
                          startAdornment: (
                            <InputAdornment position="start">
                              &#8369;
                            </InputAdornment>
                          ),
                          sx: { fontSize: 12 },
                        }}
                        sx={{
                          minWidth: 70,
                          textAlignLast: "center",
                        }}
                        onChange={(e) => {
                          const reg = /^[0-9.\b]+$/;
                          const tempCart = cart;
                          const tempProducts = products;
                          if (
                            e.target.value === "" ||
                            reg.test(e.target.value)
                          ) {
                            const selectedItemIndex = cart.findIndex((item) => {
                              return item.id === row.id;
                            });

                            tempCart[selectedItemIndex].summary.average_cost =
                              Number(e.target.value) || 0;
                            setProducts(() => [...tempProducts]);
                            setCart(() => [...tempCart]);
                          }
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell sx={{ padding: "0px 16px" }}>
                    <Button
                      onClick={() => {
                        if (
                          confirm("Are you sure you want to delete this item?")
                        ) {
                          const tempCart = cart.filter(
                            (tempRow) => tempRow.id != row.id
                          );
                          const tempProducts = products;
                          const productIndex = products.findIndex(
                            (item) => item.id === row.id
                          );
                          tempProducts[productIndex].available_stock =
                            tempProducts[productIndex].orig_stock;
                          setProducts(() => [...tempProducts]);
                          setCart(() => [...tempCart]);
                        }
                      }}
                      startIcon={<DeleteIcon />}
                      sx={{ pr: 0, minWidth: "25px" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
}
