import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

// libs
import { Box, Grid, TextField, Stack, Button } from "@mui/material";

// services
import { addStock } from "../../../services/StockService";

const AddStocks = ({
  productData,
  setOpenDrawer,
  getStockList,
  setOpenSnack,
  setSnackVariant,
  setErrMsg,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      quantity: "",
      cost: productData.summary?.average_cost || "",
      price: productData?.price || "",
      price_at_purchase: productData?.price_at_purchase || "",
      remarks: "",
    },
  });

  useEffect(() => {
    reset({ ...productData });
  }, [productData]);

  // submit form add stock
  async function onSubmit(data) {
    let formData = {};
    formData.quantity = data.quantity;
    formData.cost = data.price_at_purchase;
    formData.price = data.price;
    formData.product_id = productData.id;
    formData.remarks = data.remarks;

    if (formData) {
      try {
        const data = await addStock(formData);
        if (data?.data?.success == 1) {
          setSnackVariant("success");
          setErrMsg(data?.data?.message);
          getStockList();
          setOpenDrawer(false);
          setOpenSnack(true);
        } else {
          const msg = data.data.errors;
          const updatedErrors = { ...errors };
          for (const key in msg) {
            if (msg.hasOwnProperty(key)) {
              const value = msg[key].join(" ");
              let field = key;
              updatedErrors[key] = { type: "manual", message: value };
              setError(field, {
                type: "server",
                message: value,
              });
            }
          }
        }
      } catch (error) {
      } finally {
      }
    }
  }

  return (
    <Box
      sx={{ flexGrow: 1, width: "408px" }}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2} sx={{ padding: 5 }}>
        <Grid item xs={12} sx={{ padding: 4 }}>
          <TextField
            margin="normal"
            fullWidth
            label="Date"
            variant="filled"
            inputProps={{ autoComplete: "none" }}
            disabled={true}
            value={new Date().toLocaleDateString("en-US")}
          />

          <TextField
            margin="normal"
            fullWidth
            label="Product SKU"
            variant="filled"
            inputProps={{ autoComplete: "none" }}
            disabled={true}
            value={productData.product_sku || ""}
          />

          <TextField
            margin="normal"
            fullWidth
            label="Product Name"
            variant="filled"
            inputProps={{ autoComplete: "none" }}
            disabled={true}
            value={productData.product_name || ""}
          />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="quantity"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Qty"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "e" ||
                        event?.key === "E" ||
                        event?.key === "."
                      ) {
                        event.preventDefault();
                      }
                    }}
                    error={!!errors.quantity}
                    helperText={
                      errors.quantity?.message
                        ? errors.quantity?.message
                        : errors.quantity
                        ? "Quantity is required."
                        : ""
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "none" }}>
              <TextField
                margin="normal"
                fullWidth
                label="Units"
                variant="filled"
                inputProps={{ autoComplete: "none" }}
                disabled={true}
                value={productData.uom_units}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="price"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Price"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    // InputProps={{
                    //   inputProps: { min: 0 },
                    // }}
                    // onKeyPress={(event) => {
                    //   if (event?.key === '-' || event?.key === '+') {
                    //     event.preventDefault();
                    //   }
                    // }}
                    error={!!errors.price}
                    helperText={errors.price && "Price is required."}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="price_at_purchase"
                control={control}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Price at Purchase"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    // InputProps={{
                    //   inputProps: { min: 0 },
                    // }}
                    // onKeyPress={(event) => {
                    //   if (event?.key === '-' || event?.key === '+') {
                    //     event.preventDefault();
                    //   }
                    // }}
                    error={!!errors.price_at_purchase}
                    helperText={
                      errors.price_at_purchase &&
                      "Price at Purchase is required."
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="remarks"
            control={control}
            render={({ field }) => (
              <TextField
                margin="normal"
                fullWidth
                label="Remarks"
                variant="filled"
                inputProps={{ autoComplete: "none" }}
                error={!!errors.remarks}
                helperText={errors.remarks && "Remarks is required."}
                {...field}
              />
            )}
          />

          <Stack
            spacing={2}
            direction="row"
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={5}
          >
            <Button onClick={() => setOpenDrawer(false)} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="success">
              Stock In
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddStocks;
