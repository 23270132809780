import apiRequest from '../ApiService';

const apiEndPoint = 'store_parameters/brand';

/*
 * Get all supplier
 * This will return a list of supplier
 */
export const getBrands = async (param = '') => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    return error;
  }
};

export const AddBrand = async (param) => {
    try {
      return apiRequest.post(`/${apiEndPoint}`, param);
    } catch (error) {
      console.log(error);
    }
  };
  

export const getBrandById = async (param) => {
  try {
    return await apiRequest.get(`/${apiEndPoint}/` + param);
  } catch (error) {
    console.log(error);
  }
};

export const updateBrand = async (uuid, param) => {
  try {
    return await apiRequest.put(`/${apiEndPoint}/` + uuid, param);
  } catch (error) {
    console.log(error);
  }
};

export const brandTableColumns = [{ label: 'Brand Name' }];
