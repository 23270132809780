import apiRequest from "../ApiService";

const apiEndPoint = "store_parameters/supplier";

/*
 * Get all supplier
 * This will return a list of supplier
 */
export const getSuppliers = async (param = '') => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    return error;
  }
};

export const AddSuppliers = async (param) => {
  try {
    return apiRequest.post(`/${apiEndPoint}`, param);
  } catch (error) {
    console.log(error);
  }
};

export const getSupplierByID = async (param) => {
  try {
    return await apiRequest.get(`/${apiEndPoint}/` + param);
  } catch (error) {
    console.log(error);
  }
};

export const updateSupplier = async (uuid, param) => {
  try {
    return await apiRequest.put(`/${apiEndPoint}/` + uuid, param);
  } catch (error) {
    console.log(error);
  }
};

export const supplierTableColumns = [
  { id: 'supplier', label: 'Supplier No' },
  { id: 'name', label: 'Name' },
];
