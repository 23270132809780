import { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Modal,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  NativeSelect,
} from '@mui/material';

import { getCustomers } from '../../../../services/CustomerService';
import AddCustomer from '../../customers/AddCustomer';
import CustomDrawer from '../../../../components/CustomDrawer';
import { formatPrice } from '../../../../utils/Strings';
import { Alert } from '../../../../components/common';
import { getPaymenTypes } from '../../../../services/PaymentService';

const mainModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#FFFFFF',
  bgcolor: '#050F1A',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CUSTOM_PAYMENT = 6;

export default function InvoiceDataModal(props) {
  const { open, handleClose, grandTotal, handleSubmit, paymentTerms, setOpen } =
    props;
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [addCustomer, setAddCustomer] = useState(false);
  const [payment, setPayment] = useState(null);
  const [customDate, setCustomDate] = useState(null);
  const [action, setAction] = useState('add');
  const [error, setError] = useState(false);

  const getCustomerList = () => {
    getCustomers('?page=all&sort=first_name').then((resp) => {
      setCustomers(resp.data.data?.filter((x, i, a) => a.indexOf(x) === i));
    });
  };

  // get customers from api and set to customers state
  useEffect(() => {
    getCustomerList();
  }, []);

  const handleChange = (event) => {
    setPayment(event.target.value);
  };
  const searchCustomer = async (e) => {
    const param = e.target.value || '';
    const customerList = await getCustomers(
      '?sort=first_name&keyword=' + param
    );

    try {
      const data = customerList && customerList?.data?.data;
      if (data) {
        setCustomers(
          customerList?.data?.data?.filter((x, i, a) => a.indexOf(x) === i)
        );
      }
    } catch (error) {
      setCustomers([]);
    }
  };

  const [paymentTypeId, setPaymentTypeId] = useState(1);
  const [paymentTypes, setPaymentTypes] = useState([]);
  useEffect(() => {
    getPaymenTypes().then((res) => {
      setPaymentTypes(res.data.data);
    });
  }, []);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        onClose={() => {
          handleClose();
          getCustomerList();
          setCustomer(null);
          setPayment(null);
        }}
      >
        <Box sx={mainModalStyle}>
          <Box>
            <Typography textAlign='center' variant='h6' component='h2'>
              Invoice Transaction
            </Typography>
            <hr />
            <br />
            <Box display='flex' justifyContent='space-between'>
              <Typography>TOTAL AMOUNT DUE</Typography>
              <Typography>{formatPrice(grandTotal)}</Typography>
            </Box>
            <br />
            <Typography variant='caption' gutterBottom>
              Payment Type
            </Typography>
            <Box textAlign='center' display='flex' flexDirection='column'>
              <FormControl sx={{ background: 'gray' }}>
                <Select
                  value={paymentTypeId}
                  label='Payment Type'
                  onChange={(e) => setPaymentTypeId(e.target.value)}
                >
                  {paymentTypes.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Typography variant='caption' gutterBottom textAlign='left'>
              Select Customer
            </Typography>
            <Box sx={{ background: 'gray' }}>
              <Autocomplete
                disablePortal
                onChange={(e, value) => {
                  setCustomer(value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.defaultMuiPrevented = true;
                  } else {
                    searchCustomer(event);
                  }
                }}
                options={customers.map((row) => {
                  return {
                    label: `${row.first_name} ${row.last_name}`,
                    id: row.id,
                  };
                })}
                fullWidth
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box textAlign='center' display='flex' flexDirection='column'>
              <Button
                variant='standard'
                color='info'
                onClick={() => {
                  setAddCustomer(true);
                  handleClose();
                  getCustomerList();
                }}
              >
                Add New Customer
              </Button>
              <br />
              <Typography variant='caption' gutterBottom textAlign='left'>
                Payment Terms
              </Typography>
              <FormControl sx={{ background: 'gray' }}>
                <Select
                  value={payment}
                  label='Payment Terms'
                  onChange={handleChange}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {paymentTerms?.map((row, index) => (
                    <MenuItem key={index} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {payment === CUSTOM_PAYMENT && (
                <FormControl id='paymentDue'>
                  <Typography variant='caption' gutterBottom textAlign='left'>
                    Payment Due
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    autoComplete='off'
                    value={customDate}
                    type='date'
                    inputProps={{
                      min: new Date().toISOString().slice(0, 10),
                      sx: { background: 'gray' },
                    }}
                    onChange={(e) => {
                      setCustomDate(e.target.value);
                    }}
                    sx={{ p: 1 }}
                  />
                </FormControl>
              )}
              <br />
              {error && payment === CUSTOM_PAYMENT && (
                <Alert
                  onClose={() => {
                    setError(false);
                  }}
                  severity='error'
                  sx={{ width: '100%', alignItems: 'center' }}
                >
                  <Typography sx={{ color: 'white' }} fontWeight='md'>
                    Please select Payment Due !
                  </Typography>
                </Alert>
              )}
              <br />
              <Button
                variant='contained'
                color='success'
                onClick={() => {
                  if (handleSubmit) {
                    if (payment === CUSTOM_PAYMENT) {
                      if (customDate) {
                        setError(false);
                        setPayment(null);
                        setCustomDate(null);
                        setCustomer(null);
                        handleSubmit(
                          customer?.id,
                          payment,
                          customDate,
                          paymentTypeId
                        );
                      } else {
                        setError(true);
                      }
                    } else {
                      setError(false);
                      setPayment(null);
                      setCustomDate(null);
                      setCustomer(null);
                      handleSubmit(
                        customer?.id,
                        payment,
                        customDate,
                        paymentTypeId
                      );
                    }
                  }
                }}
                disabled={!customer || !payment}
              >
                Create Invoice
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {addCustomer && (
        <CustomDrawer
          setOpenDrawer={setAddCustomer}
          openDrawer={addCustomer}
          setAction={setAction}
          action={action}
          showAddButton={false}
        >
          {action === 'add' && (
            <AddCustomer
              setOpenDrawer={setAddCustomer}
              getCustomerList={getCustomerList}
              setOpenSnack={setOpen}
            />
          )}
        </CustomDrawer>
      )}
    </>
  );
}
