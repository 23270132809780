import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  InputAdornment,
} from "@mui/material";
import { Alert } from "../../../../components/common";

export function DiscountDialog(props) {
  const { onClose, open, onConfirm, currentTotal = 0, discount } = props;
  const [productDiscount, setProductDiscount] = useState("");
  const [error, setError] = useState(false);

  const handleCancel = () => {
    onClose();
    setProductDiscount("");
  };

  const handleOk = () => {
    if (Number(productDiscount) < currentTotal) {
      onConfirm && onConfirm(productDiscount);
      onClose();
      setError(false);
      setProductDiscount(0);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "25%", maxHeight: 435 },
        textAlign: "center",
      }}
      maxWidth="sm"
      open={open}
      keepMounted
    >
      <DialogTitle>Discount</DialogTitle>
      <DialogContent
        dividers
        display="flex"
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <TextField
          fullWidth
          type="number"
          inputProps={{ min: 1 }}
          InputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            startAdornment: (
              <InputAdornment position="start">&#8369;</InputAdornment>
            ),
          }}
          sx={{ minWidth: 50, textAlignLast: "center" }}
          value={productDiscount}
          onChange={(e) => {
            const reg = /^[0-9.\b]+$/;
            if (e.target.value === "" || reg.test(e.target.value)) {
              setProductDiscount(e.target.value);
            }
          }}
          onKeyDown={(event) => {
            const reg = /^[0-9.\b]+$/;
            if (!reg.test(event.key) && event.key != "Backspace") {
              event.preventDefault();
            }
          }}
        />
      </DialogContent>
      {error && (
        <Alert
          onClose={() => {
            setError(false);
          }}
          severity="error"
          sx={{ width: "100%", alignItems: "center" }}
        >
          <Typography sx={{ color: "white" }} fontWeight="md">
            Invalid Amount !
          </Typography>
        </Alert>
      )}
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button autoFocus onClick={handleCancel}>
          CANCEL
        </Button>
        <Button onClick={handleOk}>CONFIRM</Button>
      </DialogActions>
    </Dialog>
  );
}
