import apiRequest from "./ApiService";

const apiEndPoint = "transactions";

export const navTabs = [
  { id: "all", label: "All" },
  { id: "pos", label: "OMS" },
  { id: "invoices", label: "Invoices" },
  { id: "return", label: "Returns" },
];

export const getTransactions = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    console.log(error);
  }
};

export const getTransactionById = async (uuid = "") => {
  try {
    const { data } = await apiRequest.get(`/${apiEndPoint}/` + uuid);
    if (data?.datas && data?.success) {
      return data?.datas;
    }
  } catch (error) {
    console.log(error);
  }
};

export const storePaymentTransaction = async (param) => {
  try {
    const res = await apiRequest.post(`/${apiEndPoint}/payment`, param);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const tableDummyRecords = [
  {
    id: 1,
    transaction_no: "10-1123-0001",
    date: "2023-12-12",
    customer: "John Doe ",
    payment_statuss: "Paid",
    type_of_transaction: "POS",
  },
  {
    id: 2,
    transaction_no: "10-1123-0002",
    date: "2023-12-12",
    customer: "John Doe",
    payment_statuss: "Paid",
    type_of_transaction: "POS",
  },
  {
    id: 3,
    transaction_no: "10-1123-1233",
    date: "2023-12-12",
    customer: "Jane Doe",
    payment_statuss: "Paid",
    type_of_transaction: "Invoice",
  },
  {
    id: 4,
    transaction_no: "10-1123-1233",
    date: "2023-12-12",
    customer: "John Doe",
    payment_statuss: "Pending",
    type_of_transaction: "Invoice",
  },
  {
    id: 5,
    transaction_no: "10-1123-1233",
    date: "2023-12-12",
    customer: "John Doe",
    payment_statuss: "Paid",
    type_of_transaction: "POS",
  },
];

export const getTransactionReceipt = async (param = "", store_name) => {
  try {
    return await apiRequest.get(`/transaction_receipt/${store_name}/` + param);
  } catch (error) {
    console.log(error);
  }
};

export const exportTransactions = async (type) => {
  try {
    return await apiRequest
      .post(`/downloads?download_type=transaction&type=${type}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  } catch (error) {
    console.log(error);
  }
};
