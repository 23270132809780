// exported from libs, so it may allow dev to modify
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { View, Modal, Text, TouchableOpacity, ScrollView } from 'react-native';

import AntDesign from 'react-native-vector-icons/AntDesign';
import Entypo from 'react-native-vector-icons/Entypo';

import FontAwesome from 'react-native-vector-icons/FontAwesome';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Fontisto from 'react-native-vector-icons/Fontisto';
import Ionicons from 'react-native-vector-icons/Ionicons';

import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import styles from './style';

// global css
import GlobalStyles from '../../../../../styles/GlobalStyles';

// libs
import { Grid, InputAdornment, TextField } from '@mui/material';

// icons
import { Search as SearchIcon } from '@mui/icons-material';

const iconComponents = {
  AntDesign,
  Entypo,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
};
const ICON_COLOR_SELECTED_COLOR = 'white';
const ICON_COLOR = '#999999';
const ICON_SIZE = 20;
const MODAL_CLOSE_COLOR = '#000000';
const MODAL_CLOSE_ICON_SIZE = 20;

class IconPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wildCard: '',
    };
  }

  renderItem = (data) => {
    let selectedIconContainerStyle = this.props.selectedIconContainerStyle
      ? this.props.selectedIconContainerStyle
      : styles.selectedItem;
    let { selectedIcon } = this.props;
    return data.map((item, index) => {
      if (!item) return;

      const Family = iconComponents[item.family];
      if (!Family) return;

      let isSelected =
        selectedIcon &&
        selectedIcon.icon == item.icon &&
        selectedIcon.family == item.family;
      return (
        <TouchableOpacity
          key={index}
          style={[styles.item, isSelected ? selectedIconContainerStyle : {}]}
          onPress={() => {
            this.props.onSelect(item);
            this.setState({ wildCard: '' });
          }}
        >
          <Family
            name={item.icon}
            size={ICON_SIZE}
            color={isSelected ? ICON_COLOR_SELECTED_COLOR : ICON_COLOR}
          />
        </TouchableOpacity>
      );
    });
  };

  handleFilter = (e) => {
    const search_value = e.target.value || '';
    this.setState({ wildCard: search_value });
  };

  render() {
    let { iconDetails, showIconPicker } = this.props;
    const headerTitle = this.props.headerTitle
      ? this.props.headerTitle
      : 'Select Icon';

    const data = [];
    if (iconDetails && iconDetails.length > 0) {
      iconDetails.map((detail) => {
        let family = detail.family;
        let color = detail.color ? detail.color : ICON_COLOR;
        if (detail.icons && detail.icons.length > 0) {
          if (this.state.wildCard && this.state.wildCard?.length) {
            detail.icons.map((icon) => {
              if (String(icon).toLowerCase().includes(this.state.wildCard)) {
                data.push({
                  icon: icon,
                  family: family,
                  color: color,
                });
              }
            });
          } else {
            detail.icons.map((icon) => {
              data.push({
                icon: icon,
                family: family,
                color: color,
              });
            });
          }
        }
      });
    }
    return (
      <>
        <Modal
          animationType='fade'
          transparent={true}
          visible={showIconPicker}
          onRequestClose={this.props.toggleIconPicker}
        >
          <View style={styles.wrapper}>
            <View style={styles.content}>
              {/* header */}
              <View style={[styles.modalHeader]}>
                <View style={[styles.modalHeaderWrapper]}>
                  <View>
                    <Text style={styles.modalTitle}>{headerTitle}</Text>
                  </View>
                  <TouchableOpacity onPress={this.props.toggleIconPicker}>
                    <Ionicons
                      name={'close'}
                      size={MODAL_CLOSE_ICON_SIZE}
                      color={MODAL_CLOSE_COLOR}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              {/* Content */}

              <Grid container>
                <Grid
                  item
                  xs={12}
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                >
                  <TextField
                    onChange={(e) => {
                      this.handleFilter(e);
                    }}
                    value={this.state.wildCard}
                    placeholder='Search'
                    sx={{ m: 1, width: '10', color: GlobalStyles.searchInput }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position='start'
                          sx={{ color: 'white' }}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ autoComplete: 'none' }}
                    style={styles.searchBar}
                  />
                </Grid>
              </Grid>

              <ScrollView style={[styles.modalContent]}>
                <View style={styles.iconContainer}>
                  {this.renderItem(data)}
                </View>
              </ScrollView>
            </View>
          </View>
        </Modal>
        <TouchableOpacity onPress={this.props.toggleIconPicker}>
          {this.props.content ? (
            this.props.content
          ) : (
            <Text>Open Iconpicker</Text>
          )}
        </TouchableOpacity>
      </>
    );
  }
}

IconPicker.propTypes = {
  headerTitle: PropTypes.string,
  showIconPicker: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleIconPicker: PropTypes.func.isRequired,
  iconDetails: PropTypes.array.isRequired,
  content: PropTypes.any,
  selectedIcon: PropTypes.object,
  selectedIconContainerStyle: PropTypes.object,
};

export default IconPicker;
