import React from 'react';
import { Box } from '@mui/material';
import AsensoLogo from '../../../assets/images/asenso-logo.png';

const Logo = ({ backgroundColor = 'transparent', height = '135px', width = '306px' }) => {
  return (
    <Box
      component='img'
      sx={{
        height: height,
        width: width,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 1.2,
        backgroundColor: backgroundColor,
      }}
      alt='Asenso Logo'
      src={AsensoLogo}
    />
  );
};

export default Logo;
