import React, { useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// global css
import GlobalStyles from '../../../styles/GlobalStyles';

// icons
import stock_list from '../../../../assets/icons/stock_list.svg';


import MainCategory from './category/MainCategory';
import MainBrand from './brand/MainBrand';

// libs
import { Box, Tab, Paper } from '@mui/material';



const Settings = () => {
  // navigation change tab
  const [value, setValue] = React.useState('1');
  const handleChangeTab = async (e, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChangeTab} centered variant='fullWidth'>
            {/* <Tab
              icon={<CircleIcon sx={{ fontSize: 'medium' }} />}
              iconPosition='start'
              label='Scanner'
              value='0'
            /> */}
            <Tab
              icon={<img src={stock_list} />}
              iconPosition='start'
              label='Product Category'
              value='1'
            />
            <Tab
              icon={<img src={stock_list} />}
              iconPosition='start'
              label='Brand'
              value='2'
            />
            {/* <Tab
              icon={<CircleIcon sx={{ fontSize: 'medium' }} />}
              iconPosition='start'
              label='Payment'
              value='3'
            /> */}
          </TabList>
        </Box>

        {/* Scanner */}
        <TabPanel value='0'></TabPanel>
        {/* Scanner */}

        {/* Product Category */}
        <TabPanel value='1'>
          <MainCategory />
        </TabPanel>
        {/* Product Category */}

        {/* Brand */}
        <TabPanel value='2'>
          <MainBrand />
        </TabPanel>
        {/* Brand */}

        {/* Payment */}
        <TabPanel value='3'>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>4</Paper>
        </TabPanel>
        {/* Payment */}
      </TabContext>
    </>
  );
};

export default Settings;
