import { useState, useEffect } from "react";
import { Chip, Box } from "@mui/material";
import { getFilters } from "../../../../services/PosService";

export default function CategoryChips(props) {
  const {
    searchText,
    storeId,
    rowsPerPage,
    filter,
    setFilter,
    getProductList,
  } = props;
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const getFiltersData = async () => {
      getFilters().then((response) => {
        setFilters(response?.data?.data || []);
      });
    };

    //Call Functions
    getFiltersData();
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "90vw",
        display: "flex",
        overflowX: "scroll",
        padding: 2,
        margin: "auto",
      }}
    >
      {filters?.map((row, index) => (
        <Chip
          key={index}
          label={row?.category_name}
          style={{
            background: row?.id === filter ? "#309737" : "#454545",
            marginRight: 10,
          }}
          onClick={() => {
            const currentFilter = row?.id === filter ? 0 : row?.id;
            setFilter(currentFilter);
            getProductList(searchText, storeId, rowsPerPage, currentFilter);
          }}
        />
      ))}
    </Box>
  );
}
