import { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  Typography,
  Modal,
  Alert,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { formatPrice } from "../../../../utils/Strings";
import { getCustomers } from "../../../../services/CustomerService";
import CustomDrawer from "../../../../components/CustomDrawer";
import AddCustomer from "../../customers/AddCustomer";
import { getPaymenTypes } from "../../../../services/PaymentService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#050F1A",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ChargeModal(props) {
  const { open, handleClose, grandTotal, handleSubmit, setOpen } = props;
  const [amountReceive, setAmountReceive] = useState(0);
  const [error, setError] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [addCustomer, setAddCustomer] = useState(false);
  const [action, setAction] = useState("add");

  const getCustomerList = () => {
    getCustomers("?page=all&sort=first_name").then((resp) => {
      setCustomers(resp.data.data);
    });
  };

  useEffect(() => {
    const cust = JSON.parse(localStorage.getItem("customer"));
    if (cust) {
      setCustomer(cust);
    }
  }, []);

  // get customers from api and set to customers state
  useEffect(() => {
    getCustomerList();
  }, []);

  const searchCustomer = async (e) => {
    const param = e.target.value || "";
    const customerList = await getCustomers(
      "?sort=first_name&keyword=" + param
    );

    try {
      const data = customerList && customerList?.data?.data;
      if (data) {
        setCustomers(customerList?.data?.data);
      }
    } catch (error) {
      setCustomers([]);
    }
  };

  const [paymentTypeId, setPaymentTypeId] = useState(1);
  const [paymentTypes, setPaymentTypes] = useState([]);
  useEffect(() => {
    getPaymenTypes().then((res) => {
      setPaymentTypes(res.data.data);
    });
  }, []);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => {
          setError(false);
          handleClose && handleClose();
        }}
      >
        <Box sx={style}>
          <Typography textAlign="center" variant="h6" component="h2">
            Transaction
          </Typography>
          <hr />
          <br />
          <Box display="flex" justifyContent="space-between">
            <Typography>TOTAL AMOUNT DUE</Typography>
            <Typography> {formatPrice(grandTotal)}</Typography>
          </Box>
          <br />
          <Typography variant="caption" gutterBottom>
            Payment Type
          </Typography>
          <Box textAlign="center" display="flex" flexDirection="column">
            <FormControl sx={{ background: "gray" }}>
              <Select
                value={paymentTypeId}
                label="Payment Type"
                onChange={(e) => setPaymentTypeId(e.target.value)}
              >
                {paymentTypes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Typography variant="caption" gutterBottom>
            Amount Received
          </Typography>
          <Box sx={{ background: "gray" }}>
            <TextField
              inputProps={{
                min: 0,
              }}
              InputProps={{
                type: "number",
                startAdornment: (
                  <InputAdornment position="start">&#8369;</InputAdornment>
                ),
              }}
              fullWidth
              variant="outlined"
              onChange={(event) => setAmountReceive(event.target.value)}
            />
          </Box>
          <br />
          {error && (
            <Alert
              onClose={() => {
                setError(false);
              }}
              severity="error"
              sx={{
                width: "100%",
                alignItems: "center",
                background: "#922929",
                color: "#FFFFFF",
              }}
            >
              <Typography fontWeight="md">Invalid Amount !</Typography>
            </Alert>
          )}
          <Box sx={{ textAlign: "center" }}>
            <Autocomplete
              disablePortal
              defaultValue={customer}
              onChange={(e, value) => {
                setCustomer(value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.defaultMuiPrevented = true;
                } else {
                  searchCustomer(event);
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value}
              getOptionLabel={(option) =>
                option.first_name + " " + option.last_name
              }
              options={customers}
              // options={customers.map((row) => {
              //   return {
              //     label: `${row.first_name} ${row.last_name}`,
              //     id: row.id,
              //   };
              // })}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Select Customer" />
              )}
            />
          </Box>
          <Box textAlign="center" display="flex" flexDirection="column">
            <Button
              variant="standard"
              color="info"
              onClick={() => {
                setAddCustomer(true);
                getCustomerList();
                handleClose();
              }}
            >
              Add New Customer
            </Button>
            <br />
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                if (
                  Number(amountReceive) > 0 &&
                  Number(amountReceive) >= Number(grandTotal)
                ) {
                  setError(false);
                  setAmountReceive(0);
                  handleSubmit &&
                    handleSubmit(amountReceive, customer?.id, paymentTypeId);
                } else {
                  setError(true);
                }
              }}
            >
              Charge {formatPrice(amountReceive)}
            </Button>
            <br />
            <Button
              variant="outlined"
              color="success"
              onClick={() =>
                handleSubmit &&
                handleSubmit(grandTotal, customer?.id, paymentTypeId)
              }
            >
              Charge Exact Amount {formatPrice(grandTotal)}
            </Button>
          </Box>
        </Box>
      </Modal>

      {addCustomer && (
        <CustomDrawer
          setOpenDrawer={setAddCustomer}
          openDrawer={addCustomer}
          setAction={setAction}
          action={action}
          showAddButton={false}
        >
          {action === "add" && (
            <AddCustomer
              setOpenDrawer={setAddCustomer}
              getCustomerList={getCustomerList}
              setOpenSnack={setOpen}
            />
          )}
        </CustomDrawer>
      )}
    </>
  );
}
