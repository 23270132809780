import axios from "axios";
import { API_UPLOAD_URL, API_URL } from "@env";
import apiRequest from "./ApiService";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const uploadPhoto = async (formData) => {
  return await axios.post(API_UPLOAD_URL + "v1/drive/process", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadCSV = async (formData) => {
  const token = await AsyncStorage.getItem("access_token");
  return await axios.post(API_URL + "csv/import-inventory", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
};
