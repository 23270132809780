import apiRequest from "../ApiService";

const apiEndPoint = "reports/collection-report";

export const getCollectionReports = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    console.log(error);
  }
};

export const exportReport = async (date_start, date_end) => {
  try {
    return await apiRequest
      .post(
        `/downloads?download_type=collection&date_start=` +
          date_start +
          `&date_end=` +
          date_end
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  } catch (error) {
    console.log(error);
  }
};
