import React from "react";
import { useLocation } from "react-router-dom";

// common

// libs
import { Box, Button, Drawer, Toolbar, Typography } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";

const CustomDrawer = ({
  children,
  setOpenDrawer,
  openDrawer,
  setAction,
  action,
  showAddButton = true,
  setDrawerTitle,
  onClose,
}) => {
  const location = useLocation();
  const currentLocation = location.pathname.split("/")[1];

  const clickAddButton = (e) => {
    setOpenDrawer(e);
    setAction("add");
    onClose && onClose();
  };

  const content = () => (
    <Box role="presentation">
      <Box>
        <Toolbar variant="dense">
          <ArrowBackIcon
            onClick={() => clickAddButton(false)}
            sx={{ mr: 3 }}
          ></ArrowBackIcon>
          <Typography
            variant="h6"
            display="block"
            color="inherit"
            component="div"
          >
            {action == "profile" ||
            action == "store" ||
            action == "password" ||
            action == "view barcode"
              ? action.toUpperCase()
              : action.toUpperCase() + " " + currentLocation.toUpperCase()}
          </Typography>
        </Toolbar>
      </Box>
      {children}
    </Box>
  );

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openDrawer}
      ></Backdrop>

      {showAddButton && ( // showAddButton is true
        <Button
          variant="contained"
          color="success"
          onClick={() => clickAddButton(true)}
          sx={{ mx: 2 }}
        >
          Add {setDrawerTitle ? setDrawerTitle : currentLocation}
        </Button>
      )}
      <Box m={2} sx={{ zIndex: "1201" }}>
        <Drawer
          anchor="right"
          variant="persistent"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          {content()}
        </Drawer>
      </Box>
    </>
  );
};

export default CustomDrawer;
