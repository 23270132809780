import apiRequest from "./ApiService";

const apiEndPoint = "customers";

export const getCustomers = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    return error;
  }
};

export const addCustomer = async (param) => {
  try {
    return apiRequest.post(`/${apiEndPoint}`, param);
  } catch (error) {
    console.log(error);
  }
};

export const updateCustomer = async (uuid, param) => {
  try {
    return await apiRequest.put(`/${apiEndPoint}/` + uuid, param);
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerByID = async (param) => {
  try {
    return await apiRequest.get(`/${apiEndPoint}/` + param, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerTransactionByID = async (uuid, param) => {
  try {
    return await apiRequest.get(`/${apiEndPoint}/profile/${uuid}` + param);
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerPaymentByID = async (uuid, param) => {
  try {
    return await apiRequest.get(
      `/${apiEndPoint}/payment-history/${uuid}` + param
    );
  } catch (error) {
    console.log(error);
  }
};

export const customerTableColumns = [
  { id: "customer", label: "Customers", sortable: true },
  { id: "location", label: "Location", sortable: true },
  { id: "orders", label: "Orders", sortable: true },
  { id: "spent", label: "Spent", sortable: true },
  { id: "balance", label: "Unpaid Balance", sortable: true },
  { id: "status", label: "Status", sortable: true },
  { id: "action", label: "Action", sortable: true },
];

export const getInvoiceHistoryColumns = [
  { id: "Total Amount", label: "Total Amount", sortable: true },
  { id: "Paid Amount", label: "Paid Amount", sortable: true },
  { id: "Balance Amount", label: "Balance Amount", sortable: true },
  { id: "Transaction Number", label: "Transaction Number", sortable: true },
];

export const getPaymentHistoryColumns = [
  { id: "Payment Type", label: "Payment Type", sortable: true },
  { id: "Amount", label: "Amount", sortable: true },
];
