import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// images
import empty_image from "./../../../../assets/images/empty_image.svg";

// component
import CustomDrawer from "../../../components/CustomDrawer";

// libs
import {
  Box,
  Grid,
  Card,
  Stack,
  CardMedia,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  Typography,
  Pagination,
} from "@mui/material";

// common
import { Alert } from "../../../components/common";

// services
import {
  getCustomerByID,
  getInvoiceHistoryColumns as columns,
  getCustomerTransactionByID,
  getPaymentHistoryColumns as columnPayments,
  getCustomerPaymentByID,
} from "../../../services/CustomerService";

const ViewCustomer = () => {
  const [pagePay, setPagePay] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPagePayment, setRowsPerPagePayment] = React.useState(10);
  const [pagination, setPagination] = useState({});
  const [paginationPay, setPaginationPay] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [action, setAction] = useState("view barcode");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerImage, setCustomerImage] = useState("");
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");

  // Transaction history
  const [transaction, setTransaction] = useState([]);
  const getTransactionHistory = (param = 1) => {
    getCustomerTransactionByID(uuid, `?page=${param}`).then((response) => {
      setTransaction(response?.data?.data?.transaction_history);
      setPagination(response?.data?.pagination);
    });
  };

  // pagination Transaction history
  const handleChangePage = (event, newPage) => {
    setOpenLoader(true);
    getTransactionHistory(newPage);
    setPage(newPage);
  };

  // Payment History
  const [payment, setPayment] = useState([]);
  const getPaymentHistory = (param = 1) => {
    getCustomerPaymentByID(uuid, `?page=${param}`).then((response) => {
      setPayment(response?.data?.data);
      setPaginationPay(response?.data?.pagination);
      console.log(response?.data?.pagination);
    });
  };

  // pagination
  const handleChangePagePayment = (event, newPage) => {
    setOpenLoader(true);
    getPaymentHistory(newPage);
    setPagePay(newPage);
  };

  const location = useLocation();
  const uuid = location.pathname.split("/").pop();

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const getCustomerDetails = () => {
    getCustomerByID(uuid).then((rs) => {
      setCustomerData(rs.data.data);
      setCustomerImage(rs.data.data.customer_image);
    });
  };

  useEffect(() => {
    getCustomerDetails();
    getTransactionHistory();
    getPaymentHistory();
  }, []);

  return (
    <>
      <Grid container>
        <CustomDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setAction={setAction}
          action={action}
          showAddButton={false}
        ></CustomDrawer>
      </Grid>
      <Box sx={{ flexGrow: 1 }} component="form" noValidate>
        <Grid container spacing={2} sx={{ padding: 5 }}>
          <Card sx={{ padding: "20px", width: "100%" }}>
            <Grid item xs={12} sx={{ padding: 4 }}>
              <Grid container spacing={2}>
                <Grid xs={2}>
                  <CardMedia
                    component="img"
                    sx={{
                      border: "3px solid #fff",
                      borderRadius: "15%",
                      height: "150px",
                      width: "150px",
                      objectFit: "contain",
                    }}
                    src={customerImage ? customerImage : empty_image}
                    alt="Customer Image"
                  />
                </Grid>
                <Grid xs={10} sx={{ pl: "40px" }}>
                  <Typography component="div" variant="h6">
                    <b>
                      {" "}
                      {customerData?.last_name || ""}
                      {","} {customerData?.first_name || ""}{" "}
                    </b>
                  </Typography>
                  <Typography variant="h7" component="div">
                    <i>Mobile Number : </i>
                    <b> +63{customerData?.mobile_number} </b>
                  </Typography>
                  <Typography variant="h7" component="div">
                    <i>Email Address :</i>{" "}
                    <b> {customerData?.email_address} </b>
                  </Typography>
                  <Typography variant="h7" component="div">
                    <i>Address : </i>
                    <b>
                      {customerData.address_text
                        ? customerData.address_text +
                          ", " +
                          customerData.province?.province_name +
                          ", " +
                          customerData.region?.region_name
                        : " "}
                    </b>
                  </Typography>
                  <Grid item xs={12} sx={{ padding: 4, pl: "15px" }}>
                    <Grid container spacing={2}>
                      <Grid xs={6}>
                        <Typography variant="h7" component="div">
                          <i>Business Name :</i>{" "}
                          <b> {customerData?.business_name || ""} </b>
                        </Typography>
                        <Typography variant="h7" component="div">
                          <i>Postal Code :</i>{" "}
                          <b> {customerData?.address_postal || ""} </b>
                        </Typography>
                        <Typography>
                          <b> </b>
                        </Typography>
                        <Typography variant="h7" component="div">
                          <i>Utang :</i>{" "}
                          <b> {customerData?.outstanding_balance || ""} </b>
                        </Typography>
                        <Typography>
                          <b> </b>
                        </Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant="h7" component="div">
                          <i>Company Email :</i>{" "}
                          <b> {customerData?.business_email_address || ""} </b>
                        </Typography>
                        <Typography variant="h7" component="div">
                          <i>Company Contact :</i>{" "}
                          <b> {customerData?.business_contact_number || ""} </b>
                        </Typography>
                        <Typography variant="h7" component="div">
                          <i>Total Spent :</i>{" "}
                          <b> {customerData?.total_spent || ""} </b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={8}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                ></Grid>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ padding: "20px", mt: "20px", width: "100%" }}>
            <Typography component="div" variant="h6">
              <b>Invoice Transaction History</b>
            </Typography>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {transaction && transaction.length > 0 ? (
                      transaction.length > 0 &&
                      transaction.map((transactions, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell>{transactions.total_amount}</TableCell>
                            <TableCell>{transactions.paid_amount}</TableCell>
                            <TableCell>{transactions.balance_amount}</TableCell>
                            <TableCell>
                              {transactions.transaction_number}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} sx={{ textAlignLast: "center" }}>
                          No record found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid container>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  Showing {page ? (page - 1) * rowsPerPage + 1 : 0} -{" "}
                  {page
                    ? page * rowsPerPage > pagination.total
                      ? pagination.total
                      : page * rowsPerPage
                    : 0}{" "}
                  of {pagination.total ? pagination.total : 0} matches{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(pagination.total / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                    size="small"
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </Grid>
            </Grid>
            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleCloseSnack}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseSnack}
                severity={snackVariant}
                sx={{ width: "100%" }}
              >
                {errMsg}
              </Alert>
            </Snackbar>
          </Card>
          <Card sx={{ padding: "20px", mt: "20px", width: "100%" }}>
            <Typography component="div" variant="h6">
              <b>Payment History</b>
            </Typography>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columnPayments.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {payment && payment.length > 0 ? (
                      payment.length > 0 &&
                      payment.map((payments, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell>{payments.payment_type_name}</TableCell>
                            <TableCell>{payments.amount}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} sx={{ textAlignLast: "center" }}>
                          No record found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid container>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  Showing {pagePay ? (pagePay - 1) * rowsPerPagePayment + 1 : 0}{" "}
                  -{" "}
                  {pagePay
                    ? pagePay * rowsPerPagePayment > paginationPay.total
                      ? paginationPay.total
                      : pagePay * rowsPerPagePayment
                    : 0}{" "}
                  of {paginationPay.total ? paginationPay.total : 0} matches{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(paginationPay.total / rowsPerPagePayment)}
                    page={pagePay}
                    onChange={handleChangePagePayment}
                    color="primary"
                    size="small"
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </Grid>
            </Grid>
            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleCloseSnack}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseSnack}
                severity={snackVariant}
                sx={{ width: "100%" }}
              >
                {errMsg}
              </Alert>
            </Snackbar>
          </Card>
        </Grid>
      </Box>
    </>
  );
};

export default ViewCustomer;
