import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { Snackbar, TextField, Box, Stack, Alert, Button } from "@mui/material";

// libs
import { Grid, Typography } from "@mui/material";
import IconPicker from "./iconPicker";

// load icons
import { MaterialIcons } from "react-native-vector-icons";

// services
import {
  iconList,
  addCategory,
  updateCategory,
} from "../../../../services/StoreParameter/CategoryService";

const FormCategory = ({ onDataListChange, action }) => {
  const [category_name, setCategoryName] = useState("");
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "success" });
  const [isAddAction, setIsAddAction] = useState(true);
  const [selectedIcon, setSelectedIcon] = useState({
    family: "MaterialIcons",
    name: "folder",
    color: "white",
  });

  const [showIconPicker, setShowIconPicker] = useState(false);

  useEffect(() => {
    onDataListChange(true);

    // update from list
    if (action && action?.uuid) {
      setIsAddAction(false);

      // from update component
      setCategoryName(action?.category_name);
      setSelectedIcon({
        family: "MaterialIcons",
        name: action?.category_icon || "folder",
        color: "white",
      });
    } else {
      setIsAddAction(true);
    }
  }, [action]);

  const resetIcon = () => {
    setSelectedIcon({
      family: "MaterialIcons",
      name: "folder",
      color: "white",
    });
  };

  const onSelectIcon = (icon) => {
    setSelectedIcon({
      family: icon?.family || "MaterialIcons",
      name: icon?.icon || "folder",
    });
    setShowIconPicker(false);
  };

  const addRecord = async () => {
    const store_id = (await AsyncStorage.getItem("store_id")) || null;
    const created_by = (await AsyncStorage.getItem("user_id")) || null;
    try {
      onDataListChange(false);
      const { data } = await addCategory({
        store_id,
        category_name,
        category_icon: selectedIcon?.name || "folder",
        created_by,
      });

      if (data && data?.success) {
        setAlert({ message: data?.message, type: "success" });
        setOpen(true);
        cancelForm();
      } else {
        setAlert({
          message: data?.message?.category_name[0] || null,
          type: "warning",
        });
        setOpen(true);

        console.log(
          "[fail add category] : ",
          data?.message?.category_name[0] || null
        );
      }
    } catch (error) {
      console.log("[err add category] : ", error);

      setAlert({ message: "Invalid input.", type: "error" });
      setOpen(true);
    }
  };

  const updateRecord = async () => {
    const store_id = (await AsyncStorage.getItem("store_id")) || null;
    try {
      onDataListChange(false);

      const param = {
        category_name,
        category_icon: selectedIcon?.name || "folder",
      };

      const { data } = await updateCategory(action?.uuid, param);

      if (data && data?.success) {
        setAlert({ message: data?.message, type: "success" });
        setOpen(true);
        cancelForm();
      } else {
        console.log("[fail add category] : ", data.message || null);
      }
    } catch (error) {
      console.log("[err add category] : ", error);

      setAlert({ message: "Invalid input.", type: "error" });
      setOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // form validate
    const errors = {};
    if (!category_name) {
      errors.category_name = "Name is required";
    }

    // whenver errors occurs, set them in state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // submission
    if (isAddAction) {
      await addRecord();
    } else {
      await updateRecord();
    }
  };

  const cancelForm = async () => {
    onDataListChange(false);
    setCategoryName("");
    setErrors({});
    resetIcon();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ ml: 1, mt: 4, mb: 2 }} variant="h6" component="div">
            Category form
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div>
              <TextField
                fullWidth
                id="input-category-name"
                label="Category name"
                margin="normal"
                variant="filled"
                type="category_name"
                value={category_name}
                onChange={(e) => setCategoryName(e.target.value)}
                error={errors.category_name ? true : false}
                helperText={
                  errors.category_name
                    ? "Category name is required"
                    : "(Category name)"
                }
              />

              <IconPicker
                showIconPicker={showIconPicker}
                toggleIconPicker={() => setShowIconPicker(!showIconPicker)}
                iconDetails={[
                  {
                    family: "MaterialIcons",
                    icons: iconList || [],
                  },
                ]}
                content={
                  <Button variant="outlined" color="info">
                    <MaterialIcons
                      name={selectedIcon?.name}
                      size={30}
                      color={"white"}
                      marginRight={5}
                    />
                    Select icon
                  </Button>
                }
                onSelect={onSelectIcon}
              />
            </div>

            <Stack
              spacing={2}
              direction="row"
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={3}
            >
              <Button variant="outlined" onClick={cancelForm}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                {isAddAction ? "Add" : "Update"}
              </Button>
            </Stack>

            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                severity={alert?.type}
                sx={{ width: "100%" }}
              >
                {alert?.message || "Add successfully"}
              </Alert>
            </Snackbar>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FormCategory;
