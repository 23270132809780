import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// libs
import {
  Box,
  TextField,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const Selected = ({
  productData,
  setOpenDrawer,
  transactionNumber,
  resetItem,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      quantity: "",
    },
  });

  const prod = productData.map((i) => true);
  const [selected, setSelected] = useState(prod);
  const [items, setItems] = useState([]);

  const handleSelect = (e, index, data) => {
    const newSelected = [...items];
    newSelected[index] = !items[index];

    const newData = [...items];
    newData[index] = data;
    // if checked set is_returned to 1
    if (!Boolean(items[index]?.is_return)) {
      newData[index].is_return = 1;
      newData[index].product.is_return = 1;
      // setSelected(newSelected);
    } else {
      newData[index].is_return = 0;
      newData[index].product.is_return = 0;
      // setSelected(newSelected);
    }

    setItems(newData);
  };

  const handleContinue = async () => {
    productData.map((row, index) => (row.quantity = -row.quantity));
    localStorage.setItem("items", JSON.stringify(productData));
    localStorage.setItem(
      "transaction_no",
      transactionNumber.transaction_number
    );
    location.href = "/return_and_replace";
  };

  const [quantityMaxValue, setQuantityMaxValue] = useState(prod);

  useEffect(() => {
    const newQuantityMaxValue = [...quantityMaxValue];
    productData.map((row, index) => {
      newQuantityMaxValue[index] = row.quantity;
    });
    setQuantityMaxValue(newQuantityMaxValue);
  }, [productData]);

  const handleClose = () => {
    resetItem([]);
    setOpenDrawer(false);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = (e) => {
    resetItem([]);
    if (e.target.innerText == "Yes") {
      setOpenDrawer(false);
      handleContinue();
    }
    setOpenDialog(false);
    setOpenDrawer(false);
    resetItem([]);
  };

  return (
    <Box sx={{ padding: 1 }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Return to Stock</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productData &&
              productData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      backgroundColor: "#fff !important",
                      color: "#000",
                    }}
                    component="th"
                    scope="row"
                  >
                    {row.product.product_name}
                  </TableCell>

                  <TableCell
                    sx={{
                      backgroundColor: "#fff !important",
                      color: "#000 !important",
                    }}
                  >
                    <TextField
                      size="small"
                      variant="outlined"
                      value={row.quantity}
                      type="number"
                      inputProps={{ min: 1 }}
                      sx={{ input: { color: "#000" }, width: "70px" }}
                      onChange={(e) => {
                        if (e.target.value === "" || e.target.value === "0") {
                          e.target.value = "1";
                          e.target.select();
                        }

                        const newData = [...productData];
                        // check if quantity is greater than max value
                        if (e.target.value > quantityMaxValue[index]) {
                          e.target.value = "1";
                          e.target.select();
                        } else {
                          // set quantity
                          newData[index].quantity = e.target.value;
                          setSelected(newData);
                        }
                      }}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      backgroundColor: "#fff !important",
                      color: "#000",
                    }}
                  >
                    <Checkbox
                      value={row}
                      checked={Boolean(
                        items?.find((item) => item?.id === row?.id)
                          ?.is_return ?? false
                      )}
                      onChange={(e) => handleSelect(e, index, row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack
        spacing={1}
        direction="row"
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mt={5}
      >
        <Button onClick={() => handleClose()} variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => setOpenDialog(true)}
        >
          Continue
        </Button>
      </Stack>

      <Dialog
        open={openDialog}
        disableEscapeKeyDown
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Return Item(s)"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to return the selected item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            No
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleCloseDialog}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Selected;
