import apiRequest from "../ApiService";

const apiEndPoint = "reports/sales-report";
const dsrApiEndPoint = "reports/daily-sales-report";
const productApiEndPoint = "reports/products-sales-report";
// const exportEndPoint = "downloads/download_type=";

export const getSalesReports = async (param = "") => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    console.log(error);
  }
};

export const getProductSalesReport = async (param = "") => {
  try {
    return await apiRequest.get(`/${productApiEndPoint}` + param);
  } catch (error) {
    console.log(error);
  }
};

export const getDailySalesReport = async (param) => {
  try {
    return await apiRequest.get(`/${dsrApiEndPoint}` + param);
  } catch (error) {
    console.error(error);
  }
};

export const checkDsrTransactions = async (param) => {
  try {
    return await apiRequest.get(`/reports/check-dsr-transaction` + param);
  } catch (error) {
    console.error(error);
  }
};

export const generateDSR = async (param) => {
  try {
    return await apiRequest.post(`/reports/generate-dsr` + param);
  } catch (error) {
    console.error(error);
  }
};

export const getRecordedDSR = async (param) => {
  try {
    return await apiRequest.get(`/reports/view-dsr` + param);
  } catch (error) {
    console.error(error);
  }
};

export const exportProduct = async (
  date_start,
  date_end,
  product_keyword,
  customer_keyword
) => {
  try {
    return await apiRequest
      .post(
        `/downloads?download_type=product_sales&date_start=` +
          date_start +
          `&date_end=` +
          date_end +
          `&product_keyword=` +
          product_keyword +
          `&customer_keyword=` +
          customer_keyword
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  } catch (error) {
    console.log(error);
  }
};

export const exportTransactions = async (date_start, date_end) => {
  try {
    return await apiRequest
      .post(
        `/downloads?download_type=sales&date_start=` +
          date_start +
          `&date_end=` +
          date_end
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  } catch (error) {
    console.log(error);
  }
};
