import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Grid, Button, Typography, Snackbar, Box } from "@mui/material";
import { getPOSProducts } from "../../../services/PosService";

// global css
import AsyncStorage from "@react-native-async-storage/async-storage";
import SearchProduct from "../pos/components/SearchProduct";

// Sections
import Products from "./sections/Products";
import ItemList from "./sections/ItemList";
import { multipleStockIn } from "../../../services/StockService";
import { Alert } from "../../../components/common";
import StockInSkeleton from "./sections/StockInSkeleton";

export default function StockIn() {
  let cancelTokenSource;
  const tableRef = useRef(0);
  const [storeId, setStoreId] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [barcodeMode, setBarcodeMode] = useState(false);
  const [cart, setCart] = useState([]);
  const [selected, setSelected] = useState(null);
  const [products, setProducts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [snackOption, setSnackOption] = useState({
    message: "",
    variant: "success",
    isOpen: false,
  });
  const filter = 0;

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const loadMore = async () => {
    const newRows = Number(rowsPerPage) + 100;
    setRowsPerPage(newRows);
    getProductList(searchText, storeId, newRows, filter);
  };

  const subTotal = Number(
    cart.reduce((accumulator, object) => {
      return accumulator + object.price * object.quantity;
    }, 0)
  );

  const getProductList = async (word, storeId, rowsPerPage, filter) => {
    // Cancel the previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new search");
    }

    // Create a new CancelToken source
    cancelTokenSource = axios.CancelToken.source();

    return await getPOSProducts(
      word,
      storeId,
      rowsPerPage,
      filter,
      cancelTokenSource
    )
      .then((response) => {
        if (response?.data?.length === products?.length) {
          setHasNextPage(false);
        } else {
          setProducts(response?.data);
        }
        return response?.data;
      })
      .finally(() => {
        setFetching(false);
      });
  };
  const handlePressItem = (item) => {
    handleAddProduct(1, item);
  };

  // get products from api and set to products state
  useEffect(() => {
    const fetchProduct = async () => {
      const id = await AsyncStorage.getItem("store_id");
      getProductList("", id, rowsPerPage, filter);
      setStoreId(id);
    };
    fetchProduct();
  }, []);
  const handleAddProduct = (quantity, item) => {
    const selectedItem = item ? item : selected;
    const selectedFromCart = cart.find((row) => row.id === selectedItem.id);
    const tempCart = cart.filter((row) => row.id !== selectedItem.id);
    const accumulatedQuantity = selectedFromCart?.quantity
      ? (selectedFromCart.quantity += quantity)
      : quantity;
    const selectedItemIndex = products.findIndex(
      (row) => row.id === selectedItem.id
    );
    const tempProducts = products;
    if (!tempProducts[selectedItemIndex].orig_stock) {
      tempProducts[selectedItemIndex].orig_stock = selectedItem.available_stock;
    }
    setProducts(tempProducts);
    setCart([
      ...tempCart,
      {
        ...selectedItem,
        quantity: accumulatedQuantity,
      },
    ]);
    if (item) {
      setSearchText("");
    }

    if (tableRef.current) {
      setTimeout(() => {
        tableRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 100);
    }
  };

  const handleStockIn = async () => {
    let formData = new FormData();

    const products = [];
    cart.forEach((row) => {
      products.push({
        product_id: row.id,
        quantity: row.quantity,
        price: row.price,
        cost: row.summary.average_cost,
      });
    });

    formData.append("products", products);
    if (confirm("Are you sure you want to proceed?")) {
      try {
        const { data } = await multipleStockIn({ products: products });
        if (data) {
          setSnackOption({
            isOpen: true,
            message: data?.message,
            variant: "success",
          });
          setCart([]);
        } else {
          setSnackOption({
            isOpen: true,
            message: data?.message || "Stock In Failed",
            variant: "error",
          });
        }
      } catch (error) {
        console.error("Transaction Failed!");
      }
    }
  };
  const handleInactivePopup = () => {
    setSnackOption({
      isOpen: true,
      message: "Inactive Product!",
      variant: "warning",
    });
  };
  return fetching ? (
    <StockInSkeleton />
  ) : (
    <Grid container>
      <Grid item xs={12} md={5}>
        <SearchProduct
          storeId={storeId}
          rowsPerPage={rowsPerPage}
          getProductList={getProductList}
          searchText={searchText}
          setSearchText={setSearchText}
          filter={filter}
          setBarcodeMode={setBarcodeMode}
          barcodeMode={barcodeMode}
          handleAddProduct={handleAddProduct}
          setSelected={setSelected}
          products={products || []}
          handleInactivePopup={handleInactivePopup}
        />
        <Products
          cart={cart}
          products={products || []}
          handlePressItem={handlePressItem}
        />
        <hr />
        {products && products.length > 0 ? (
          hasNextPage ? (
            <Button onClick={loadMore} fullWidth>
              Load More
            </Button>
          ) : (
            <></>
          )
        ) : (
          <Typography mt={2} align="center">
            No Record Found
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          background: "#050F1A",
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ItemList
          cart={cart}
          setCart={setCart}
          setProducts={setProducts}
          products={products}
          tableRef={tableRef}
        />
        {cart.length && !cart.find((row) => row.quantity === "") ? (
          <Box display="flex" justifyContent="flex-end" padding={2}>
            <Button variant="contained" color="success" onClick={handleStockIn}>
              Save
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Grid>
      <Snackbar
        open={snackOption.isOpen}
        autoHideDuration={3000}
        onClose={() => {
          setSnackOption({ isOpen: false, message: "", variant: "success" });
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => {
            setSnackOption({ isOpen: false, message: "", variant: "success" });
          }}
          severity={snackOption.variant}
          sx={{ width: "100%" }}
        >
          {snackOption.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
