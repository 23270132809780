import React, { useEffect, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// components
import CustomDrawer from "../../../components/CustomDrawer";
import AddCustomer from "./AddCustomer";
import EditCustomer from "./EditCustomer";

// global css

// common
import { Alert } from "../../../components/common";

// icons
import stock_list from "../../../../assets/icons/stock_list.svg";
import SearchIcon from "@mui/icons-material/Search";

// services
import {
  getCustomers,
  getCustomerByID,
  customerTableColumns as columns,
} from "../../../services/CustomerService";

import { Visibility, Edit } from "@mui/icons-material";

// libs
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  Link,
  TableHead,
  TableRow,
  Box,
  Tab,
  Paper,
  Grid,
  Stack,
  TextField,
  Chip,
  InputAdornment,
  Snackbar,
  Backdrop,
  CircularProgress,
  TablePagination,
} from "@mui/material";

const ListCustomer = () => {
  const [customer, setCustomer] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [action, setAction] = useState("add");
  const [uuid, setUuid] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [openLoader, setOpenLoader] = useState(true);

  // all
  const [pagination, setPagination] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // pagination for All customer list
  const handleChangePage = (event, newPage) => {
    setOpenLoader(true);
    getCustomers(
      `?page=${
        newPage + 1
      }&size=${rowsPerPage}&filter[status]=1,3&sort=first_name`
    ).then((response) => {
      setCustomer(response?.data?.data);
      setPagination(response?.data?.pagination);
      setOpenLoader(false);
    });
    setPage(newPage);
  };

  // pagination rows per page
  const handleChangeRowsPerPage = (event) => {
    setOpenLoader(true);
    getCustomers(
      `?page=1&size=${+event.target.value}&filter[status]=1,3&sort=first_name`
    ).then((response) => {
      setCustomer(response?.data?.data);
      setPagination(response?.data?.pagination);
      setOpenLoader(false);
    });
    setPage(0);
    setRowsPerPage(+event.target.value);
  };

  // active
  const [paginationActive, setPaginationActive] = useState({});
  const [pageActive, setPageActive] = React.useState(0);
  const [rowsPerPageActive, setRowsPerPageActive] = React.useState(10);

  // pagination for active customer list
  const handleChangePageActive = (event, newPage) => {
    setOpenLoader(true);
    getCustomers(`?page=${newPage + 1}&filter[status]=1&sort=first_name`).then(
      (response) => {
        setCustomer(response?.data?.data);
        setPaginationActive(response?.data?.pagination);
        setOpenLoader(false);
      }
    );
    setPageActive(newPage);
  };

  // pagination rows per page active
  const handleChangeRowsPerPageActive = (event) => {
    setOpenLoader(true);
    getCustomers(
      `?page=1&size=${+event.target.value}&filter[status]=1&sort=first_name`
    ).then((response) => {
      setCustomer(response?.data?.data);
      setPaginationActive(response?.data?.pagination);
      setOpenLoader(false);
    });
    setPageActive(0);
    setRowsPerPageActive(+event.target.value);
  };

  // pagination for Archive customer list
  const [paginationArchive, setPaginationArchive] = useState({});
  const [pageArchive, setPageArchive] = React.useState(0);
  const [rowsPerPageArchive, setRowsPerPageArchive] = React.useState(10);
  const handleChangePageArchive = (event, newPage) => {
    setOpenLoader(true);
    getCustomers(`?page=${newPage + 1}&filter[status]=3&sort=first_name`).then(
      (response) => {
        setCustomer(response?.data?.data);
        setPaginationArchive(response?.data?.pagination);
        setOpenLoader(false);
      }
    );
    setPageArchive(newPage);
  };

  // pagination rows per page
  const handleChangeRowsPerPageArchive = (event) => {
    setOpenLoader(true);
    getCustomers(
      `?page=1&size=${+event.target.value}&filter[status]=3&sort=first_name`
    ).then((response) => {
      setCustomer(response?.data?.data);
      setPaginationArchive(response?.data?.pagination);
      setOpenLoader(false);
    });
    setPageArchive(0);
    setRowsPerPageArchive(+event.target.value);
  };

  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");

  // sorting function
  const [orderDirection, setOrderDirection] = React.useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = React.useState();

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  // sorting

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const clickTableRow = async (uuid) => {
    setCustomerData(null);
    if (uuid) {
      const response = await getCustomerByID(uuid);
      if (response && response?.data?.data) {
        setUuid(uuid);
        setCustomerData(response.data.data);
        setOpenDrawer(true);
        setAction("edit");
      }
    }
  };

  // navigation change tab
  const [value, setValue] = React.useState("0");
  const handleChangeTab = async (e, newValue) => {
    if (newValue == 0) {
      getCustomerList();
    } else {
      const customerList = await getCustomers(
        "?page=1&filter[status]=" + newValue
      );
      if (customerList && customerList?.data?.data) {
        setCustomer(customerList?.data?.data);
        setPagination(customerList?.data?.pagination);
        setPaginationActive(customerList?.data?.pagination);
        setPaginationArchive(customerList?.data?.pagination);
      }
    }
    setValue(newValue);
  };

  // get customers from api and set to customers state
  useEffect(() => {
    getCustomerList();
  }, []);

  const getCustomerList = () => {
    getCustomers("?page=1&filter[status]=1,3&sort=first_name").then((resp) => {
      setCustomer(resp?.data?.data);
      setPagination(resp?.data?.pagination);
      setPaginationActive(resp?.data?.pagination);
      setOpenLoader(false);
    });
  };

  // filter customer
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const searchCustomer = async (e) => {
    const param = e.target.value || "";
    const customerList = await getCustomers(
      "?sort=first_name&keyword=" + param
    );

    try {
      const data = customerList && customerList?.data?.data;
      if (data) {
        setCustomer(customerList?.data?.data);
        setPagination(customerList?.data?.pagination);
      } else {
        setErrMsg("No data found.");
      }
    } catch (error) {
      setErrMsg("No data found.");
    } finally {
      setOpenSnack(false);
    }
  };

  return (
    <>
      <Grid container sx={{ mt: 0, mb: 0 }}>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <TextField
            inputProps={{
              autoComplete: "none",
            }}
            onChange={(e) => {
              searchCustomer(e);
            }}
            placeholder="Search Customer"
            label="Search Customer"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ color: "white" }}
                ></InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "white" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: 0, mb: 0 }}
          >
            <CustomDrawer
              clickTableRow={clickTableRow}
              setOpenDrawer={setOpenDrawer}
              openDrawer={openDrawer}
              setAction={setAction}
              action={action}
            >
              {action === "add" && (
                <AddCustomer
                  setOpenDrawer={setOpenDrawer}
                  getCustomerList={getCustomerList}
                  setOpenSnack={setOpenSnack}
                  setSnackVariant={setSnackVariant}
                  setErrMsg={setErrMsg}
                />
              )}
              {action === "edit" && customerData && (
                <EditCustomer
                  customerData={customerData}
                  uuid={uuid}
                  setOpenDrawer={setOpenDrawer}
                  getCustomerList={getCustomerList}
                  setOpenSnack={setOpenSnack}
                  setSnackVariant={setSnackVariant}
                  setErrMsg={setErrMsg}
                />
              )}
            </CustomDrawer>
          </Grid>
        </Grid>
      </Grid>

      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChangeTab}
            aria-label="lab API tabs example"
            centered
            variant="fullWidth"
          >
            <Tab
              icon={<img src={stock_list} />}
              iconPosition="start"
              label="All"
              value="0"
            />
            <Tab
              icon={<img src={stock_list} />}
              iconPosition="start"
              label="Active"
              value="1"
            />
            <Tab
              icon={<img src={stock_list} />}
              iconPosition="start"
              label="Archived"
              value="3"
            />
          </TabList>
        </Box>

        {/* All Customer */}
        <TabPanel value="0">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customer && customer.length > 0 ? (
                    customer.length > 0 &&
                    customer.map((post, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          onClick={() => clickTableRow(post.uuid)}
                        >
                          <TableCell width={"20%"}>
                            {post.first_name || ""} {post.last_name || ""}
                          </TableCell>
                          <TableCell width={"20%"}>
                            {post?.city_name &&
                            post?.city_name &&
                            post.country_code
                              ? post?.city_name +
                                ", " +
                                post?.region_name +
                                ", " +
                                post.country_code
                              : "-"}
                          </TableCell>
                          <TableCell width={"15%"}>{post.orders}</TableCell>
                          <TableCell width={"15%"}>
                            {post.total_spent}
                          </TableCell>
                          <TableCell width={"15%"}>
                            {post.outstanding_balance}
                          </TableCell>
                          <TableCell width={"15%"}>
                            {
                              <Chip
                                sx={{ borderRadius: "0px", height: "auto" }}
                                label={post.status_name}
                                color={
                                  post.status_name == "Active"
                                    ? "success"
                                    : "default"
                                }
                                variant={
                                  post.status_name == "Active"
                                    ? "filled"
                                    : "outlined"
                                }
                              />
                            }
                          </TableCell>
                          <TableCell width={"10%"}>
                            <Link
                              href={`/customer_profile/${post.uuid}`}
                              target="_blank"
                            >
                              <IconButton>
                                <Visibility />
                              </IconButton>
                            </Link>

                            <IconButton
                              sx={{ padding: "0px" }}
                              onClick={() => clickTableRow(post.uuid, "remove")}
                            >
                              <Edit />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} sx={{ textAlignLast: "center" }}>
                        No record found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Grid container>
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            ></Grid>
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Stack spacing={2}>
                <TablePagination
                  component="div"
                  count={pagination.total ? pagination.total : 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Stack>
            </Grid>
          </Grid>
          <Snackbar
            open={openSnack}
            autoHideDuration={3000}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity={snackVariant}
              sx={{ width: "100%" }}
            >
              {errMsg}
            </Alert>
          </Snackbar>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </TabPanel>
        {/* All Customer */}

        {/* Active Customer */}
        <TabPanel value="1">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customer && customer.length > 0 ? (
                    customer.length > 0 &&
                    customer.map((post, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          onClick={() => clickTableRow(post.uuid)}
                        >
                          <TableCell width={"20%"}>
                            {post.first_name || ""} {post.last_name || ""}
                          </TableCell>
                          <TableCell width={"20%"}>
                            {post?.province_name &&
                            post?.province_name &&
                            post.country_code
                              ? post?.province_name +
                                ", " +
                                post?.region_name +
                                ", " +
                                post.country_code
                              : "-"}
                          </TableCell>
                          <TableCell width={"15%"}>{post.orders}</TableCell>
                          <TableCell width={"15%"}>
                            {post.total_spent}
                          </TableCell>
                          <TableCell width={"15%"}>
                            {post.outstanding_balance}
                          </TableCell>
                          <TableCell width={"15%"}>
                            {
                              <Chip
                                sx={{ borderRadius: "0px", height: "auto" }}
                                label={post.status_name}
                                color={
                                  post.status_name == "Active"
                                    ? "success"
                                    : "default"
                                }
                                variant={
                                  post.status_name == "Active"
                                    ? "filled"
                                    : "outlined"
                                }
                              />
                            }
                          </TableCell>
                          <TableCell width={"10%"}>
                            <Link
                              href={`/customer_profile/${post.uuid}`}
                              target="_blank"
                            >
                              <IconButton>
                                <Visibility />
                              </IconButton>
                            </Link>

                            <IconButton
                              sx={{ padding: "0px" }}
                              onClick={() => clickTableRow(post.uuid, "remove")}
                            >
                              <Edit />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} sx={{ textAlignLast: "center" }}>
                        No record found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Grid container>
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            ></Grid>
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Stack spacing={2}>
                <TablePagination
                  component="div"
                  count={paginationActive.total ? paginationActive.total : 0}
                  page={pageActive}
                  onPageChange={handleChangePageActive}
                  rowsPerPage={rowsPerPageActive}
                  onRowsPerPageChange={handleChangeRowsPerPageActive}
                />
              </Stack>
            </Grid>
          </Grid>
          <Snackbar
            open={openSnack}
            autoHideDuration={3000}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity={snackVariant}
              sx={{ width: "100%" }}
            >
              {errMsg}
            </Alert>
          </Snackbar>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </TabPanel>
        {/* Active Customer */}

        {/* Archive Customer */}
        <TabPanel value="3">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customer && customer.length > 0 ? (
                    customer.length > 0 &&
                    customer.map((post, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          onClick={() => clickTableRow(post.uuid)}
                        >
                          <TableCell width={"20%"}>
                            {post.first_name || ""} {post.last_name || ""}
                          </TableCell>
                          <TableCell width={"20%"}>
                            {post?.province_name &&
                            post?.province_name &&
                            post.country_code
                              ? post?.province_name +
                                ", " +
                                post?.region_name +
                                ", " +
                                post.country_code
                              : "-"}
                          </TableCell>
                          <TableCell width={"15%"}>{post.orders}</TableCell>
                          <TableCell width={"15%"}>
                            {post.total_spent}
                          </TableCell>
                          <TableCell width={"15%"}>
                            {post.outstanding_balance}
                          </TableCell>
                          <TableCell width={"15%"}>
                            {
                              <Chip
                                sx={{ borderRadius: "0px", height: "auto" }}
                                label={post.status_name}
                                color={
                                  post.status_name == "Active"
                                    ? "success"
                                    : "default"
                                }
                                variant={
                                  post.status_name == "Active"
                                    ? "filled"
                                    : "outlined"
                                }
                              />
                            }
                          </TableCell>
                          <TableCell width={"10%"}>
                            <Link
                              href={`/viewcustomer/${post.uuid}`}
                              target="_blank"
                            >
                              <IconButton>
                                <Visibility />
                              </IconButton>
                            </Link>

                            <IconButton
                              sx={{ padding: "0px" }}
                              onClick={() => clickTableRow(post.uuid, "remove")}
                            >
                              <Edit />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} sx={{ textAlignLast: "center" }}>
                        No record found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Grid container>
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            ></Grid>
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Stack spacing={2}>
                <TablePagination
                  component="div"
                  count={paginationArchive.total ? paginationArchive.total : 0}
                  page={pageArchive}
                  onPageChange={handleChangePageArchive}
                  rowsPerPage={rowsPerPageArchive}
                  onRowsPerPageChange={handleChangeRowsPerPageArchive}
                />
              </Stack>
            </Grid>
          </Grid>
          <Snackbar
            open={openSnack}
            autoHideDuration={3000}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity={snackVariant}
              sx={{ width: "100%" }}
            >
              {errMsg}
            </Alert>
          </Snackbar>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </TabPanel>
        {/* Archive Customer */}
      </TabContext>
    </>
  );
};

export default ListCustomer;
