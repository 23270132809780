import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

// global css
import GlobalStyles from "../../styles/GlobalStyles";

// assets
import LoginImage from "../../../assets/images/login-bg.png";
import key from "../../../assets/icons/key.svg";

// libs
import {
  CssBaseline,
  TextField,
  Box,
  Grid,
  Typography,
  Snackbar,
  Link,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  TaskAlt as TaskAltIcon,
  ReportGmailerrorred as ReportGmailerrorredIcon,
} from "@mui/icons-material";

import { Logo, Alert } from "../../components/common";

// services
import {
  checkResetPassword,
  confirmResetPassword,
} from "../../services/AuthService";

const ConfirmReset = () => {
  const [openSnack, setOpenSnack] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [snackVariant, setSnackVariant] = useState("success");
  const navigate = useNavigate();
  const location = useLocation();
  const uuid = location.pathname.split("/")[2];
  const time = location.pathname.split("/")[3];
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });
  const [isSent, setIsSent] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [openLoader, setOpenLoader] = useState(true);

  useEffect(() => {
    setOpenLoader(true);
    checkResetPassword(uuid, time)
      .then(function (rs) {
        if (rs.data.success == "0") {
          setIsSent(true);
          setIsExpired(true);
        } else {
          setIsSent(false);
          setIsExpired(false);
        }
        setOpenLoader(false);
      })
      .catch(function (error) {
        // setIsSent(true);
        // setIsExpired(true);
      });
  }, []);

  // submit form confirm reset password
  const onSubmit = (data) => {
    setOpenLoader(true);
    confirmResetPassword(data, uuid, time)
      .then(function (rs) {
        setIsSent(true);
        setOpenLoader(false);
      })
      .catch(function (error) {
        const dataMessage = error.response.data.message.password;
        const dataErrors = error.response.data.errors;
        for (const errorsKey in dataErrors) {
          if (!dataErrors.hasOwnProperty(errorsKey)) continue;
          dataMessage += "\r\n" + dataErrors[errorsKey];
        }
        setErrMsg(dataMessage);
        setOpenSnack(true);
        setSnackVariant("error");
        setIsSent(false);
      });
  };

  // close snackbar
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  if (openLoader == true) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Grid sx={{ backgroundColor: "#12355B" }}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "dark"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} elevation={6} square="true">
          <Box
            sx={{
              my: 8,
              mx: 4,
              padding: 7,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Logo />
            <Box sx={{ mt: 8, textAlign: "left" }}>
              {!isSent ? (
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Typography variant="h5" sx={{ mb: 3 }}>
                    <Box
                      component="img"
                      sx={{
                        height: "25px",
                        width: "25px",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: 2,
                      }}
                      src={key}
                    />
                    Set new password
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    mb=""
                    sx={{ lineHeight: "18.75px" }}
                  >
                    Your new password must be different to previously used
                    passwords.
                  </Typography>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Must be 8 characters",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        id="password"
                        label="New password"
                        type="password"
                        name="password"
                        autoFocus
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        error={!!errors.password}
                        helperText={
                          errors.password
                            ? errors.password.message
                            : "(Must be at least 8 characters)"
                        }
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="password_confirmation"
                    control={control}
                    rules={{
                      required: "Confirm password is required",
                      minLength: {
                        value: 8,
                        message: "Must be 8 characters",
                      },
                      validate: (value) =>
                        value === watch("password") ||
                        "The passwords do not match",
                    }}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        id="password_confirmation"
                        label="Confirm password"
                        type="password"
                        name="password_confirmation"
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        error={!!errors.password_confirmation}
                        helperText={
                          errors.password_confirmation?.message
                            ? errors.password_confirmation?.message
                            : errors.password_confirmation
                        }
                        {...field}
                      />
                    )}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reset Password
                  </Button>

                  <Link
                    sx={{ color: GlobalStyles.white, display: "flex", mt: 2 }}
                    alignItems="center"
                    justifyContent="center"
                    href="/login"
                  >
                    <ArrowBackIcon />
                    <Typography alignItems="center" variant="body2" ml="5px">
                      Back to login
                    </Typography>
                  </Link>
                </Box>
              ) : (
                <Box>
                  {!isExpired ? (
                    <Box>
                      <Typography
                        variant="h7"
                        alignItems="center"
                        sx={{ mb: 3 }}
                        display="flex"
                      >
                        <TaskAltIcon />
                        <Typography variant="h5" ml={2}>
                          Password reset
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: "18.75px" }}
                      >
                        Your password has been successfully reset. Click below
                        to log in magically.
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="h7" sx={{ mb: 3 }} display="flex">
                        <ReportGmailerrorredIcon />
                        <Typography variant="h5" ml={2}>
                          Request expired
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: "18.75px" }}
                      >
                        Request was expired, please request a new one
                      </Typography>
                    </Box>
                  )}

                  <Button
                    component={Link}
                    href="/login"
                    type="button"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 4, mb: 2 }}
                  >
                    Back to login
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default ConfirmReset;
