export const formatPrice = (price) => {
  let formattedPrice = "₱0.00";
  if (price) {
    const formatter = new Intl.NumberFormat("en-PH", {
      style: "currency",
      currency: "PHP",
    });
    formattedPrice = formatter.format(price);
  }

  return formattedPrice;
};
