import React, { useEffect, useState } from "react";

// global css

// svg icons
import stock_in from "./../../../../assets/icons/stock_in.svg";
import stock_out from "./../../../../assets/icons/stock_out.svg";
import stock_list from "./../../../../assets/icons/stock_list.svg";

// libs
import {
  InputAdornment,
  Box,
  Grid,
  TextField,
  Menu,
  MenuItem,
  Tab,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Pagination,
  IconButton,
  Snackbar,
  Backdrop,
  CircularProgress,
  TablePagination,
  Button,
} from "@mui/material";
// import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Search as SearchIcon } from "@mui/icons-material";
import { Alert } from "../../../components/common";
import Modal from "@mui/material/Modal";

import { useNavigate } from "react-router-dom";
// services
import {
  stockTableColumns as columns,
  getStocks,
  getStocksIn,
  getStocksOut,
  downloadInventoryCSV,
} from "../../../services/StockService";
import { getProductsByID } from "../../../services/ProductService";
import * as DocumentPicker from "expo-document-picker";
import { uploadCSV } from "../../../services/UploadService";

// components
import CustomDrawer from "../../../components/CustomDrawer";
import AddStocks from "./AddStocks";
import RemoveStocks from "./RemoveStocks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#050F1A",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
};

const ListStocks = () => {
  const stockInRowsPerPage = 10;
  const stockOutRowsPerPage = 10;

  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [stockInPage, setStockInPage] = React.useState(1);
  const [stockOutPage, setStockOutPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pagination, setPagination] = useState({});

  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");
  const [openLoader, setOpenLoader] = useState(true);

  const [openModal, setOpenModal] = React.useState(false);
  const handleCloseModal = () => setOpenModal(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  // tabs
  // const [value, setValue] = useState("1");
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // get stocks from api and set to stocks state
  const [stocks, setStocks] = useState([]);
  // const [stocksIn, setStocksIn] = useState([]);
  // const [stocksOut, setStocksOut] = useState([]);

  const getStockList = () => {
    getStocks(`/stock?page=${page + 1}`).then((response) => {
      setStocks(response.data.data);
      setPagination(response.data.pagination);
      setOpenLoader(false);
    });
    // getStocksIn().then((response) => {
    //   setStocksIn(response.data.data);
    // });
    // getStocksOut().then((response) => {
    //   setStocksOut(response.data.data);
    // });
  };

  // pagination
  const handleChangePage = (event, newPage) => {
    // switch (value) {
    //   case "1":
    setOpenLoader(true);
    getStocks(`/stock?page=${newPage + 1}&size=10`).then((response) => {
      setStocks(response.data.data);
      setPagination(response.data.pagination);
      setOpenLoader(false);
    });
    setPage(newPage);
    //     break;
    //   case "2":
    //     setStockInPage(newPage);
    //     break;
    //   case "3":
    //     setStockOutPage(newPage);
    //     break;
    //   default:
    //     break;
    // }
  };

  // pagination rows per page
  const handleChangeRowsPerPage = (event) => {
    setOpenLoader(true);
    getStocks(`/stock?page=1&size=${+event.target.value}`).then((response) => {
      setStocks(response?.data?.data);
      setPagination(response?.data?.pagination);
      setOpenLoader(false);
    });
    setPage(0);
    setRowsPerPage(+event.target.value);
  };

  // drawer
  const [openDrawer, setOpenDrawer] = useState(false);
  const [action, setAction] = useState("add");
  const [productData, setProductData] = useState([]);
  const [uuid, setUuid] = useState("");

  const handleStockActions = async (uuid, type) => {
    if (uuid) {
      const response = await getProductsByID(uuid);
      if (response && response?.data?.data) {
        setUuid(uuid);
        setProductData(response.data.data);
        setOpenDrawer(true);
        setAction(type);
      }
    }
  };

  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    const getDataAsync = setTimeout(async () => {
      const getStocksAsync = async (tab) => {
        let stockList = [];
        let stockHandler;
        let setStocksFunc;
        let setFilter;

        switch (tab) {
          case "1":
            stockHandler = getStocks;
            setStocksFunc = setStocks;
            if (searchValue) {
              setFilter = `/search?keyword=${searchValue}&page=${
                page + 1
              }&size=10`;
            } else {
              setFilter = `/stock?page=${page + 1}&size=10`;
            }
            break;
          // case "2":
          //   stockHandler = getStocksIn;
          //   setStocksFunc = setStocksIn;
          //   if (searchValue) {
          //     setFilter = `?keyword=${searchValue}`;
          //   } else {
          //     setFilter = "";
          //   }
          //   break;
          // case "3":
          //   stockHandler = getStocksOut;
          //   setStocksFunc = setStocksOut;
          //   if (searchValue) {
          //     setFilter = `?keyword=${searchValue}`;
          //   } else {
          //     setFilter = "";
          //   }
          //   break;
          default:
            break;
        }

        if (stockHandler) {
          // if (searchValue) {
          setOpenLoader(true);
          // }
          stockList = await stockHandler(setFilter);
          if (stockList?.data?.data) {
            setStocksFunc(stockList?.data?.data);
            setPagination(stockList?.data?.pagination);
          }
        }
        setOpenLoader(false);
      };
      getStocksAsync("1");
    }, 1000);

    return () => clearTimeout(getDataAsync);
  }, [searchValue]);

  // download csv
  const downloadCSV = async () => {
    handleCloseModal();
    setOpenLoader(true);
    let param = "";
    param = `?download_type=product`;
    const resp = await downloadInventoryCSV(param);
    if (resp?.data?.success === 1) {
      setSnackVariant("success");
      setErrMsg(resp?.data?.message);
      setOpenSnack(true);
      setOpenLoader(false);
    } else {
      setSnackVariant("error");
      setErrMsg(resp?.data?.message);
      setOpenSnack(true);
      setOpenLoader(false);
    }
  };

  // import csv
  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "text/csv",
      multiple: false,
      copyToCacheDirectory: false,
    });

    if (result.canceled) {
      return;
    }
    try {
      if (
        result.type === "success" &&
        result.name.split(".").pop().toLowerCase() === "csv"
      ) {
        let formData = new FormData();
        formData.append("file", result.file);
        uploadCSV(formData);

        setTimeout(() => {
          navigate("/import_history");
        }, 2000);
        setSnackVariant("success");
        setErrMsg("Processing import of inventory...");
        setOpenSnack(true);
        setOpenLoader(false);
        handleCloseModal();
        // if (resp?.data?.success === 1) {
        //   setSnackVariant("success");
        //   setErrMsg(resp?.data?.message);
        //   setOpenSnack(true);
        //   setOpenLoader(false);
        //   handleCloseModal();
        //   getStockList();
        // } else {
        //   setSnackVariant("error");
        //   setErrMsg(resp?.message);
        //   setOpenSnack(true);
        //   setOpenLoader(false);
        // }
      }
    } catch (e) {
      setSnackVariant("error");
      setErrMsg(e?.response?.data?.message || "An error occurred");
      setOpenSnack(true);
      setOpenLoader(false);
      handleCloseModal();
    }
  };
  const downloadStockCSV = (url) => {
    window.open(
      "https://s3.ap-southeast-1.amazonaws.com/asenso.drive/asenso_inventory_template.csv",
      "_blank",
      "noopener,noreferrer"
    );
    handleCloseModal();
  };
  return (
    <Box container>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <TextField
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder="Search Products"
            label="Search Products"
            variant="outlined"
            InputProps={{
              // startAdornment: (
              //   <InputAdornment position='start' sx={{ color: 'white' }}>
              //     <SearchIcon />
              //   </InputAdornment>
              // ),
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "white" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
              <Button
                variant="contained"
                color="success"
                onClick={() => setOpenModal(true)}
              >
                Import Inventory
              </Button>

              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  navigate("/stock_in");
                }}
              >
                Multiple Stock IN
              </Button>
            </Stack>
          </Box>
          {/* <TuneIcon /> */}
        </Box>
      </Box>

      <Box>
        {/* <TabContext value={value}> */}
        {/* <Box hidden sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={() => {}} centered variant="fullWidth">
              <Tab
                icon={<img src={stock_list} />}
                iconPosition="start"
                label="Stock List"
                value="1"
              />
              <Tab
                icon={<img src={stock_in} />}
                iconPosition="start"
                label="Stock In History"
                value="2"
              />
              <Tab
                icon={<img src={stock_out} />}
                iconPosition="start"
                label="Stock Out History"
                value="3"
              />
            </TabList>
          </Box> */}
        {/* <TabPanel value="1"> */}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 480 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.stock_list.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stocks && stocks.length > 0 ? (
                  stocks.length > 0 &&
                  stocks.map((stock, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>{stock?.product_code}</TableCell>
                        <TableCell>{stock?.product_name}</TableCell>
                        <TableCell>{stock?.summary?.available_stock}</TableCell>
                        <TableCell>
                          {Number(stock?.price).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell>
                          {Number(stock?.summary?.average_cost).toLocaleString(
                            "en",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                        <TableCell>
                          {Number(
                            stock?.summary?.available_stock * stock?.price
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                        {/* <TableCell width={"15%"}>
                                {
                                  <Chip
                                    sx={{
                                      borderRadius: "0px",
                                      height: "auto",
                                    }}
                                    label={stock?.stock_status}
                                    color={
                                      stock?.stock_status == stock?.stock_status
                                        ? "success"
                                        : "default"
                                    }
                                  />
                                }
                              </TableCell> */}
                        <TableCell>
                          <IconButton
                            sx={{ padding: "0px" }}
                            onClick={() =>
                              handleStockActions(stock.uuid, "add")
                            }
                          >
                            <img src={stock_in} />
                          </IconButton>
                          <IconButton
                            sx={{ padding: "0px" }}
                            onClick={() =>
                              handleStockActions(stock.uuid, "remove")
                            }
                          >
                            <img src={stock_out} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} sx={{ textAlignLast: "center" }}>
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Grid container>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            {/* <Typography>
                  Showing {page ? (page - 1) * rowsPerPage + 1 : 0} -{' '}
                  {page
                    ? page * rowsPerPage > pagination.total
                      ? pagination.total
                      : page * rowsPerPage
                    : 0}{' '}
                  of {pagination.total ? pagination.total : 0} matches{' '}
                </Typography> */}
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Stack spacing={2}>
              {/* <Pagination
                    count={pagination.total ? Math.ceil(pagination.total / rowsPerPage) : 0}
                    page={page}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  /> */}
              <TablePagination
                component="div"
                count={pagination.total ? pagination.total : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Grid>
        </Grid>
        {/* </TabPanel> */}
        {/* <TabPanel hidden value="2">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.stock_in.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stocksIn && stocksIn.length > 0 ? (
                      stocksIn.length > 0 &&
                      stocksIn
                        .slice(
                          (stockInPage - 1) * stockInRowsPerPage,
                          stockInPage * stockInRowsPerPage
                        )
                        .map((stock_in, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              // onClick={() => clickTableRow(stock.uuid)}
                            >
                              <TableCell>{stock_in?.created_at}</TableCell>
                              <TableCell>
                                {stock_in?.product?.product_code}
                              </TableCell>
                              <TableCell>
                                {stock_in?.product?.product_name}
                              </TableCell>
                              <TableCell>{stock_in?.quantity}</TableCell>
                              <TableCell>{stock_in?.cost}</TableCell>
                              <TableCell>{stock_in?.remarks}</TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} sx={{ textAlignLast: "center" }}>
                          No record found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  Showing{" "}
                  {stockInPage ? (stockInPage - 1) * stockInRowsPerPage + 1 : 0}{" "}
                  -{" "}
                  {stockInPage
                    ? stockInPage * stockInRowsPerPage > stocksIn.length
                      ? stocksIn.length
                      : stockInPage * stockInRowsPerPage
                    : 0}{" "}
                  of {stocksIn.length ? stocksIn.length : 0} matches{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Stack spacing={2}>
                  <Pagination
                    count={
                      stocksIn.length < stockInRowsPerPage
                        ? 1
                        : Math.ceil(stocksIn.length / stockInRowsPerPage)
                    }
                    page={stockInPage}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </Grid>
            </Grid>
          </TabPanel> */}
        {/* <TabPanel hidden value="3">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.stock_out.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stocksOut && stocksOut.length > 0 ? (
                      stocksOut.length > 0 &&
                      stocksOut
                        .slice(
                          (stockOutPage - 1) * stockOutRowsPerPage,
                          stockOutPage * stockOutRowsPerPage
                        )
                        .map((stock_out, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              // onClick={() => clickTableRow(stock.uuid)}
                            >
                              <TableCell>{stock_out?.created_at}</TableCell>
                              <TableCell>
                                {stock_out?.product?.product_code}
                              </TableCell>
                              <TableCell>
                                {stock_out?.product?.product_name}
                              </TableCell>
                              <TableCell>{stock_out?.quantity}</TableCell>
                              <TableCell>{stock_out?.remarks}</TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} sx={{ textAlignLast: "center" }}>
                          No record found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid container>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  Showing{" "}
                  {stockOutPage
                    ? (stockOutPage - 1) * stockOutRowsPerPage + 1
                    : 0}{" "}
                  -{" "}
                  {stockOutPage
                    ? stockOutPage * stockOutRowsPerPage > stocksOut.length
                      ? stocksOut.length
                      : stockOutPage * stockOutRowsPerPage
                    : 0}{" "}
                  of {stocksOut.length ? stocksOut.length : 0} matches{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Stack spacing={2}>
                  <Pagination
                    count={
                      stocksOut.length < stockOutRowsPerPage
                        ? 1
                        : Math.ceil(stocksOut.length / stockOutRowsPerPage)
                    }
                    page={stockOutPage}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </Grid>
            </Grid>
          </TabPanel> */}
        {/* </TabContext> */}
      </Box>

      <Grid
        item
        xs={6}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <CustomDrawer
          // clickTableRow={clickTableRow}
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          setAction={setAction}
          action={action}
          showAddButton={false}
        >
          {action === "add" && (
            <AddStocks
              productData={productData}
              setOpenDrawer={setOpenDrawer}
              getStockList={getStockList}
              setOpenSnack={setOpenSnack}
              setSnackVariant={setSnackVariant}
              setErrMsg={setErrMsg}
            />
          )}
          {action === "remove" && (
            <RemoveStocks
              productData={productData}
              setOpenDrawer={setOpenDrawer}
              getStockList={getStockList}
              setOpenSnack={setOpenSnack}
              setSnackVariant={setSnackVariant}
              setErrMsg={setErrMsg}
            />
          )}
        </CustomDrawer>
      </Grid>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* modal  */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            fontWeight="bold"
            component="h2"
            textAlign="center"
          >
            Import CSV
          </Typography>

          <Typography id="modal-modal-description" sx={{ my: 2 }}>
            1. Download the CSV template file.
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={() => downloadStockCSV()}
          >
            Download Template
          </Button>
          <Typography id="modal-modal-description" sx={{ my: 2 }}>
            2. Import the filled CSV file.
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={() => pickFile()}
          >
            Import CSV
          </Button>
          <br />
          <br />
          <hr />
          <br />
          <Button
            fullWidth
            variant="contained"
            color="info"
            onClick={() => downloadCSV()}
          >
            Export Current Inventory
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ListStocks;
