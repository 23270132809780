import React, { useEffect, useState } from 'react';

// libs
import { Grid } from '@mui/material';

// sub component
import ListBrands from './ListBrands';
import FormBrand from './FormBrand';

const MainBrand = () => {
  const [renderFlag, setRenderFlag] = useState('');
  const [action, setAction] = useState({});

  const handleDataChange = (newRenderIndicator) => {
    setRenderFlag(newRenderIndicator);
  };

  const handleActionChange = (newActionIndicator) => {
    setAction(newActionIndicator);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} display='flex'>
          <FormBrand onDataListChange={handleDataChange} action={action} />
        </Grid>

        <Grid item xs={6} display='flex'>
          <ListBrands
            renderFlag={renderFlag}
            onActionChange={handleActionChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MainBrand;
