import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import GlobalStyles from '../styles/GlobalStyles';
import shadows from '@mui/material/styles/shadows';
const themeLight = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#000000',
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#EFEFEF',
          color: '#000000',
        },
      },
    },
  },
});

const themeDark = createTheme({
  palette: {
    background: {
      default: '#0A1E33',
    },
    text: {
      primary: '#FFFFFF',
      disabled: '#fff',
    },
  },
  components: {
    MuiNativeSelect: {
      '&.option': {
        color: '#000',
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          MuiTableSortLabel: {
            styleOverrides: {
              root: {
                '&.hover': {
                  color: '#fff!important',
                },
              },
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          MuiTableSortLabel: {
            styleOverrides: {
              root: {
                '&.MuiTableSortLabel-icon': {
                  color: '#fff !important',
                },
              },
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#194b80',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: '#309737',
            borderRadius: '25px',
          },

          '&.Mui-selected:hover': {
            backgroundColor: '#309737',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          backgroundColor: '#0A1E33',
        },
        dense: {
          height: '100px',
          backgroundColor: 'none',
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          color: '#fff',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#fff',
          '&.Mui-disabled': {
            color: '#fff',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          square: 'false',
        },
        root: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&.MuiDrawer-paper': {
            backgroundColor: '#12355B',
          },
          backgroundColor: '#12355B',
        },
      },
    },

    MuiInputBase: {
      // material input
      styleOverrides: {
        root: {
          '&.MuiFilledInput-root:before': {
            color: '#fff',
            borderBottom: '1px solid rgb(255 255 255)',
          },
          '&.MuiFilledInput-input': {
            backgroundColor: '#0A294A',
          },
          '&.MuiFilledInput-root': {
            backgroundColor: '#0A294A',
          },
          '&.MuiInputBase-input': {
            '&.MuiFilledInput-input': {
              '&.Mui-disabled': {
                color: '#fff',
              },
            },
          },
          '.Mui-disabled': {
            backgroundColor: '#999999',
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              backgroundColor: '#0A294A',
              color: '000',
            },
          },
        },
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            color: 'rgb(255 255 255)',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: '#0A1E33',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderTop: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: '#0F2D4D',
          },
          '&:nth-of-type(odd)': {
            backgroundColor: '#02101F',
          },
          '&:hover': {
            color: '#fff!important',
          },
        },
      },
    },
    // material select dropdown
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSelect-icon': {
            color: '#fff',
          },
          '&.MuiTableSortLabel-icon': {
            color: '#fff',
          },
        },
      },
    },
    //material select options
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#12355B!important',
          margin: '0 5px 0 5px',
          '&.MuiMenu-list': {
            backgroundColor: '#0A1E33',
          },
        },
      },
    },

    //material tabs
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#309737 !important',
          height: '3px',
        },
        flexContainer: {
          height: '60px',
          marginTop: '-5px',
        },
        root: {
          marginTop: '24px',
          backgroundColor: '#1C1B1F',
          '&.indicator': {
            backgroundColor: '#cac4d0 !important',
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          color: '#cac4d0 !important',
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#309737',
            },
          },
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          '&.css-view-175oi2r': {
            '&.rti--container': {
              '&.rti--tag': {
                backgroundColor: '#28629c',
              },
            },
          },
        },
      },
    },

    // button for pagination
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#309737! important',
          },
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          MuiTableSortLabel: {
            styleOverrides: {
              root: {
                '&:hover': {
                  color: '#fff!important',
                },
                '&.Mui-active': {
                  styleOverrides: {
                    root: {
                      color: '#fff !important',
                      '&.MuiTableSortLabel-icon': {
                        color: '#fff',
                        opacity: 'inherit',
                      },
                      '&.MuiTableSortLabel-icon': {
                        color: '#fff !important',
                      },
                    },
                  },
                },
              },
            },
          },
          MuiPaginationItem: {
            styleOverrides: {
              root: {
                '&.MuiPaginationItem-sizeMedium': {
                  backgroundColor: '#fff',
                },
                '&.Mui-selected': {
                  backgroundColor: '#fff! important',
                },
              },
            },
          },

          MuiChip: {
            styleOverrides: {
              root: {
                backgrounColor: '#0A294A',
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  backgrounColor: '#356697',
                },
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                '&.Mui-disabled': {
                  color: '#ffffff',
                },
              },
            },
          },
        },

        MuiListItemButton: {
          styleOverrides: {
            root: {
              '&.Mui-selected': {
                color: '#FFFFFF',
                backgroundColor: '#309737!important',
                borderRadius: '25px',
              },
            },
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: '1px solid #fff',
          '&.MuiToggleButtonGroup-grouped': {
            backgrounColor: '#0A294A',
            color: '#fff',
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          backgroundColor: '#0A294A',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-body1': {
            color: '#fff',
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.MuiTableCell-head:hover': {
            color: '#fff',
          },
          borderBottom: 'none',
        },
        head: {
          borderBottom: '1px solid #fff',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: '#0A1E33',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.MuiTableSortLabel-root:hover': {
            color: '#fff !important',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon': {
            opacity: '1',
            color: '#fff',
          },
        },
      },
    },
    // MuiInputBase-root-MuiOutlinedInput-root
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //         color: '#000 !important',
    //     },
    //   },
    // },
    MuiDataGrid: {
      styleOverrides: {
        virtualScroller: {
          // overflow: 'hidden!important',
        },
      },
    },
  },
});

const ThemeManager = (props) => {
  const [light, setLight] = React.useState(true);

  return (
    <ThemeProvider theme={light ? themeDark : themeLight}>
      {props.children}
    </ThemeProvider>
  );
};

export default ThemeManager;
