import apiRequest from './ApiService';

const apiEndPoint = 'global_parameters/payment_method?';

/*
 * Get all payment types
 * This will return a list of payment types
 */
export const getPaymenTypes = async (param = '') => {
  try {
    return await apiRequest.get(`/${apiEndPoint}` + param);
  } catch (error) {
    return error;
  }
};