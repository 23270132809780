import dashboard from './../../assets/icons/dashboard.svg';
import ar from './../../assets/icons/ar.svg';
import pos from './../../assets/icons/pos.svg';
import products from './../../assets/icons/Products.svg';
import stocks from './../../assets/icons/stocks.svg';
import customer from './../../assets/icons/customer.svg';
import earnings from './../../assets/icons/earnings.svg';
import reports from './../../assets/icons/reports.svg';
import settings from './../../assets/icons/settings.png';
import transactions from './../../assets/icons/transactions.svg';
import promo from './../../assets/icons/promo.svg';
import invoice from '../../assets/icons/invoice.svg';

import {
  Dashboard,
  Pos,
  ListProducts,
  ViewProducts,
  ListCustomer,
  ViewCustomer,
  ListStocks,
  Settings,
  ListTransactions,
  Invoices,
  ManageAccount,
  MainSupplier,
  SalesReport,
  CollectionReport,
  StockIn,
  StocksIn,
  StocksOut,
  Return,
  ImportHistory,
} from '../screens';

// Sidebar Routes
const routeList = [
  {
    text: 'Dashboard',
    icon: <img src={dashboard} />,
    to: '/',
    component: <Dashboard />,
    tooltip: 'Dashboard',
  },
  {
    text: 'POS',
    icon: <img src={pos} />,
    to: '/oms',
    component: <Pos />,
    tooltip: 'OMS',
  },
  {
    text: 'Invoices',
    icon: <img src={invoice} />,
    to: '/invoice',
    component: <Invoices />,
    tooltip: 'Invoices',
  },
  {
    text: 'Transactions',
    icon: <img src={transactions} />,
    to: '/transactions',
    component: <ListTransactions />,
    tooltip: 'Transactions',
  },
  {
    text: 'Products',
    icon: <img src={products} />,
    to: '/products',
    component: <ListProducts />,
    is_hidden: true,
    tooltip: 'Products',
  },
  {
    text: 'Suppliers',
    icon: <img src={products} />,
    to: '/supplier',
    component: <MainSupplier />,
    is_hidden: true,
    tooltip: 'Suppliers',
  },
  {
    text: 'View Products',
    icon: <img src={products} />,
    to: '/product_profile/:uuid',
    component: <ViewProducts />,
    is_hidden: true,
    tooltip: 'View Products',
  },
  {
    text: 'Stock Room',
    icon: <img src={stocks} />,
    is_hidden: false,
    sub_routes: [
      {
        text: 'Products',
        icon: <img src={products} />,
        to: '/products',
        tooltip: 'Products',
      },
      {
        text: 'Inventory',
        icon: <img src={stocks} />,
        to: '/inventory',
        tooltip: 'Inventory',
      },
      {
        text: 'Suppliers',
        icon: <img src={products} />,
        to: '/supplier',
        tooltip: 'Suppliers',
      },
    ],
  },
  {
    text: 'Stock Room',
    icon: <img src={stocks} />,
    to: '/inventory',
    component: <ListStocks />,
    is_hidden: true,
    tooltip: 'Stock Room',
  },

  {
    text: 'Customers',
    icon: <img src={customer} />,
    to: '/customers',
    component: <ListCustomer />,
    is_hidden: false,
    tooltip: 'Customers',
  },
  {
    text: 'View Customer',
    icon: <img src={products} />,
    to: '/customer_profile/:uuid',
    component: <ViewCustomer />,
    is_hidden: true,
    tooltip: 'View Customer',
  },
  {
    text: 'A/R',
    icon: <img src={ar} />,
    to: '/ar',
    is_hidden: true,
    tooltip: 'A/R',
  },
  {
    text: 'Earnings',
    icon: <img src={earnings} />,
    to: '/earnings',
    is_hidden: true,
    tooltip: 'Earnings',
  },
  {
    text: 'Reports',
    icon: <img src={reports} />,
    is_hidden: false,
    tooltip: 'Reports',
    sub_routes: [
      {
        text: 'Sales',
        icon: <img src={reports} />,
        to: '/sales_report',
        tooltip: 'Sales',
      },
      {
        text: 'Collection',
        icon: <img src={reports} />,
        to: '/collection_report',
        tooltip: 'Collection',
      },
      {
        text: 'Stock In',
        icon: <img src={reports} />,
        to: '/report_stock_in',
      },
      {
        text: 'Stock Out',
        icon: <img src={reports} />,
        to: '/report_stock_out',
      },
      {
        text: 'Import History',
        icon: <img src={reports} />,
        to: '/import_history',
      },
    ],
  },
  {
    text: 'Settings',
    icon: <img src={settings} />,
    to: '/setting',
    component: <Settings />,
    is_hidden: false,
    tooltip: 'Settings',
  },
  {
    text: 'Manage Account',
    to: '/manage_account',
    component: <ManageAccount />,
    is_hidden: true,
    tooltip: 'Manage Account',
  },
  {
    text: 'Sales Report',
    to: '/sales_report',
    component: <SalesReport />,
    is_hidden: true,
    tooltip: 'Sales Report',
  },
  {
    text: 'Collection Report',
    to: '/collection_report',
    component: <CollectionReport />,
    is_hidden: true,
    tooltip: 'Collection Report',
  },
  {
    text: 'Stock In',
    to: '/stock_in',
    component: <StockIn />,
    is_hidden: true,
    tooltip: 'Stock In',
  },
  {
    text: 'Stock In',
    to: '/report_stock_in',
    component: <StocksIn />,
    is_hidden: true,
  },
  {
    text: 'Stock In',
    to: '/report_stock_out',
    component: <StocksOut />,
    is_hidden: true,
  },
  {
    text: 'Return and Replace',
    to: '/return_and_replace',
    component: <Return />,
    is_hidden: true,
  },
  {
    text: 'Import History',
    to: '/import_history',
    component: <ImportHistory />,
    is_hidden: true,
  },
];

export default routeList;
