import { useEffect, useState } from "react";

// global css
import GlobalStyles from "../../../styles/GlobalStyles";

// component
import RecordSet from "./RecordSet";

// libs
import {
  Alert,
  AppBar,
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Modal,
  Link,
  Paper,
  Stack,
  Typography,
  Tab,
  Tabs,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TextField,
  Snackbar,
  FormControl,
  InputLabel,
  NativeSelect,
  Checkbox,
} from "@mui/material";

import { TabContext, TabList, TabPanel, LoadingButton } from "@mui/lab";

import { useTheme } from "@mui/material/styles";

// icons
import {
  QrCode as QrCodeIcon,
  Search as SearchIcon,
  Tune as TuneIcon,
} from "@mui/icons-material";
import pos from "../../../../assets/icons/pos.svg";
import invoice from "../../../../assets/icons/invoice.svg";
import all from "../../../../assets/icons/all.svg";

// services
import {
  navTabs,
  storePaymentTransaction,
  getTransactions,
} from "../../../services/TransactionService";

import { getPaymenTypes } from "../../../services/PaymentService";
import { set } from "react-hook-form";
import Selected from "./Selected";
import CustomDrawer from "../../../components/CustomDrawer";

const ListTransactions = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [wildCard, setWildCard] = useState("");
  const [triggerChange, setTriggerChange] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [snackOpen, setSnackOpen] = useState(false);
  const handleSnackOpen = () => setSnackOpen(true);
  const handleSnackClose = () => setSnackOpen(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [action, setAction] = useState("");

  const [payment, setPayment] = useState({
    transaction_uuid: "",
    payment_type: 1,
    amount: 0,
    remarks: "",
  });

  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [transaction, setTransaction] = useState({});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: GlobalStyles?.modalBg.backgroundColor,
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const detectChange = (flag) => {
    setTriggerChange(flag);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const handleFilter = (e) => {
    const search_value = e.target.value || "";
    setWildCard(search_value);
    setTriggerChange(true);
  };

  const [remarks, setRemarks] = useState("");
  useEffect(() => {
    setPayment({
      ...payment,
      remarks,
    });
  }, [remarks]);

  // update transaction on click
  useEffect(() => {
    setTriggerChange(true);
  }, [transaction]);

  // update payment on click
  useEffect(() => {
    if (payment && payment?.transaction_uuid) {
      // submit payment
      storePaymentTransaction(payment);

      // close modal
      handleClose();

      // show snackbar
      handleSnackOpen();

      // reset payment
      setPayment({
        transaction_uuid: "",
        payment_type: 1,
        amount: 0,
        remarks: "",
      });
      setRemarks("");
    }
  }, [payment]);

  const [productList, setProductList] = useState([]);

  const transactionData = (data) => {
    // transaction data - to be continue
    if (data && data?.id) {
      setTransaction(data);
      setProductList(data?.products);
      handleOpen();
      setActiveTab("1");
    }
  };

  const markAsPaid = (data) => {
    setPayment({
      ...payment,
      transaction_uuid: data?.uuid,
      amount: data?.balance_amount,
    });
  };

  const setPaymentType = (event) => {
    setPayment({
      ...payment,
      payment_type: event.target.value || 1,
    });
  };

  const [paymentTypes, setPaymentTypes] = useState([]);
  useEffect(() => {
    getPaymenTypes().then((res) => {
      setPaymentTypes(res.data.data);
    });
  }, []);

  const paymentForm = ({ status }) => {
    if (Number(status) === 0) {
      return (
        <>
          <Grid xs={12} mt={3} padding={2}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Payment Type
              </InputLabel>
              <NativeSelect
                sx={{
                  color: "#fff!important",
                }}
                defaultValue={1}
                inputProps={{
                  name: "payment_type",
                  id: "uncontrolled-native",
                }}
                onChange={setPaymentType}
              >
                {paymentTypes.map((item) => (
                  <option
                    key={item.id}
                    value={item.id}
                    sx={{ color: "000!important" }}
                  >
                    {item.name}
                  </option>
                ))}
                {/* <option value={1}>Cash</option>
                <option value={2}>Gcash</option>
                <option value={3}>Paymaya</option>
                <option value={4}>Bank Transfer</option>
                <option value={5}>Check</option> */}
              </NativeSelect>
            </FormControl>

            <TextField
              fullWidth
              id="input-remarks"
              label="Remarks"
              margin="normal"
              variant="filled"
              type="remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                markAsPaid(transaction);
              }}
            >
              Set as PAID
            </Button>
          </Grid>
        </>
      );
    }
  };

  const paymentDetails = ({ transaction }) => {
    if (
      transaction.payments?.length &&
      Number(transaction.payment_status) === 1
    ) {
      return (
        <>
          <Grid container spacing={2} mb={1} mt={1}>
            <Grid item xs={12} sm={12}>
              <Typography gutterBottom marginTop={2} fontWeight={500}>
                Status: {transaction.payment_status ? "Paid" : "Pending"}
              </Typography>

              {transaction?.payment_term?.name && (
                <Typography gutterBottom marginTop={2} fontWeight={500}>
                  Payment Terms: {transaction?.payment_term?.name || "N/A"}
                </Typography>
              )}

              <Typography gutterBottom marginTop={2} fontWeight={500}>
                Payment Type:{" "}
                {transaction?.payments[0]?.payment_type_name || "Cash"}
              </Typography>

              <Typography gutterBottom marginTop={2} fontWeight={500}>
                Remarks: {transaction?.payments[0]?.remarks || "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const prod = productList.map((i) => false);
  const [selected, setSelected] = useState(prod);
  const [items, setItems] = useState([]);

  const handleSelect = (e, index, data) => {
    const newSelected = [...selected];
    newSelected[index] = !selected[index];
    setSelected(newSelected);

    const newData = [...items];

    if (e.target.checked == true) {
      data["price"] = data.product?.price;
      data["available_stock"] = data.product?.product_summary?.available_stock;
      data["product_name"] = data.product?.product_name;
      data["orig_stock"] = data.product?.product_summary?.available_stock;
      newData.push(data);
      setItems(newData);
    } else {
      const index = newData.findIndex((x) => x.id === data.id);
      newData.splice(index, 1);
      setItems(newData);
    }
  };

  const getSelected = () => {
    // reset checkboxes
    const prod = productList.map((i) => false);
    setSelected(prod);
    localStorage.setItem("customer", JSON.stringify(transaction.customer));
    handleClose();
    setOpenDrawer(true);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          sx={12}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <TextField
            onChange={(e) => {
              handleFilter(e);
            }}
            placeholder="Transaction No."
            label="Search Transaction No."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "white" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs"
          >
            <Tab
              label={navTabs[0].label}
              icon={<img src={all} />}
              iconPosition="start"
              {...a11yProps(0)}
            />
            <Tab
              label={navTabs[1].label}
              icon={<img src={pos} />}
              iconPosition="start"
              {...a11yProps(1)}
            />
            <Tab
              label={navTabs[2].label}
              icon={<img src={invoice} />}
              iconPosition="start"
              {...a11yProps(2)}
            />
            <Tab
              label={navTabs[3].label}
              icon={<img />}
              iconPosition="start"
              {...a11yProps(3)}
            />
          </Tabs>

          <TabPanel value={value} index={0} dir={theme.direction}>
            <RecordSet
              filterType={{ type_of_transaction: "all", wildCard }}
              transactionData={transactionData}
              triggerChange={triggerChange}
              detectChange={detectChange}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <RecordSet
              filterType={{ type_of_transaction: "pos", wildCard }}
              transactionData={transactionData}
              triggerChange={triggerChange}
              detectChange={detectChange}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <RecordSet
              filterType={{ type_of_transaction: "invoice", wildCard }}
              transactionData={transactionData}
              triggerChange={triggerChange}
              detectChange={detectChange}
            />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <RecordSet
              filterType={{ type_of_transaction: "return", wildCard }}
              transactionData={transactionData}
              triggerChange={triggerChange}
              detectChange={detectChange}
            />
          </TabPanel>
        </Box>
        <CustomDrawer
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          setAction={setAction}
          action={action}
          showAddButton={false}
          onClose={() => {
            setOpenDrawer(false);
            setItems([]);
          }}
        >
          <Selected
            productData={items}
            setOpenDrawer={setOpenDrawer}
            transactionNumber={transaction}
            resetItem={setItems}
          />
        </CustomDrawer>
      </Grid>

      {/* <!-- transaction detail --> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            fontWeight={600}
          >
            Transaction: {transaction.transaction_number}
          </Typography>

          <Grid container spacing={2} mb={1} mt={1}>
            <TabContext value={activeTab}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
              >
                <TabList
                  onChange={handleTabChange}
                  aria-label="nav bar"
                  variant="fullWidth"
                >
                  <Tab label="Details" value="1" />
                  {transaction?.payment_status === 0 && (
                    <Tab label="Update" value="2" />
                  )}
                  {transaction?.payment_status === 1 && (
                    <Tab label="Payment" value="2" />
                  )}
                </TabList>
              </Box>
              {activeTab === "1" && (
                <TabPanel>
                  <Grid container spacing={2} mb={1} mt={1}>
                    <Grid item xs={6} sm={6}>
                      <Typography fontWeight={550} gutterBottom>
                        Transaction Details
                      </Typography>
                      <Typography gutterBottom>
                        Number: {transaction.transaction_number}
                      </Typography>
                      <Typography gutterBottom>
                        {/* {addresses.join(', ')} */}
                        Date: {transaction.created_at}
                      </Typography>
                      <Typography gutterBottom>
                        Type: {transaction.transaction_type_name}
                      </Typography>
                      <Typography gutterBottom marginTop={2} fontWeight={500}>
                        Status:{" "}
                        {transaction.payment_status ? "Paid" : "Pending"}
                      </Typography>

                      <Typography gutterBottom fontWeight={500} mt={2}>
                        Total Amount Due: ₱{" "}
                        {Number(transaction.balance_amount || 0).toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </Typography>
                    </Grid>
                    <Grid item container direction="column" xs={6} sm={6}>
                      <Typography fontWeight={550} gutterBottom>
                        Customer Details
                      </Typography>

                      <Typography gutterBottom>
                        {(transaction.customer?.first_name || "N/A") +
                          " " +
                          (transaction.customer?.last_name || "")}
                      </Typography>
                      <Typography gutterBottom>
                        {(transaction.customer?.location_city?.city_name ||
                          "") +
                          " " +
                          (transaction.customer?.location_province
                            ?.province_name || "")}
                      </Typography>
                      <Typography gutterBottom>
                        {transaction.customer?.mobile_number || "-"}
                      </Typography>
                    </Grid>

                    <TableContainer>
                      <Table
                        sx={{
                          minWidth: "500px",
                          maxWidth: "530px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          border: "1px solid",
                          marginTop: "10px",
                        }}
                        aria-label="caption table"
                      >
                        <TableHead>
                          <TableRow
                            sx={{
                              marginTop: "2px",
                            }}
                          >
                            {/* <TableCell>Number</TableCell> */}
                            <TableCell>Items</TableCell>
                            <TableCell align="right">Qty</TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            {transaction.has_return == 0 ? (
                              <TableCell align="right">Action</TableCell>
                            ) : (
                              <></>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productList &&
                            productList.map((row, index) => (
                              <TableRow key={row.product.product_name}>
                                {/* <TableCell
                                  sx={{
                                    backgroundColor: '#fff !important',
                                    color: '#000',
                                  }}
                                  component='th'
                                  scope='row'
                                >
                                  {row.product.product_code}
                                </TableCell> */}
                                <TableCell
                                  sx={{
                                    backgroundColor: "#fff !important",
                                    color: "#000",
                                  }}
                                >
                                  {row.product.product_name}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: "#fff !important",
                                    color: "#000",
                                  }}
                                  align="right"
                                >
                                  {row.quantity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: "#fff !important",
                                    color: "#000",
                                  }}
                                  align="right"
                                >
                                  ₱
                                  {Number(
                                    row.price_per_piece || 0
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: "#fff !important",
                                    color: "#000",
                                  }}
                                  align="right"
                                >
                                  ₱
                                  {Number(row.total_amount || 0).toLocaleString(
                                    "en",
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </TableCell>
                                {transaction.has_return == 0 ? (
                                  <TableCell
                                    sx={{
                                      backgroundColor: "#fff !important",
                                      color: "#000",
                                    }}
                                    align="right"
                                  >
                                    <Checkbox
                                      value={row}
                                      checked={selected[index]}
                                      onChange={(e) =>
                                        handleSelect(e, index, row)
                                      }
                                    />
                                  </TableCell>
                                ) : (
                                  <></>
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {transaction.has_return == 0 ? (
                      <Grid item xs={12}>
                        <Box flex={1} textAlign="right">
                          <LoadingButton
                            disabled={items.length == 0}
                            variant="contained"
                            style={GlobalStyles.primaryBtn}
                            onClick={() => getSelected()}
                          >
                            Return
                          </LoadingButton>
                        </Box>
                      </Grid>
                    ) : (
                      <> </>
                    )}
                    <Grid item xs={6}>
                      <Typography fontWeight={600} gutterBottom>
                        Sub Total:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} gutterBottom>
                        ₱{" "}
                        {Number(
                          transaction.subtotal_amount || 0
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography gutterBottom>Add Discount:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} gutterBottom>
                        ₱{" "}
                        {Number(
                          transaction.discount_amount || 0
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Typography gutterBottom>Tax:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} gutterBottom>
                        ₱{' '}
                        {Number(transaction.tax_amount || 0).toLocaleString(
                          'en',
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </Typography>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Typography fontWeight={600} gutterBottom>
                        Total:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={600} gutterBottom>
                        ₱{" "}
                        {Number(transaction.total_amount || 0).toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </Typography>
                    </Grid>
                    <Button
                      padding="1"
                      fullWidth
                      variant="contained"
                      color="info"
                      // replace with uat. from API response with window.location.origin
                      href={`${window.location.origin}/transaction_receipt/${
                        transaction.store?.name
                          ? transaction.store?.name
                              .replace(" ", "_")
                              .toLowerCase()
                          : ""
                      }/${transaction.transaction_number}`}
                      target="_blank"
                    >
                      Open Receipt
                    </Button>
                  </Grid>
                </TabPanel>
              )}
            </TabContext>

            {activeTab === "2" &&
              transaction?.payment_status === 0 &&
              paymentForm({ status: transaction?.payment_status })}

            {activeTab === "2" &&
              transaction?.payment_status === 1 &&
              paymentDetails({ transaction })}
          </Grid>
        </Box>
      </Modal>

      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Payment has been successfully submitted
        </Alert>
      </Snackbar>
    </>
  );
};

export default ListTransactions;
