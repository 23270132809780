import { useEffect, useState, useRef } from "react";
import {
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  Box,
  Chip,
  Grid,
  Button,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useParams } from "react-router-dom";

// services
import {
  tableDummyRecords,
  getTransactions,
  getTransactionById,
  exportTransactions,
} from "../../../services/TransactionService";
import DownloadIcon from "@mui/icons-material/Download";

// global css
import GlobalStyles from "../../../styles/GlobalStyles";

const RecordSet = ({
  filterType,
  transactionData,
  detectChange,
  triggerChange,
}) => {
  const [rsData, setRsData] = useState([]);
  const [rsDataPagination, setRsDataPagination] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const mountedRef = useRef();
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (mountedRef.current) {
      //if (triggerChange) {
      getTransactionRecords(page, pageSize);
      // }
    }
  }, [value, pageSize]);

  useEffect(() => {
    mountedRef.current = true;
    // update the state after some time
    setTimeout(setValue, 1000, true);
  }, []);

  const tableColumns = [
    {
      field: "date",
      headerName: "Transaction Date",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "transaction_no",
      headerName: "Transaction Number",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "type_of_transaction",
      headerName: "Transaction Type",
      minWidth: 150,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (param) => {
        const handleColor = (color) => {
          switch (color) {
            case "POS":
              return "success";
            case "Invoice":
              return "info";
            case "Return":
              return "warning";
            default:
              return "default";
          }
        };

        const handleLabel = (label) => {
          switch (label) {
            case "POS":
              return "Order";

            default:
              return label;
          }
        };

        return (
          <Chip
            sx={{ borderRadius: "0px", height: "auto", width: 100 }}
            label={handleLabel(param.value)}
            color={handleColor(param.value)}
          />
        );
      },
    },
    {
      field: "items_quantity",
      headerName: "No. of Items",
      minWidth: 100,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "discount_amount",
      headerName: "Discount",
      minWidth: 100,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "payment_status",
      headerName: "Payment status",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (param) => {
        return (
          <Chip
            sx={{ borderRadius: "0px", height: "auto", width: 100 }}
            label={param.value}
            variant={param.value === "Paid" ? "filled" : "outlined"}
            color={param.value === "Paid" ? "success" : "default"}
          />
        );
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "sales_person",
      headerName: "Sales Person",
      minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const getTransactionRecords = async (page, pageSize) => {
    setIsLoading(true);
    setPage(page);

    const store_id = (await AsyncStorage.getItem("store_id")) || null;
    let formattedData = [];
    let record = [];
    let type = filterType?.type_of_transaction || null;

    switch (type) {
      case "pos":
        type = 1;
        break;
      case "invoice":
        type = 2;
        break;
      case "return":
        type = 3;
        break;
      default:
        type = "1,2,3";
    }

    var data = [];
    if (filterType?.wildCard && filterType?.wildCard?.length) {
      data = await getTransactions(
        `/search?keyword=${filterType?.wildCard}&page=${page}&size=${pageSize}&type=${type}`
      );
    } else {
      data = await getTransactions(
        `?sort=-id&page=${page}&size=${pageSize}&filter[type]=${type}&filter[store_id]=` +
          store_id
      );
    }

    if (data?.data?.data && data?.data?.success) {
      formattedData = data?.data?.data.map((d) => {
        return {
          id: d?.id,
          date: d?.created_at || "",
          transaction_no: d?.transaction_number || "",
          type_of_transaction: d?.transaction_type_name || "N/A",
          items_quantity: d?.items_quantity || "",
          discount_amount: d?.discount_amount || "",
          total_amount: d?.total_amount || "",
          payment_status: d?.payment_status ? "Paid" : "Pending",
          customer: d?.customer
            ? `${d?.customer?.last_name}, ${d?.customer?.first_name}`
            : "N/A",
          sales_person: d?.sales_person || "",
          uuid: d?.uuid,
        };
      });

      record = formattedData;

      // search by wildcard
      // if (filterType?.wildCard && filterType?.wildCard?.length) {
      //    const wildcard = (filterType?.wildCard || '').toLowerCase();
      //   record = record.filter(
      //     (d) =>
      //       // String(d?.customer).toLowerCase().includes(wildcard) ||
      //       String(d?.transaction_no).toLowerCase().includes(wildcard)
      //       // String(d?.date).toLowerCase().includes(wildcard) ||
      //       // String(d?.payment_statuss).toLowerCase().includes(wildcard)
      //   );
      // }

      setRsData(record);
      setRsDataPagination(data?.data?.pagination);
      setIsLoading(false);
      // detectChange(false);
    } else {
      console.warn("Failed to load transactions");
    }
  };

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: GlobalStyles.tableEvenBg.backgroundColor,
    },
  }));

  const customToolbar = () => {
    return (
      <GridToolbarContainer style={{ float: "right" }}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  // useEffect(() => {
  //   console.log('rs data', transactionData);
  //   // update transaction on click
  // }, [transactionData]);

  const onRowClick = async (params, event) => {
    const transData = (await getTransactionById(params?.row?.uuid)) || {};
    transactionData(transData);
  };

  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  // const { type } = useParams();

  const handleClickExportTransaction = async () => {
    try {
      let type = filterType?.type_of_transaction;
      switch (type) {
        case "pos":
          type = 1;
          break;
        case "invoice":
          type = 2;
          break;
        default:
          type = "1,2";
      }

      const res = await exportTransactions(type);
      if (resp?.data?.success === 1) {
        setOpenSnack(true);
        setSnackVariant("success");
        setErrMsg("Successfully sent file to your email address");
      } else {
        setOpenSnack(true);
        setSnackVariant("error");
        setErrMsg(resp?.data?.message || "Something went wrong");
      }
    } catch {
      setOpenSnack(true);
      setSnackVariant("error");
      setErrMsg("Something went wrong");
    }
  };

  return (
    <>
      <Box style={{ width: "100%", border: 0 }}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button onClick={() => handleClickExportTransaction()}>
            <DownloadIcon></DownloadIcon>Export
          </Button>
        </Grid>
        <StripedDataGrid
          style={{ border: 0 }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) =>
            getTransactionRecords(newPage + 1, pageSize)
          }
          page={page - 1}
          rowCount={rsDataPagination?.total}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          columns={tableColumns}
          pagination
          paginationMode="server"
          rows={rsData}
          autoHeight
          disableColumnFilter
          disableSelectionOnClick
          loading={isLoading}
          onRowClick={onRowClick}
          sx={{
            ".MuiDataGrid-row:hover": {
              backgroundColor: GlobalStyles?.secondaryBg?.backgroundColor,
            },
            ".MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&>.MuiDataGrid-main": {
              "&>.MuiDataGrid-columnHeaders": {
                borderBottom: "none",
              },

              "& div div div div >.MuiDataGrid-cell": {
                borderBottom: "none",
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          components={
            {
              // Toolbar: customToolbar,
            }
          }
        />
      </Box>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default RecordSet;
