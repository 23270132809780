import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
// css
import GlobalStyles from "../../../styles/GlobalStyles";

// common

// services
import { getProductsByID, addBarcode } from "../../../services/ProductService";

// images
import no_barcode from "./../../../../assets/images/no_barcode.png";

// libs
import {
  Box,
  Grid,
  CardMedia,
  Card,
  Typography,
  CircularProgress,
  Backdrop,
  Button,
} from "@mui/material";

import axios from "axios";
import { saveAs } from "file-saver";

const ViewBarcode = ({ setOpenDrawer, setBarcodeImage, barcodeImage }) => {
  const [formValues, setFormValues] = useState({});
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    defaultValues: formValues,
  });

  useEffect(() => {
    getProductDetails();
  }, []);

  const [productData, setProductData] = useState([]);
  const uuid = location.pathname.split("/").pop();

  const getProductDetails = () => {
    getProductsByID(uuid).then((rs) => {
      setProductData(rs.data.data);
      setBarcodeImage(rs.data.data.barcode);
    });
  };

  // generate barcode
  const generateBarcode = async () => {
    const resp = await addBarcode(uuid);
    setBarcodeImage(resp.data.data.barcode);
    getProductDetails();
  };

  const handleDownload = async (imageUrl, fileName) => {
    try {
      const response = await axios.get(imageUrl, {
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      console.log(imageUrl);

      saveAs(response.data, fileName);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <>
      <Box component="form" sx={{ flexGrow: 1, width: "500px" }} noValidate>
        <Grid container spacing={2} style={GlobalStyles.drawerWrapper}>
          <Grid item md={12} sx={{ padding: "20px 15px" }}>
            <Grid item xs={12} mb={2}>
              <Typography variant="h7" component="div">
                <i>Product Name :</i> <b>{productData.product_name}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{}}>
              <Card
                sx={{
                  borderRadius: 0,
                  backgroundColor: "#fff",
                  padding: "20px",
                }}
              >
                <CardMedia
                  component="img"
                  src={barcodeImage ? barcodeImage : no_barcode}
                  alt="Barcode Image"
                  sx={{
                    width: "390px",
                    height: "130px",
                    margin: "auto",
                    objectFit: "contain",
                  }}
                />
              </Card>
            </Grid>

            <Typography
              component="div"
              variant="h4"
              sx={{ py: "15px", textAlign: "center" }}
            >
              {productData.product_code}
            </Typography>

            <Grid container spacing={2}>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => generateBarcode()}
                  variant="text"
                  sx={{ p: "0 25px" }}
                >
                  Generate Barcode
                </Button>
                {productData?.barcode && (
                  <a
                    sx={{ p: "0 25px" }}
                    onClick={() =>
                      handleDownload(productData.barcode, "Barcode.png")
                    }
                  >
                    <Button sx={{ p: "0 25px" }} variant="text">
                      {" "}
                      Download{" "}
                    </Button>
                  </a>
                )}
                {/* </a> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
};

export default ViewBarcode;
