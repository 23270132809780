import { useState } from "react";
import { Box, Grid, Button, CardMedia } from "@mui/material";

// images
import success_image from "./../../../../../assets/images/success_transaction_image.png";
import success_bg from "./../../../../../assets/images/success_transaction_bg.png";

import { formatPrice } from "../../../../utils/Strings";
import { useLocation } from "react-router-dom";
import { UseDocumentTitle } from "../../../../hooks/UseDocumentTitle";

export default function PosSuccess(props) {
  const {
    discount,
    paymentAmount,
    isSuccessTransaction,
    cart,
    handleNewTransaction,
    handleReceipt,
    handleCopyLink,
  } = props;

  const location = useLocation();
  const currentLocation = location.pathname.split('/')[1];
  const [documentTitle] = UseDocumentTitle(currentLocation);

  const subTotal = Number(
    cart.reduce((accumulator, object) => {
      return accumulator + object.price * object.quantity;
    }, 0)
  );
  const grandTotal = Number(subTotal - discount).toFixed(2);

  const handleExitTransaction = () => {
    window.location.href = '/transactions';
  };


  return isSuccessTransaction && cart.length ? (
    <Grid item xs={12} textAlign="center" p={1}>
      <h6 textAlign="center" style={{ marginBottom: 0 }}>
        Grand Total
      </h6>
      <h2 textAlign="center" style={{ marginTop: 0 }}>
        {formatPrice(grandTotal)}
      </h2>
      <h1 textAlign="center" style={{ marginBottom: 0 }}>
        {Number(paymentAmount - grandTotal) >= 0
          ? formatPrice(Number(paymentAmount - grandTotal))
          : formatPrice(0)}
        &nbsp; change
      </h1>
      <h6 textAlign="center" style={{ marginTop: 0 }}>
        out of {formatPrice(paymentAmount)}
      </h6>
      <Box
        alignItems="center"
        textAlign="center"
        display="flex"
        flexDirection="column"
        style={{
          backgroundImage: `url(${success_bg})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      >
        <h3>Transaction Successful!</h3>
        <CardMedia
          name="customer_image"
          component="img"
          src={success_image}
          alt=""
          style={{
            height: "103px",
            width: "114px",
            contain: "size",
          }}
        />
      </Box>
      <Box
        textAlign="center"
        display="flex"
        justifyContent="space-between"
        alignSelf="normal"
        mt={5}
      >
        <Button
          fullWidth
          variant="contained"
          color="info"
          onClick={handleCopyLink}
        >
          Copy Link
        </Button>
        &emsp;
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={handleReceipt}
        >
          Receipt
        </Button>
      </Box>
      <br />
      {documentTitle === 'return_and_replace' ? (
      <Button
        fullWidth
        variant="outlined"
        color="success"
        onClick={handleExitTransaction}
      >
        Exit Transaction
      </Button>
      ) : (
        <Button
        fullWidth
        variant="outlined"
        color="success"
        onClick={handleNewTransaction}
      >
        New Transaction
      </Button>
      )}

    </Grid>
  ) : (
    <></>
  );
}
