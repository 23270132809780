import { Skeleton, Box, Grid } from "@mui/material";

export default function PosProductSkeleton() {
  return (
    <Box>
      <Skeleton variant="rectangular" width="100%" height={50} />
      <hr />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Box p={2}>
        <Grid
          container
          sx={{
            width: "100%",
            maxHeight: "60vh",
            overflow: "hidden",
            overflowY: "scroll",
            pt: 2,
          }}
        >
          {Array(18)
            .fill()
            .map((_, index) => (
              <Grid key={index} item xs={4} sm={3} md={2} lg={2}>
                <Skeleton
                  variant="rectangular"
                  height="122.31px"
                  width="129.31px"
                  sx={{ borderRadius: 2, mb: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  height="8px"
                  width="129.31px"
                  sx={{ mb: 1 }}
                />
                <Box display="flex">
                  <Skeleton
                    variant="rectangular"
                    height="8px"
                    width="80px"
                    sx={{ marginRight: "20px" }}
                  />
                  <Skeleton variant="rectangular" height="8px" width="30px" />
                </Box>
                <br />
              </Grid>
            ))}
        </Grid>
      </Box>
      <br />
      <hr />
      <Skeleton variant="rectangular" width="100%" height={25} />
      <br />
    </Box>
  );
}
