import {
  Button,
  TextField,
  InputAdornment,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createRef, useEffect, useState } from "react";

export default function SearchProduct(props) {
  const inputRef = createRef(null);
  const [search, setSearch] = useState("");
  const {
    storeId,
    rowsPerPage,
    getProductList,
    setSearchText,
    disabled,
    filter,
    barcodeMode,
    setBarcodeMode,
    products,
    handleAddProduct,
    handleInactivePopup,
  } = props;

  const resetSearch = () => {
    setSearchText("");
    setSearch("");
    getProductList("", storeId, rowsPerPage, filter);
    inputRef.current.focus();
  };

  useEffect(() => {
    console.log(search);
    const delayDebounceFn = setTimeout(async () => {
      const list = await getProductList(search, storeId, rowsPerPage, filter);
      if (barcodeMode && list.length === 1) {
        const selectedProduct = products.find((row) => row.id === list[0].id);
        if (selectedProduct?.status) {
          handleAddProduct(1, selectedProduct);
          setSearch("");
        } else {
          handleInactivePopup && handleInactivePopup();
          setSearch("");
        }
      } else {
        setSearchText(search);
      }
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <Box display="flex" alignItems="center">
      <TextField
        fullWidth
        autoFocus
        autoComplete="off"
        value={search}
        label="Search"
        variant="filled"
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button onClick={resetSearch}>
                <CloseIcon style={{ color: "#FFFFFF" }} />
              </Button>
            </InputAdornment>
          ),
        }}
        inputRef={inputRef}
      />
      <FormGroup
        style={{
          marginLeft: 10,
          marginRight: 5,
          paddingBottom: 8,
          paddingTop: 8,
          background: barcodeMode ? "#036803" : "#0A294A",
        }}
      >
        <FormControlLabel
          style={{ marginLeft: 5 }}
          onChange={() => {
            setBarcodeMode(!barcodeMode);
            inputRef.current.focus();
          }}
          control={<Switch size="large" color="info" checked={barcodeMode} />}
          label="BARCODE"
        />
      </FormGroup>
    </Box>
  );
}
