import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatPrice } from "../../../../utils/Strings";

export default function PosDetails(props) {
  const {
    isLoading,
    discount,
    isSuccessTransaction,
    cart,
    setCart,
    handleOpenChargeModal,
    handleOpenUtangModal,
    handleDiscount,
    products,
    setProducts,
    tableRef,
  } = props;

  const columns = [
    { field: "product_name", headerName: "PRODUCT" },
    {
      field: "quantity",
      headerName: "QTY",
    },
    {
      field: "price",
      headerName: "PRICE",
    },
    {
      field: "amount",
      headerName: "AMOUNT",
    },
  ];

  const subTotal = Number(
    cart.reduce((accumulator, object) => {
      return accumulator + object.price * object.quantity;
    }, 0)
  );
  const vatableSales = ((subTotal - discount) / 1.12).toFixed(2);
  const vatValue = vatableSales * 0.12;
  const grandTotal = Number(subTotal - discount).toFixed(2);

  return !isSuccessTransaction && cart.length ? (
    <Grid item xs={12}>
      <Box
        style={{
          borderRadius: 10,
          paddingTop: "5%",
          height: "100%",
        }}
      >
        <Box
          textAlign="right"
          sx={{
            background: "#D9D9D9",
            borderRadius: 2,
            ml: 2,
            mr: 2,
            pl: 2,
            pr: 2,
          }}
        >
          <h1 style={{ color: "#000000", margin: 0 }}>
            {formatPrice(grandTotal)}
          </h1>
        </Box>
        <br />
        <Typography m={2} align="right">
          No. of Items: {cart?.length}
        </Typography>
        <TableContainer sx={{ height: 300 }}>
          <Table ref={tableRef}>
            <TableHead>
              <TableRow>
                {columns?.map((col, index) => (
                  <TableCell sx={{ fontWeight: "bold" }} key={index}>
                    {col.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody p={1} sx={{ maxHeight: 250 }}>
              {cart.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ padding: "0px 16px" }}>
                    <Typography
                      noWrap
                      sx={{
                        maxWidth: 100,
                        fontSize: 12,
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.product_name || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "0px 16px" }}>
                    <TextField
                      type="number"
                      value={row?.quantity}
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        min: 1,
                      }}
                      sx={{
                        minWidth: 70,
                        textAlignLast: "center",
                      }}
                      onChange={(e) => {
                        const reg = /^[0-9\b]+$/;
                        const tempCart = cart;
                        const tempProducts = products;
                        if (e.target.value === "" || reg.test(e.target.value)) {
                          const selectedProductIndex = products.findIndex(
                            (item) => {
                              return item.id === row.id;
                            }
                          );
                          const selectedItemIndex = cart.findIndex((item) => {
                            return item.id === row.id;
                          });
                          const CurrentAvailableStock = Number(
                            tempCart[selectedItemIndex].available_stock || 0
                          );
                          const CurrentStock = Number(
                            tempCart[selectedItemIndex].orig_stock
                          );

                          if (CurrentAvailableStock) {
                            if (
                              e.target.value === "" ||
                              Number(e.target.value) <= CurrentStock
                            ) {
                              tempCart[selectedItemIndex].quantity =
                                Number(e.target.value) || "";

                              tempCart[selectedItemIndex].available_stock =
                                Number(tempCart[selectedItemIndex].orig_stock) -
                                Number(tempCart[selectedItemIndex].quantity);

                              tempProducts[
                                selectedProductIndex
                              ].available_stock =
                                Number(tempCart[selectedItemIndex].orig_stock) -
                                Number(tempCart[selectedItemIndex].quantity);
                              setProducts(() => [...tempProducts]);
                              setCart(() => [...tempCart]);
                            }
                          } else {
                            if (
                              e.target.value === "" ||
                              Number(e.target.value || 1) < CurrentStock
                            ) {
                              tempCart[selectedItemIndex].quantity =
                                Number(e.target.value) || 1;

                              tempCart[selectedItemIndex].available_stock =
                                Number(tempCart[selectedItemIndex].orig_stock) -
                                Number(tempCart[selectedItemIndex].quantity);

                              tempProducts[
                                selectedProductIndex
                              ].available_stock =
                                Number(tempCart[selectedItemIndex].orig_stock) -
                                Number(tempCart[selectedItemIndex].quantity);
                              setProducts(() => [...tempProducts]);
                              setCart(() => [...tempCart]);
                            }
                          }
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "0px 16px" }}>
                    <Typography
                      minWidth={75}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {formatPrice(row?.price)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "0px 16px" }}>
                    <Box
                      sx={{
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        minWidth: 100,
                        fontSize: 12,
                      }}
                    >
                      {formatPrice(Number(row.quantity * row.price))}
                      &emsp;
                      <Button
                        onClick={() => {
                          if (
                            confirm(
                              "Are you sure you want to delete this item?"
                            )
                          ) {
                            const tempCart = cart.filter(
                              (tempRow) => tempRow.id != row.id
                            );
                            const tempProducts = products;
                            const productIndex = products.findIndex(
                              (item) => item.id === row.id
                            );
                            tempProducts[productIndex].available_stock =
                              tempProducts[productIndex].orig_stock;
                            setProducts(() => [...tempProducts]);
                            setCart(() => [...tempCart]);
                          }
                        }}
                        startIcon={<DeleteIcon />}
                        sx={{ pr: 0, minWidth: "25px" }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography>SUBTOTAL</Typography>
            <Typography> {formatPrice(subTotal)}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button sx={{ p: 0 }} onClick={handleDiscount}>
              <Link>
                <Typography style={{ color: "#2A7DD5" }}>
                  ADD DISCOUNT
                </Typography>
              </Link>
            </Button>
            <Typography>{discount ? formatPrice(discount) : 0.0}</Typography>
          </Box>
          {/* <Box display="flex" justifyContent="space-between">
            <Typography>VATable Sales</Typography>
            <Typography>{formatPrice(vatableSales)}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography>12% VAT</Typography>
            <Typography>{formatPrice(vatValue)}</Typography>
          </Box> */}
          <Box display="flex" justifyContent="space-between">
            <Typography>TOTAL AMOUNT DUE</Typography>
            <Typography> {formatPrice(grandTotal)}</Typography>
          </Box>
          <br />
          {isLoading && (
            <Box display="flex" flex={1} justifyContent="center">
              <CircularProgress textAlign="center" />
            </Box>
          )}
          {Number(grandTotal) && !cart.find((row) => row.quantity === "") ? (
            <Box
              textAlign="center"
              display="flex"
              justifyContent="space-between"
            >
              <LoadingButton
                variant="outlined"
                color="success"
                onClick={() => {
                  if (
                    confirm("Are you sure you want to cancel your transaction?")
                  ) {
                    setCart([]);
                    const tempProducts = products.map((product) => {
                      if (product?.orig_stock) {
                        product.available_stock = product.orig_stock;
                      }
                      return product;
                    });
                    setProducts(tempProducts);
                  }
                }}
                disabled={isLoading}
                sx={{ p: 1, m: 1 }}
                fullWidth
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="success"
                onClick={handleOpenChargeModal}
                disabled={isLoading}
                sx={{ p: 1, m: 1 }}
                fullWidth
              >
                Charge {formatPrice(grandTotal)}
              </LoadingButton>
              <LoadingButton
                disabled={isLoading}
                variant="contained"
                color="info"
                onClick={handleOpenUtangModal}
                sx={{ p: 1, m: 1 }}
                fullWidth
              >
                Utang {formatPrice(grandTotal)}
              </LoadingButton>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Grid>
  ) : (
    <></>
  );
}
