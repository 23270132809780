import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '@env';

const apiRequest = axios.create({
  baseURL: API_URL,
  timeout: 150000,
  responseType: 'json',
});

apiRequest.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    config.headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default apiRequest;
