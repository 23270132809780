import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

// libs
import { Box, TextField, Stack, Button } from "@mui/material";

// services
import { changePassword } from "../../../services/ProfileService";

const UpdatePassword = ({
  productData,
  setOpenDrawer,
  setOpenSnack,
  setSnackVariant,
  setErrMsg,
  setOpenLoader,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
  });

  useEffect(() => {
    reset({ ...productData });
  }, []);

  // submit form add stock
  async function onSubmit(data) {
    setOpenLoader(true);
    let formData = {};
    formData = data;
    // formData.product_id = productData.id;
    // formData.created_by = 1;

    if (formData) {
      try {
        const data = await changePassword(formData);
        if (data.data.success == 1) {
          setSnackVariant("success");
          setErrMsg(data.data.message);
          reset();
          setOpenDrawer(false);
          setOpenSnack(true);
        } else {
          const msg = data.data.errors;
          const updatedErrors = { ...errors };
          for (const key in msg) {
            if (msg.hasOwnProperty(key)) {
              const value = msg[key].join(" ");
              let field = key;
              updatedErrors[key] = { type: "manual", message: value };
              setError(field, {
                type: "server",
                message: value,
              });
            }
          }
        }
        setOpenLoader(false);
      } catch (error) {
        console.log(error);
      } finally {
        // setOpenSnack(true);
      }
    }
  }

  return (
    <Box
      sx={{ flexGrow: 1, width: "408px" }}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box sx={{ padding: 5 }}>
        <Controller
          name="current_password"
          control={control}
          rules={{
            required: "Current Password is required",
          }}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              required
              label="Current password"
              type="password"
              autoFocus
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              error={!!errors.current_password}
              helperText={
                errors.current_password?.message
                  ? errors.current_password?.message
                  : ""
              }
              {...field}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          rules={{
            required: "New Password is required",
            minLength: {
              value: 8,
              message: "Must be 8 characters",
            },
          }}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              required
              id="password"
              label="New password"
              type="password"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              error={!!errors.password}
              helperText={
                errors.password
                  ? errors.password.message
                  : "(Must be at least 8 characters)"
              }
              {...field}
            />
          )}
        />

        <Controller
          name="password_confirmation"
          control={control}
          rules={{
            required: "Confirm new password is required",
            minLength: {
              value: 8,
              message: "Must be 8 characters",
            },
            validate: (value) =>
              value === watch("password") || "Passwords don't match.",
          }}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              required
              id="password_confirmation"
              label="Confirm new password"
              type="password"
              variant="filled"
              inputProps={{ autoComplete: "none" }}
              error={!!errors.password_confirmation}
              helperText={
                errors.password_confirmation?.message
                  ? errors.password_confirmation.message
                  : "(Must be at least 8 characters)"
              }
              {...field}
            />
          )}
        />

        <Stack
          spacing={1}
          direction="row"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          mt={5}
        >
          <Button onClick={() => setOpenDrawer(false)} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="success">
            Save
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default UpdatePassword;
