import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Button, Grid, Typography } from "@mui/material";
import {
  getPOSProducts,
  storeInvoiceTransaction,
  getPaymentTerms,
} from "../../../services/PosService";
import ReceiptModal from "./components/ReceiptModal";
import { QuantityDialog } from "./components/QuantityDialog";

// global css
import InvoiceDataModal from "./components/InvoiceDataModal";
import { DiscountDialog } from "./components/DiscountDialog";
import PosProducts from "./sections/PosProducts";
import InvoiceDetails from "./sections/InvoiceDetails";
import InvoiceSuccess from "./sections/InvoiceSuccess";
import InvoiceModal from "./components/InvoiceModal";
import SearchProduct from "./components/SearchProduct";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CategoryChips from "./sections/CategoryChips";
import PosProductSkeleton from "./sections/PosProductSkeleton";

export default function Invoice() {
  let cancelTokenSource;
  const tableRef = useRef(0);
  const [barcodeMode, setBarcodeMode] = useState(false);
  const [storeId, setStoreId] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [filter, setFilter] = useState(0);
  const [cart, setCart] = useState([]);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [openReceiptModal, setReceiptModalOpen] = useState(false);
  const [openInvoiceDataModal, setInvoiceDataModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const [addDiscount, setAddDiscount] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [isSuccessTransaction, setSuccessTransaction] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const loadMore = async () => {
    const newRows = Number(rowsPerPage) + 15;
    setRowsPerPage(newRows);
    getProductList(searchText, storeId, newRows, filter);
  };

  const handleOpenInvoiceModal = () => {
    setIsLoading(!isLoading);
    setInvoiceDataModalOpen(!openInvoiceDataModal);
  };

  const subTotal = Number(
    cart.reduce((accumulator, object) => {
      return accumulator + object.price * object.quantity;
    }, 0)
  );

  const grandTotal = Number(subTotal - discount).toFixed(2);

  const getProductList = async (word, storeId, rowsPerPage, filter) => {
    // Cancel the previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new search");
    }

    // Create a new CancelToken source
    cancelTokenSource = axios.CancelToken.source();

    return await getPOSProducts(
      word,
      storeId,
      rowsPerPage,
      filter,
      cancelTokenSource
    )
      .then((response) => {
        if (response.data.length === products.length) {
          setHasNextPage(false);
        } else {
          setProducts(
            response.data?.map((row) => ({
              ...row,
              available_stock: row?.summary?.available_stock,
            }))
          );
        }
        return response.data;
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const handlePressItem = (item) => {
    handleAddProduct(1, item);
  };

  // get products from api and set to products state
  useEffect(() => {
    const fetchProduct = async () => {
      const id = await AsyncStorage.getItem("store_id");
      getProductList("", id, rowsPerPage, filter);
      setStoreId(id);
    };
    const getInvoicePaymentTerms = async () => {
      await getPaymentTerms().then((response) => {
        setPaymentTerms(response?.data?.data);
      });
    };
    fetchProduct();
    getInvoicePaymentTerms();
  }, []);

  const handleAddProduct = (quantity, item) => {
    const selectedItem = item ? item : selected;
    const selectedFromCart = cart.find((row) => row.id === selectedItem.id);
    const tempCart = cart.filter((row) => row.id !== selectedItem.id);
    const accumulatedQuantity = selectedFromCart?.quantity
      ? (selectedFromCart.quantity += quantity)
      : quantity;
    const selectedItemIndex = products.findIndex(
      (row) => row.id === selectedItem.id
    );
    const tempProducts = products;
    if (!tempProducts[selectedItemIndex].orig_stock) {
      tempProducts[selectedItemIndex].orig_stock = selectedItem.available_stock;
    }
    tempProducts[selectedItemIndex].available_stock =
      selectedItem.available_stock - quantity;
    setProducts(tempProducts);
    setCart([
      ...tempCart,
      {
        ...selectedItem,
        quantity: accumulatedQuantity,
      },
    ]);
    if (item) {
      setSearchText("");
    }

    if (tableRef.current) {
      setTimeout(() => {
        tableRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 100);
    }
  };

  const handleInvoice = async (
    customerId,
    paymentId,
    customDate,
    payment_type
  ) => {
    setInvoiceDataModalOpen(false);
    const products = cart.map((row) => {
      return { product_id: row.id, quantity: row.quantity };
    });

    const params = {
      products: products,
      customer_id: customerId,
      items_quantity: products.length,
      subtotal_amount: subTotal,
      discount_value: discount,
      payment_terms: paymentId,
      payment_due: customDate,
      payment_type: payment_type,
    };

    try {
      const data = await storeInvoiceTransaction(params);
      if (data) {
        setReceipt({ ...data?.data });
        setSuccessTransaction(!isSuccessTransaction);
        setFailed(false);
      } else {
        setFailed(true);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleNewTransaction = () => {
    setCart([]);
    setSuccessTransaction(false);
    setIsLoading(false);
    setDiscount(0);
  };

  const handleReceipt = () => {
    setReceiptModalOpen(!openReceiptModal);
  };
  const handleInvoiceModal = () => {
    setInvoiceModal(!invoiceModal);
  };

  const handleCopyLink = async () => {
    const copyLink = receipt?.data?.transaction_receipt_link;
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(copyLink);
    } else {
      document.execCommand("copy", true, copyLink);
    }
    alert("Copied Link!");
  };
  return fetching ? (
    <PosProductSkeleton />
  ) : (
    <Grid container>
      <Grid item sm={12} md={cart.length ? 6 : 12}>
        <CategoryChips
          searchText={searchText}
          storeId={storeId}
          rowsPerPage={rowsPerPage}
          filter={filter}
          setFilter={setFilter}
          getProductList={getProductList}
        />

        <SearchProduct
          storeId={storeId}
          rowsPerPage={rowsPerPage}
          getProductList={getProductList}
          searchText={searchText}
          setSearchText={setSearchText}
          disabled={isSuccessTransaction}
          filter={filter}
          setBarcodeMode={setBarcodeMode}
          barcodeMode={barcodeMode}
          handleAddProduct={handleAddProduct}
          setSelected={setSelected}
          products={products}
        />
        <PosProducts
          cart={cart}
          products={products}
          handlePressItem={handlePressItem}
          isSuccessTransaction={isSuccessTransaction}
        />
        {products && products.length > 0 ? (
          hasNextPage ? (
            <Button onClick={loadMore} fullWidth>
              Load More
            </Button>
          ) : (
            <Typography mt={2} align="center">
              - End of List -
            </Typography>
          )
        ) : (
          <Typography mt={2} align="center">
            No Record Found
          </Typography>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          background: "#050F1A",
          borderRadius: 1,
        }}
      >
        {!isSuccessTransaction && cart.length ? (
          <InvoiceDetails
            isFailed={isFailed}
            isLoading={isLoading}
            discount={discount}
            isSuccessTransaction={isSuccessTransaction}
            cart={cart}
            setCart={setCart}
            setProducts={setProducts}
            products={products}
            setFailed={setFailed}
            handleOpenInvoiceModal={handleOpenInvoiceModal}
            handleDiscount={() => setAddDiscount(!addDiscount)}
            tableRef={tableRef}
          />
        ) : (
          <></>
        )}
        {isSuccessTransaction && cart.length ? (
          <InvoiceSuccess
            handleNewTransaction={handleNewTransaction}
            discount={discount}
            isSuccessTransaction={isSuccessTransaction}
            cart={cart}
            handleReceipt={handleReceipt}
            handleInvoice={handleInvoiceModal}
            handleCopyLink={handleCopyLink}
          />
        ) : (
          <></>
        )}
      </Grid>
      <QuantityDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleAddProduct}
        item={selected}
      />
      <DiscountDialog
        currentTotal={subTotal}
        discount={discount}
        open={addDiscount}
        onClose={() => setAddDiscount(false)}
        onConfirm={(discount) => setDiscount(discount)}
      />

      <ReceiptModal
        open={openReceiptModal}
        handleClose={() => setReceiptModalOpen(false)}
        grandTotal={grandTotal}
        receipt={receipt}
        handleReceipt={handleReceipt}
      />

      <InvoiceModal
        open={invoiceModal}
        handleClose={() => setInvoiceModal(false)}
        receipt={receipt}
      />
      <InvoiceDataModal
        open={openInvoiceDataModal}
        setOpen={setInvoiceDataModalOpen}
        handleClose={() => {
          setInvoiceDataModalOpen(false);
          setIsLoading(false);
        }}
        grandTotal={grandTotal}
        handleSubmit={handleInvoice}
        paymentTerms={paymentTerms}
      />
    </Grid>
  );
}
