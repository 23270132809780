import React, { useEffect, useState } from 'react';

// libs
import { Grid } from '@mui/material';

// sub component
import ListCategory from './ListCategory';
import FormCategory from './FormCategory';

const MainCategory = () => {
  const [renderFlag, setRenderFlag] = useState('');
  const [action, setAction] = useState({});

  const handleDataChange = (newRenderIndicator) => {
    setRenderFlag(newRenderIndicator);
  };

  const handleActionChange = (newActionIndicator) => {
    setAction(newActionIndicator);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} display='flex'>
          <FormCategory onDataListChange={handleDataChange} action={action} />
        </Grid>

        <Grid item xs={6} display='flex'>
          <ListCategory
            renderFlag={renderFlag}
            onActionChange={handleActionChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MainCategory;
