import apiRequest from "./ApiService";

const apiEndPoint = "account";

export const getUserById = async (uuid) => {
  try {
    return await apiRequest.get(`/${apiEndPoint}/${uuid}`);
  } catch (error) {
    return error;
  }
};

export const updateUserById = async (uuid, param) => {
  try {
    return await apiRequest.put(`/${apiEndPoint}/profile/${uuid}`, param);
  } catch (error) {
    return error;
  }
};

export const updateStore = async (uuid, param) => {
  try {
    return await apiRequest.put(`/stores/${uuid}`, param);
  } catch (error) {
    return error;
  }
};

export const changePassword = async (param) => {
  try {
    return await apiRequest.put(`/${apiEndPoint}/change-password`, param);
  } catch (error) {
    return error;
  }
};
