import React, { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// services
import { getCategoryByStoreId } from '../../../../services/StoreParameter/CategoryService';

// global css
import GlobalStyles from '../../../../styles/GlobalStyles';

// libs
import {
  styles as styled,
  Avatar,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
} from '@mui/material';

import {
  Folder as FolderIcon,
  ModeEdit as ModeEditIcon,
} from '@mui/icons-material';

// load icons
import { MaterialIcons } from 'react-native-vector-icons';

const ListCategory = ({ renderFlag, onActionChange }) => {
  const [categoryList, setCategoryList] = useState([]);

  const getCategoryList = async () => {
    const store_id = (await AsyncStorage.getItem('store_id')) || null;
    const dataList = await getCategoryByStoreId(store_id);
    setCategoryList(dataList);

    // reset to add
    onActionChange({});
  };

  const editItem = (data) => {
    onActionChange(data);
  };

  useEffect(() => {
    if (!renderFlag) {
      getCategoryList();
    }
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ ml: 4, mt: 4, mb: 2 }} variant='h6' component='div'>
            Category list
          </Typography>
          {categoryList && categoryList.length > 0 ? (
            <List style={GlobalStyles.ListCategory}>
              {categoryList.map((data, index) => (
                <ListItem
                  key={data?.uuid}
                  secondaryAction={
                    <IconButton
                      edge='end'
                      aria-label='edit'
                      onClick={() => editItem(data)}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <MaterialIcons
                      name={data?.category_icon || 'folder'}
                      size={30}
                      color={'white'}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={data.category_name} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography sx={{ textAlignLast: 'center' }}>
              - No record found -
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ListCategory;
