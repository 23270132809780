import { Box, Button, Modal, ClickAwayListener, Tooltip } from "@mui/material";
import { useRef, useEffect, useState } from "react";
import shoppingBagImage from "./../../../../../assets/images/shopping-bag.png";
import { formatPrice } from "../../../../utils/Strings";
import { getUserById } from "../../../../services/ProfileService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#FFFFFF",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "#000000",
  overflow: "scroll",
  height: "100%",
};

export default function InvoiceModal(props) {
  const { open, handleClose, receipt } = props;
  const printableAreaRef = useRef(0);
  const uuid = localStorage.getItem("uuid");
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    getUserById(uuid).then((rs) => {
      setUserData(rs.data.data);
    });
  };

  const handlePrintClick = () => {
    const printWindow = window.open(
      " ",
      " ",
      "width=" + "1024px" + ", height=" + "800px"
    );
    if (printableAreaRef.current?.innerHTML) {
      printWindow.document.write("<html><head><title></title>");
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      printWindow.document.write("<body>");
      printWindow?.document.write(printableAreaRef.current.innerHTML);
      printWindow.document.write("</body>");
      printWindow.document.write("</html>");
      printWindow?.print();
    }

    setTimeout(function () {
      printWindow?.close();
    }, 2000);
  };

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltip = () => {
    setOpenTooltip(!openTooltip);
    navigator.clipboard.writeText(receipt?.data?.transaction_receipt_link);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box sx={style}>
        <h3>Preview</h3>
        <div ref={printableAreaRef}>
          <hr />
          <div
            style={{ display: "flex", justifyContent: "space-between", p: 2 }}
          >
            <img
              src={userData?.store?.logo || shoppingBagImage}
              name="shoppingBag"
              alt=""
              style={{
                height: "60px",
                width: "60px",
                contain: "size",
                margin: "20px",
                padding: "5px",
              }}
            />
            {/* <div style={{ marginRight: '20px' }}>
              <h3 style={{ color: 'rgb(0, 0, 0)' }}>
                {receipt?.data?.store?.name}
              </h3>
              <h6 style={{ color: 'rgb(0, 0, 0)' }}>
                {receipt?.data?.store?.address}
              </h6>
            </div> */}
          </div>
          <div>
            <b style={{ fontSize: "12px", alignSelf: "center" }}>
              Invoice No: {receipt?.data?.transaction_number}
            </b>
          </div>
          <div>
            <b style={{ fontSize: "12px", alignSelf: "center" }}>
              Date & Time:{" "}
              {new Date(receipt?.data?.created_at).toLocaleString("en-US")}
            </b>
          </div>
          <div>
            <b style={{ fontSize: "12px", alignSelf: "center" }}>
              Hi {receipt?.data?.customer?.first_name}, we're getting your order
              ready to be shipped. We will notify you when it has been sent.
            </b>
          </div>
          <br />
          <div>
            <table style={{ width: "100%" }} cellPadding={10}>
              <thead>
                <tr>
                  <th
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      color: "black",
                      textAlign: "left",
                    }}
                    scope="col"
                  >
                    PRODUCT CODE
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      color: "black",
                      textAlign: "left",
                    }}
                    scope="col"
                  >
                    ITEMS
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      color: "black",
                      textAlign: "left",
                    }}
                    scope="col"
                  >
                    QTY
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      color: "black",
                      textAlign: "left",
                    }}
                    scope="col"
                  >
                    PRICE
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      color: "black",
                      textAlign: "left",
                    }}
                    scope="col"
                  >
                    AMOUNT
                  </th>
                </tr>
              </thead>
              <tbody>
                {receipt?.data?.products.map((row) => (
                  <tr
                    key={row.id}
                    style={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <td
                      scope="row"
                      style={{ background: "white", color: "black" }}
                    >
                      {row?.product?.product_code || "-"}
                    </td>
                    <td
                      scope="row"
                      style={{ background: "white", color: "black" }}
                    >
                      {row?.product?.product_name || "-"}
                    </td>
                    <td
                      scope="row"
                      style={{ background: "white", color: "black" }}
                    >
                      {row?.quantity}
                    </td>
                    <td
                      scope="row"
                      style={{
                        background: "white",
                        color: "black",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.product?.price}
                    </td>
                    <td
                      scope="row"
                      style={{
                        background: "white",
                        color: "black",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.total_amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <b style={{ color: "rgb(0, 0, 0)", alignSelf: "center" }}>
              SUBTOTAL
            </b>
            <p style={{ fontSize: "12px" }}>
              {formatPrice(Number(receipt?.data?.subtotal_amount))}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: "12px" }}>DISCOUNT</p>
            <p style={{ fontSize: "12px" }}>
              {formatPrice(Number(receipt?.data?.discount_amount))}
            </p>
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: "12px" }}>VAT - 12%</p>
            <p style={{ fontSize: "12px" }}>
              {formatPrice(Number(receipt?.data?.tax_amount))}
            </p>
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <b style={{ color: "rgb(0, 0, 0)" }}>TOTAL AMOUNT DUE</b>
            <b style={{ color: "rgb(0, 0, 0)" }}>
              {formatPrice(Number(receipt?.data?.total_amount))}
            </b>
          </div>
          <br />
          <hr />
          <br />
        </div>
        <Box
          textAlign="center"
          display="flex"
          justifyContent="space-between"
          alignSelf="normal"
          p={2}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handlePrintClick();
            }}
          >
            Print Invoice
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
