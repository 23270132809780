import { Box, Button, Container, Modal, Typography } from "@mui/material";
import { useRef } from "react";
import { formatPrice } from "../../../../utils/Strings";
import { useReactToPrint } from "react-to-print";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFFFFF",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  color: "#000000",
  overflow: "scroll",
  maxHeight: "80vh",
};

export default function ReceiptModal(props) {
  const { open, handleClose, receipt, change } = props;
  const printableAreaRef = useRef(0);

  const handlePrintClick = useReactToPrint({
    content: () => printableAreaRef.current,
  });

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box sx={style}>
        <Box>
          <h3>Preview</h3>
          <Box ref={printableAreaRef} p={2}>
            <Box
              style={{
                textAlign: "center",
                borderTop: "2px #000000 dashed",
                paddingTop: 20,
              }}
            >
              <Typography style={{ color: "#000000", lineHeight: 1.2 }}>
                {receipt?.data?.store?.name || " "}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ color: "#000000", lineHeight: 1, fontSize: 8 }}
              >
                {receipt?.data?.store?.address_text || " "}
              </Typography>
            </Box>
            <br />
            <Box style={{ borderBottom: "1px solid black" }}>
              <Typography fontSize={10} style={{ color: "#000000" }}>
                Name: {receipt?.data?.customer?.first_name}{" "}
                {receipt?.data?.customer?.last_name}
              </Typography>
            </Box>
            <Box style={{ borderBottom: "1px solid black" }}>
              <Typography fontSize={10} style={{ color: "#000000" }}>
                Transaction: {receipt?.data?.transaction_number}
              </Typography>
            </Box>
            <Box style={{ borderBottom: "1px solid black" }}>
              <Typography fontSize={10} style={{ color: "#000000" }}>
                Date & Time:{" "}
                {new Date(receipt?.data?.created_at).toLocaleString("en-US")}
              </Typography>
            </Box>
            {/* <Box style={{ borderBottom: "1px solid black" }}>
              <Typography fontSize={10} style={{ color: "#000000" }}>
                TIN:{" "}
              </Typography>
            </Box> */}
            {/* <Box style={{ borderBottom: "1px solid black" }}>
              <Typography fontSize={10} style={{ color: "#000000" }}>
                ID No:{" "}
              </Typography>
            </Box> */}
            <Box style={{ borderBottom: "1px solid black" }}>
              <Typography fontSize={10} style={{ color: "#000000" }}>
                {`Address: ${receipt?.data?.customer?.address_text || ""} ${
                  receipt?.data?.customer?.city_name || ""
                } ${receipt?.data?.customer?.province_name || ""}`}
              </Typography>
            </Box>
            {/* <Box style={{ borderBottom: "1px solid black" }}>
              <Typography fontSize={10} style={{ color: "#000000" }}>
                Business Style:{" "}
              </Typography>
            </Box> */}
            {/* <Box style={{ borderBottom: "1px solid black" }}>
              <Typography fontSize={10} style={{ color: "#000000" }}>
                Signature:{" "}
              </Typography>
            </Box> */}
            <br />
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontWeight="bold"
                fontSize={12}
                style={{ color: "#000000" }}
              >
                Items
              </Typography>
              <Typography
                fontWeight="bold"
                fontSize={12}
                style={{ color: "#000000" }}
              >
                Amount
              </Typography>
            </Box>
            {receipt?.data?.products?.map((row, index) => (
              <Box
                key={index}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography fontSize={10} style={{ color: "#000000" }}>
                  {row?.product?.product_name}
                  <br />
                  {row?.quantity} x {formatPrice(row?.product?.price)}
                </Typography>
                <Typography fontSize={10} style={{ color: "#000000" }}>
                  {formatPrice(row?.total_amount)}
                  <br />
                </Typography>
              </Box>
            ))}
            <br />
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  color: "#000000",
                }}
                fontWeight="bold"
                fontSize={12}
                alignSelf="center"
              >
                SUBTOTAL
              </Typography>
              <Typography
                fontSize={10}
                alignSelf="center"
                style={{ color: "#000000" }}
              >
                {formatPrice(receipt?.data?.subtotal_amount)}
              </Typography>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={10}
                alignSelf="center"
                style={{ color: "#000000" }}
              >
                DISCOUNT
              </Typography>
              <Typography
                fontSize={10}
                alignSelf="center"
                style={{ color: "#000000" }}
              >
                {formatPrice(receipt?.data?.discount_amount)}
              </Typography>
            </Box>

            {change ? (
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={10}
                  alignSelf="center"
                  style={{ color: "#000000" }}
                >
                  CHANGE
                </Typography>
                <Typography
                  fontSize={10}
                  alignSelf="center"
                  style={{ color: "#000000" }}
                >
                  {formatPrice(change)}
                </Typography>
              </Box>
            ) : (
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={10}
                  alignSelf="center"
                  style={{ color: "#000000" }}
                >
                  BALANCE
                </Typography>
                <Typography
                  fontSize={10}
                  alignSelf="center"
                  style={{ color: "#000000" }}
                >
                  {receipt?.data?.payments.length
                    ? formatPrice(0)
                    : formatPrice(receipt?.data?.total_amount)}
                </Typography>
              </Box>
            )}

            {/* <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={10}
                alignSelf="center"
                style={{ color: "#000000" }}
              >
                VAT - 12%
              </Typography>
              <Typography
                fontSize={10}
                alignSelf="center"
                style={{ color: "#000000" }}
              >
                {formatPrice(receipt?.data?.tax_amount)}
              </Typography>
            </Box> */}
            <br />
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "2px dashed #000000",
                paddingBottom: 20,
              }}
            >
              <Typography
                style={{
                  color: "#000000",
                }}
                fontSize={12}
                alignSelf="center"
                fontWeight="bold"
              >
                TOTAL
              </Typography>
              <Typography
                style={{
                  color: "#000000",
                }}
                fontSize={12}
                alignSelf="center"
              >
                {formatPrice(receipt?.data?.total_amount)}
              </Typography>
            </Box>
            <br />

            <br />
          </Box>
        </Box>
        <Box
          style={{
            textAlign: "center",
            justifyContent: "space-between",
            alignSelf: "normal",
            display: "flex",
            p: 2,
          }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handlePrintClick();
            }}
          >
            Print
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
