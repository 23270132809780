import { useEffect, useState } from "react";
import { DataGrid, gridClasses, GridToolbarContainer } from "@mui/x-data-grid";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";

// import empty_image from '../../../../../assets/icons/empty_state.svg';

// global css
import GlobalStyles from "../../../../styles/GlobalStyles";

const DataRecordsProduct = ({
  tableColumnsProducts,
  tableRecordsProduct,
  pageSize,
  onPageSizeChange,
  onPageChange,
  page,
  rowCount,
}) => {
  const [rsData, setRsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDataRecordsProduct = async () => {
    setIsLoading(true);

    if (tableRecordsProduct) {
      setRsData(tableRecordsProduct);
      setIsLoading(false);
    } else {
      setRsData([]);
      setIsLoading(false);
      console.warn("Failed to load data");
    }
  };

  const onRowClick = async (params, event) => {
    console.log("params", params);
  };

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: GlobalStyles.tableEvenBg.backgroundColor,
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: GlobalStyles.tableEvenBgOdd.backgroundColor,
    },
  }));

  const customToolbar = () => {
    return (
      <GridToolbarContainer style={{ float: "right" }}>
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    getDataRecordsProduct();
    console.log("test");
  }, [tableRecordsProduct]);

  return (
    <Box
      style={{
        width: "100%",
        border: 0,
      }}
    >
      <StripedDataGrid
        width={"auto"}
        style={{ border: 0 }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
        onPageChange={(newPage) => onPageChange(newPage, pageSize)}
        page={page - 1}
        rowCount={rowCount}
        paginationMode="server"
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        pagination
        columns={tableColumnsProducts}
        rows={rsData}
        // autoPageSize
        disableColumnFilter
        disableSelectionOnClick
        loading={isLoading}
        onRowClick={onRowClick}
        sx={{
          ".MuiDataGrid-row:hover": {
            backgroundColor: GlobalStyles?.secondaryBg.backgroundColor,
          },
          ".MuiDataGrid-iconSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&>.MuiDataGrid-main": {
            "&>.MuiDataGrid-columnHeaders": {
              borderBottom: "none",
            },

            "& div div div div >.MuiDataGrid-cell": {
              borderBottom: "none",
            },
          },
          minHeight: 500,
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        components={{
          Toolbar: customToolbar,
          NoRowsOverlay: () => (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "#02101F",
                p: 10,
                height: "100%",
              }}
            >
              <Container maxWidth="lg">
                <Box textAlign="center" alignItems="center">
                  <AssignmentLateIcon></AssignmentLateIcon>
                  <Typography fontSize={20} fontWeight={600} color="#FFFFFF">
                    There are no records to display.
                  </Typography>
                  <Typography fontSize={20} fontWeight={400} color="#12355B">
                    Your sales report is currently empty, and this table will
                    update when data is available.
                  </Typography>
                </Box>
              </Container>
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default DataRecordsProduct;
