import { useEffect, useState } from "react";
import { DataGrid, gridClasses, GridToolbarContainer } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
// global css
import GlobalStyles from "../../../../styles/GlobalStyles";

const DataRecords = ({
  tableColumns,
  tableRecords,
  pageSize,
  onPageSizeChange,
  onPageChange,
  page,
  rowCount,
}) => {
  const [rsData, setRsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDataRecords = async () => {
    setIsLoading(true);

    if (tableRecords) {
      setRsData(tableRecords);
      // console.log('tableRecords', tableRecords);
      setIsLoading(false);
    } else {
      console.warn("Failed to load data");
    }
  };

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: GlobalStyles.tableEvenBg.backgroundColor,
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: GlobalStyles.tableEvenBgOdd.backgroundColor,
    },
  }));

  const customToolbar = () => {
    return (
      <GridToolbarContainer style={{ float: "right" }}>
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    // console.log('rs data', tableRecords);
    getDataRecords();
  }, [tableRecords]);

  const onRowClick = async (params, event) => {
    console.log("params", params);
  };

  return (
    <>
      <Box
        style={{
          width: "100%",
          border: 0,
          maxHeight: "1000px",
        }}
      >
        <StripedDataGrid
          width={"auto"}
          style={{ border: 0, minHeight: "70vh" }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
          onPageChange={(newPage) => onPageChange(newPage, pageSize)}
          page={page - 1}
          rowCount={rowCount}
          paginationMode="server"
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          pagination
          columns={tableColumns}
          rows={rsData}
          disableColumnFilter
          disableSelectionOnClick
          loading={isLoading}
          onRowClick={onRowClick}
          sx={{
            ".MuiDataGrid-row:hover": {
              backgroundColor: GlobalStyles?.secondaryBg.backgroundColor,
            },
            ".MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&>.MuiDataGrid-main": {
              "&>.MuiDataGrid-columnHeaders": {
                borderBottom: "none",
              },

              "& div div div div >.MuiDataGrid-cell": {
                borderBottom: "none",
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          components={{
            Toolbar: customToolbar,
            NoRowsOverlay: () => (
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "#02101F",
                  mt: 1,
                  height: "100%",
                }}
              >
                <Box
                  component="main"
                  padding={{ xs: "5px", md: "32px" }}
                  sx={{
                    margin: "auto",
                  }}
                >
                  <Container maxWidth="lg">
                    <Box textAlign="center" alignItems="center" mt={5} mb={5}>
                      <AssignmentLateIcon></AssignmentLateIcon>
                      <Typography
                        fontSize={20}
                        fontWeight={600}
                        color="#12355B"
                      >
                        There are no records to display.
                      </Typography>
                      <Typography
                        fontSize={20}
                        fontWeight={400}
                        color="#12355B"
                      >
                        Your sales report is currently empty, and this table
                        will update when data is available.
                      </Typography>
                    </Box>
                  </Container>
                </Box>
              </Box>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default DataRecords;
