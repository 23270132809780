import { useState } from "react";
import { Box, Grid, Button, CardMedia } from "@mui/material";

// images
import success_image from "./../../../../../assets/images/success_transaction_image.png";
import success_bg from "./../../../../../assets/images/success_transaction_bg.png";

import { formatPrice } from "../../../../utils/Strings";

export default function InvoiceSuccess(props) {
  const {
    discount,
    paymentAmount,
    isSuccessTransaction,
    cart,
    handleNewTransaction,
    handleReceipt,
    handleInvoice,
    handleCopyLink,
  } = props;

  const subTotal = Number(
    cart.reduce((accumulator, object) => {
      return accumulator + object.price * object.quantity;
    }, 0)
  );
  const grandTotal = Number(subTotal - discount).toFixed(2);

  return isSuccessTransaction && cart.length ? (
    <Grid item xs={12} textAlign="center" p={2}>
      <h6 textAlign="center" style={{ marginBottom: 0 }}>
        Grand Total
      </h6>
      <h2 textAlign="center" style={{ marginTop: 0 }}>
        {formatPrice(grandTotal)}
      </h2>
      <Box
        alignItems="center"
        textAlign="center"
        display="flex"
        flexDirection="column"
        style={{
          backgroundImage: `url(${success_bg})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          height: 250,
        }}
      >
        <h3>Transaction Successful!</h3>
        <CardMedia
          name="customer_image"
          component="img"
          src={success_image}
          alt=""
          style={{
            height: "120px",
            width: "114px",
            contain: "size",
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        textAlign="center"
        justifyContent="space-between"
        alignSelf="normal"
        mt={5}
      >
        <Button
          fullWidth
          variant="contained"
          color="warning"
          onClick={handleReceipt}
        >
          Receipt
        </Button>
        &ensp;
        <Button
          fullWidth
          variant="contained"
          color="info"
          onClick={handleCopyLink}
        >
          Copy Link
        </Button>
      </Box>
      <Box
        textAlign="center"
        display="flex"
        justifyContent="space-between"
        alignSelf="normal"
        mt={5}
      >
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={handleInvoice}
        >
          Invoice
        </Button>
      </Box>
      <br />
      <Button
        fullWidth
        variant="outlined"
        color="success"
        onClick={handleNewTransaction}
      >
        New Transaction
      </Button>
    </Grid>
  ) : (
    <></>
  );
}
