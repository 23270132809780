import React from "react";
import { Box } from "@mui/material";
import AsensoLogo from "../../../assets/images/asenso-logo2.png";

const LogoTransparent = ({
  backgroundColor = "transparent",
  height = "135px",
  width = "306px",
  otherSrc,
}) => {
  return (
    <Box
      component="img"
      sx={{
        height: height,
        width: width,
        justifyContent: "center",
        alignItems: "center",
        padding: 1.2,
        maxHeight: "120px",
      }}
      alt="Asenso Logo"
      src={otherSrc || AsensoLogo}
    />
  );
};

export default LogoTransparent;
