import React, { useEffect, useState } from "react";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// global css

// libs
import {
  Box,
  Grid,
  Typography,
  Snackbar,
  Backdrop,
  CircularProgress,
  TextField,
  InputAdornment,
  Tab,
  Button,
  Stack,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import DownloadIcon from "@mui/icons-material/Download";

// services
import {
  getSalesReports,
  getProductSalesReport,
  generateDSR,
  getRecordedDSR,
  exportProduct,
  exportTransactions,
} from "../../../../services/report/SalesReportService";
import DataRecords from "../components/DataRecords";
import DataRecordsProduct from "../components/DataRecordsProduct";

// sub component
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DSRModal from "../components/DSRModal";
import DSRDateModal from "../components/DSRDateModal";
import { LoadingButton } from "@mui/lab";
import { Alert } from "../../../../components/common";

const SalesReport = () => {
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataReport, setDateReport] = useState();
  const [transaction, setTransaction] = useState();
  const [DSRData, setDSRData] = useState([]);
  const [openDSRModal, setOpenDSRModal] = useState(false);
  const [date, setDate] = useState({
    start: dayjs().date(1),
    end: dayjs(new Date()),
  });
  const [dateProduct, setDateProduct] = useState({
    startProduct: dayjs().date(1),
    endProduct: dayjs(new Date()),
  });
  const [dateDSR, setDateDSR] = useState("");
  const [openDSRDateModal, setOpenDSRDateModal] = useState(false);
  const [rsDataPagination, setRsDataPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizeProduct, setPageSizeProduct] = useState(10);
  const [showButton, setShowButton] = useState(false);
  const [generateOrShow, setGenerateOrShow] = useState(true);
  const [cash_on_hand, setCashOnHand] = useState("");

  const [openSnack, setOpenSnack] = useState(false);
  const [snackVariant, setSnackVariant] = useState("success");
  const [errMsg, setErrMsg] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const [transactionProduct, setTransactionProduct] = useState();
  const [dataReportProduct, setDataReportProduct] = useState();
  const [rsDataPaginationProduct, setRsDataPaginationProduct] = useState([]);
  const [pageProduct, setPageProduct] = useState(1);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const schema = {
    transaction_number: "",
    transaction_date_created: "",
    transaction_date_updated: "",
    transaction_type: "",
    no_of_items: "",
    discount: "",
    total_amount: "",
    payment_status: "",
    customer: "",
  };

  const schemaProduct = {
    transaction_id: "",
    transaction_type: "",
    created_at: "",
    product_id: "",
    product_name: "",
    total_amount: "",
    quantity: "",
    price: "",
    total_amount: "",
    payment_status_name: "",
  };

  useEffect(() => {
    const fetchDSR = async () => {
      const report_date = dateDSR?.start.format("YYYY-MM-DD");
      setIsLoading(true);
      let response = [];
      if (generateOrShow) {
        response = await generateDSR(
          `?report_date=${report_date}&cash_on_hand=${cash_on_hand}`
        );
      } else {
        response = await getRecordedDSR(`?report_date=${report_date}`);
      }
      if (response.data.success === 1) {
        setDSRData(response?.data?.data);
        setOpenDSRModal(!openDSRModal);
        setOpenDSRDateModal(!openDSRDateModal);
      } else {
        setDSRData([]);
        setSnackVariant("error");
        setErrMsg(response.data.message);
        setOpenSnack(true);
      }
      setIsLoading(false);
    };
    if (dateDSR && dateDSR !== "Invalid Date") {
      fetchDSR();
    }
  }, [dateDSR]);

  const [value, setValue] = React.useState("1");
  const handleChangeTab = async (e, newValue) => {
    setValue(newValue);
  };

  // **** CUSTOMER SEARCH
  const [searchTextCustomer, setSearchTextCustomer] = useState("");
  const [customer, setCustomer] = useState([]);

  // **** PRODUCT SEARCH
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);

  const fetchSalesReport = async (page, pageSize) => {
    setPage(page);
    const start = dayjs(date.start).format("YYYY-MM-DD");

    const end = dayjs(date.end).format("YYYY-MM-DD");

    const today = dayjs(new Date()).format("YYYY-MM-DD");

    if (
      dayjs(new Date(start)) < dayjs(new Date("1900-01-01")) ||
      dayjs(new Date(end)) < dayjs(new Date("1900-01-01"))
    ) {
      setSnackVariant("error");
      setErrMsg("Invalid Date");
      setOpenSnack(true);
      return false;
    }

    if (start <= today && start && end <= today) {
      const { data } = await getSalesReports(
        `?&sort=-id&page=${page}&size=${pageSize}&date_start=${dayjs(
          date.start
        ).format("YYYY-MM-DD")}&date_end=${dayjs(date.end).format(
          "YYYY-MM-DD"
        )}`
      );
      if (data && data?.success) {
        let rs = [];

        setTransaction(data?.data);

        data?.data?.transaction?.map((d) => {
          rs.push({
            ...schema,
            id: d?.transaction_number,
            transaction_number: d?.transaction_number,
            payment_status: d?.payment_status_name || "",
            transaction_type: d?.transaction_type_name || "N/A",
            transaction_date_created: d?.created_at || "",
            transaction_date_updated: d?.updated_at || "",
            no_of_items: d?.items_quantity || "",
            discount: `₱ ${Number(d?.discount_amount || 0).toLocaleString(
              "en",
              {
                minimumFractionDigits: 2,
              }
            )}`,
            total_amount: `₱ ${Number(d?.total_amount || 0).toLocaleString(
              "en",
              {
                minimumFractionDigits: 2,
              }
            )}`,
            sales_person: d?.sales_person || "",
            uuid: d?.uuid,
          });
        });
        setDateReport(rs);
        setRsDataPagination(data?.data?.pagination);
      }
    } else {
      setSnackVariant("error");
      setErrMsg("Invalid Date");
      setOpenSnack(true);
      return false;
    }
  };

  const fetchSalesReportPerProduct = async (pageProduct, pageSizeProduct) => {
    setPageProduct(pageProduct);

    const start = dayjs(dateProduct.startProduct).format("YYYY-MM-DD");

    const end = dayjs(dateProduct.endProduct).format("YYYY-MM-DD");

    const today = dayjs(new Date()).format("YYYY-MM-DD");

    if (
      dayjs(new Date(start)) < dayjs(new Date("1900-01-01")) ||
      dayjs(new Date(end)) < dayjs(new Date("1900-01-01"))
    ) {
      setSnackVariant("error");
      setErrMsg("Invalid Date");
      setOpenSnack(true);
      return false;
    }

    if (start <= today && start && end <= today) {
      const { data } = await getProductSalesReport(
        `?&sort=-id&page=${pageProduct}&size=${pageSizeProduct}&date_start=${dayjs(
          dateProduct.startProduct
        ).format("YYYY-MM-DD")}&date_end=${dayjs(dateProduct.endProduct).format(
          "YYYY-MM-DD"
        )}&product_keyword=` +
          searchText +
          `&customer_keyword=` +
          searchTextCustomer
      );
      if (data && data?.success) {
        let rs = [];

        setTransactionProduct(data?.data);

        data?.data?.transaction?.map((d) => {
          rs.push({
            ...schemaProduct,
            id: d?.id,
            product_code: d?.product?.product_code,
            product_name: d?.product?.product_name,
            total_amount: d?.total_amount,
            quantity: d?.quantity,
            price_per_piece: d?.price_per_piece,
            transaction_id: d?.transaction_id,
            transaction_type: d?.transaction?.transaction_type_name || "N/A",
            created_at: d?.transaction?.created_at,
            customer: d?.transaction?.customer
              ? `${d?.transaction?.customer?.last_name}, ${d?.transaction?.customer?.first_name}`
              : "N/A",
            payment_status_name: d?.transaction?.payment_status_name || "",
            uuid: d?.uuid,
          });
        });
        setDataReportProduct(rs);
        setRsDataPaginationProduct(data?.data?.pagination);
      }
    } else {
      setSnackVariant("error");
      setErrMsg("Invalid Date");
      setOpenSnack(true);
      return false;
    }
  };

  const handleClickDSRButton = () => {
    setOpenDSRDateModal(!openDSRDateModal);
    setShowButton(false);
  };

  const handleClickReset = () => {
    setSearchText("");
    setSearchTextCustomer("");
    setPageSizeProduct("");
    setDateProduct({
      startProduct: "",
      endProduct: "",
    });
  };

  const handleSearch = (e) => {
    setSearchTextCustomer(e.target.value);
  };

  const handleSearchItem = () => {
    fetchSalesReportPerProduct(page, pageSizeProduct);
  };

  const handleSearchTransaction = () => {
    fetchSalesReport(page, pageSize);
  };

  const handleClickExport = async () => {
    setIsExportLoading(true);
    try {
      const date_start = dateProduct.startProduct.format("YYYY-MM-DD");
      const date_end = dateProduct.endProduct.format("YYYY-MM-DD");
      const { data } = await exportProduct(
        date_start,
        date_end,
        searchText,
        searchTextCustomer
      );
      // exportProduct((dateProduct.start ).format('YYYY-MM-DD')(dateProduct.end).format('YYYY-MM-DD'));

      if (data?.success) {
        setOpenSnack(true);
        setSnackVariant("success");
        setErrMsg(
          data?.message ?? "Successfully sent file to your email address"
        );
      } else {
        setOpenSnack(true);
        setSnackVariant("error");
        setErrMsg("Invalid Date!");
      }
      setIsExportLoading(false);
    } catch {
      setIsExportLoading(false);
      setOpenSnack(true);
      setSnackVariant("error");
      setErrMsg("Something went wrong, please try again");
    }

    setIsExportLoading(false);
  };

  const handleClickExportTransaction = async () => {
    setIsExportLoading(true);
    try {
      const start = date.start.format("YYYY-MM-DD");
      const end = date.end.format("YYYY-MM-DD");

      const { data } = await exportTransactions(start, end);

      if (data?.success) {
        setOpenSnack(true);
        setSnackVariant("success");
        setErrMsg(
          data?.message ?? "Successfully sent file to your email address"
        );
      } else {
        setOpenSnack(true);
        setSnackVariant("error");
        setErrMsg(data?.message || "Something went wrong, please try again");
      }
      setIsExportLoading(false);
    } catch {
      setIsExportLoading(false);
      setOpenSnack(true);
      setSnackVariant("error");
      setErrMsg("Something went wrong, please try again");
    }

    setIsExportLoading(false);
  };

  const tableColumns = [
    {
      field: "transaction_number",
      headerName: "Transaction No.",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (param) => {
        const handleLabel = (label) => {
          switch (label) {
            case "POS":
              return "Order";

            default:
              return label;
          }
        };

        return handleLabel(param.value);
      },
    },
    {
      field: "no_of_items",
      headerName: "No. of Items",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "discount",
      headerName: "Discount",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },

    {
      field: "transaction_date_created",
      headerName: "Date&Time Created",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "transaction_date_updated",
      headerName: "Date&Time Updated",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "sales_person",
      headerName: "Sales Person",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
    },
  ];

  const tableColumnsProducts = [
    {
      field: "product_code",
      headerName: "Product Code",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "product_name",
      headerName: "Product Name",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "quantity",
      headerName: "Qty.",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "price_per_piece",
      headerName: "Price",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "transaction_id",
      headerName: "Transaction No.",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (param) => {
        const handleLabel = (label) => {
          switch (label) {
            case "POS":
              return "Order";

            default:
              return label;
          }
        };

        return handleLabel(param.value);
      },
    },
    {
      field: "created_at",
      headerName: "Transaction Date",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "payment_status_name",
      headerName: "Payment Status",
      minWidth: 200,
      sortable: true,
      disableColumnMenu: true,
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <img
      src="https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg"
      alt="no-item"
    />
  );

  useEffect(() => {
    if (value === "1") {
      fetchSalesReport(page, pageSize);
    } else {
      fetchSalesReportPerProduct(page, pageSizeProduct);
    }
  }, [date, dateProduct, pageSize]);

  return (
    <>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChangeTab}
            aria-label="lab API tabs example"
            centered
            variant="fullWidth"
          >
            <Tab
              iconPosition="start"
              label="Report per Transaction"
              value="1"
            />
            <Tab
              iconPosition="start"
              label="Report per Product"
              value="2"
              // onClick={reportPerProduct}
            />
          </TabList>
        </Box>
        {/* Report Per Transaction */}
        <TabPanel value="1">
          <Grid container>
            <Grid
              item
              xs={12}
              display="flex"
              direction={{ xs: "column", md: "row" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                  <DatePicker
                    label="Date Start"
                    value={date?.start}
                    disableFuture={true}
                    onChange={(newValue) => {
                      setDate({
                        ...date,
                        start: newValue,
                      });
                    }}
                  />
                  <DatePicker
                    label="Date End"
                    value={date?.end}
                    disableFuture={true}
                    minDate={date.start}
                    onChange={(newValue) => {
                      setDate({
                        ...date,
                        end: newValue,
                      });
                    }}
                  />
                </Stack>
              </LocalizationProvider>
              <Stack
                direction={{ xs: "column", md: "row" }}
                flex={1}
                justifyContent="flex-end"
                spacing={1}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={2}
                >
                  <LoadingButton
                    variant="contained"
                    onClick={() => handleClickDSRButton()}
                    // onClick={() => fetchDSR()}
                    loading={isLoading}
                  >
                    Generate DSR
                  </LoadingButton>
                  &nbsp;
                  <LoadingButton
                    variant="contained"
                    onClick={() => handleClickExportTransaction()}
                    loading={isExportLoading}
                  >
                    <DownloadIcon />
                    Export
                  </LoadingButton>
                </Box>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <DataRecords
                tableColumns={tableColumns}
                tableRecords={dataReport}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPage) =>
                  fetchSalesReport(newPage + 1, pageSize)
                }
                page={page}
                // rowCount={rsDataPagination?.total}
                rowCount={rsDataPagination?.total || 0}
              ></DataRecords>
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom fontWeight={500} mt={2} display="block">
                Total Discount: ₱{" "}
                {Number(
                  transaction?.summary?.total_discount || 0
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}
              </Typography>

              <Typography gutterBottom fontWeight={500} mt={2} display="block">
                Total Sales: ₱{" "}
                {Number(transaction?.summary?.total_amount || 0).toLocaleString(
                  "en",
                  {
                    minimumFractionDigits: 2,
                  }
                )}
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        {/* Report Per Product */}
        <TabPanel value="2">
          <Grid container>
            <Grid
              item
              xs={12}
              display="flex"
              direction={{ xs: "column", md: "row" }}
              p={1}
            >
              <TextField
                fullWidth
                inputProps={{
                  autoComplete: "none",
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
                label="Search Product"
                placeholder="Search Product"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ color: "white" }}
                    ></InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: "white" }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                inputProps={{
                  autoComplete: "none",
                }}
                onChange={handleSearch}
                value={searchTextCustomer}
                placeholder="Search Customer"
                label="Search Customer"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ color: "white" }}
                    ></InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: "white" }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent={{ xs: "center", md: "space-between" }}
              direction={{ xs: "column", md: "row" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                  <DatePicker
                    label="Date Start"
                    value={dateProduct?.startProduct}
                    disableFuture={true}
                    onChange={(newValue) =>
                      setDateProduct({
                        ...dateProduct,
                        startProduct: newValue,
                      })
                    }
                  />
                  <DatePicker
                    label="Date End"
                    value={dateProduct?.endProduct}
                    disableFuture={true}
                    onChange={(newValue) =>
                      setDateProduct({
                        ...dateProduct,
                        endProduct: newValue,
                      })
                    }
                  />
                </Stack>
              </LocalizationProvider>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={2}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleSearchItem()}
                >
                  Search
                </Button>
                &nbsp;
                <Button onClick={() => handleClickReset()} variant="contained">
                  Reset
                </Button>
                &nbsp;
                <LoadingButton
                  loading={isExportLoading}
                  variant="contained"
                  onClick={() => handleClickExport()}
                >
                  <DownloadIcon />
                  Export
                </LoadingButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <DataRecordsProduct
                rows={dataReportProduct}
                tableColumnsProducts={tableColumnsProducts}
                tableRecordsProduct={dataReportProduct}
                pageSize={pageSizeProduct}
                onPageSizeChange={(newPageSize) =>
                  setPageSizeProduct(newPageSize)
                }
                onPageChange={(newPage) =>
                  fetchSalesReportPerProduct(newPage + 1, pageSizeProduct)
                }
                page={pageProduct}
                rowCount={rsDataPaginationProduct?.total || 0}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>

      <DSRDateModal
        open={openDSRDateModal}
        handleClose={() => setOpenDSRDateModal(!openDSRDateModal)}
        handleGenerate={() => fetchDSR()}
        setDateDSR={setDateDSR}
        isLoading={isLoading}
        showButton={showButton}
        setShowButton={setShowButton}
        setGenerateOrShow={setGenerateOrShow}
        generateOrShow={generateOrShow}
        setCashOnHand={setCashOnHand}
      />

      <DSRModal
        open={openDSRModal}
        handleClose={() => setOpenDSRModal(!openDSRModal)}
        data={DSRData}
      />

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SalesReport;
