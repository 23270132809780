import React, { useEffect, useState } from 'react';

const UseDocumentTitle = (title) => {
  const [documentTitle, setDocumentTitle] = useState(
    title ? title : 'Dashboard'
  );
  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return [documentTitle, setDocumentTitle];
};

export { UseDocumentTitle };
