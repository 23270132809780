import apiRequest from './ApiService';

/*
 * Get all locations
 * This will return a list of locations
 */
export const getLocations = async () => {
  try {
    return await apiRequest.get('/datasets/location');
  } catch (error) {
    console.log(error);
  }
};

/*
 * Get all unit of measurements
 * This will return a list of unit of measurements
 */
export const getUom = async () => {
  try {
    return await apiRequest.get('/global_parameters/uom_type');
  } catch (error) {
    console.log(error);
  }
};

/*
 * Get all category
 * This will return a list of category
 */
export const getCategory = async () => {
  try {
    return apiRequest.get('/store_parameters/category?page=all');
  } catch (error) {
    console.log(error);
  }
};

/*
 * Get all supplier
 * This will return a list of supplier
 */
export const getSupplier = async () => {
  try {
    return await apiRequest.get('/store_parameters/supplier?page=all');
  } catch (error) {
    console.log(error);
  }
};

/*
 * Get all brand
 * This will return a list of brand
 */
export const getBrand = async () => {
  try {
    return await apiRequest.get('/store_parameters/brand?page=all');
  } catch (error) {
    console.log(error);
  }
};
